<template>
    <div>
        <div v-if="loading">
            <slot name="loading">
                Loaded {{ loaded }} of {{ total }} graphs.
            </slot>
        </div>
        <div v-else>
            <slot name="default">
                Loaded {{ total }} graphs.
            </slot>
        </div>
    </div>
</template>

<script>
import { EventBus } from '../../../../event_bus.js';

export default {
    mounted() {
        this.init()
    },
    data() {
        return {
            loading: false,
            loaded: 0,
            total: 0,
            charts: [],
        }
    },
    methods: {
        init() {
            EventBus.$on('graph-create', (evt) => {
                if(this.charts[evt.id]) {
                    console.log("Double load?");
                } else {
                    this.charts[evt.id] = 'created';
                    this.total+= 1;
                }
                this.loading = (this.total != this.loaded);
            });
            EventBus.$on('graph-ready', (evt) => {
                if(this.charts[evt.id] === undefined) {
                    console.log("Got ready before create?");
                }
                else if(this.charts[evt.id] != 'ready') {
                    this.loaded += 1;
                    this.charts[evt.id] = 'ready';
                } else if(this.charts[evt.id] == 'ready') {
                    // multiple resizes cause this.
                }
                this.loading = (this.total != this.loaded);
            });
        }
    }
}
</script>
