<style lang="scss">
.site-license-request {
  label {
    font-size: 14px;
  }
}
</style>

<template>
<div>
  <button class="btn btn-block btn-lg" :class="btnClass" style="font-size: 18px" @click="showDialog = true">
    {{ btnLabel }}
  </button>

  <b-modal v-model="showDialog" title="Contact Us To Get Your School Started with Lyrics2Learn" ref="modal">
    <div v-if="successMessage && successMessage.length">
      <!-- this looks an awfully lot like a button -->
      <h4 class="alert alert-success">{{ successMessage }}</h4>
    </div>
    <div v-else>
      <h4 class="alert alert-warning">To automatically generate a quote for your school, please fill out the following information.</h4>
      <h4 v-if="errorMessage" class="alert alert-error">{{ errorMessage }}</h4>
      <form class="form-horizontal site-license-request" action="generate_pricing_sch_invoice_pdf.php">

        <div class="form-group">
          <label class="col-xs-4 control-label">Name of School:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.school.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Address:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.address.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">City:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.city.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">State:</label>
          <div class="col-xs-8">
            <select class="form-control" v-model="v$.form.state.$model">
              <option value="">Please select one</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AR">Arkansas</option>
              <option value="AZ">Arizona</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DC">District of Columbia</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Zip Code:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.zip.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">First Name:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.firstname.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Last Name:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.lastname.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Job Title:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.title.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Email Address:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.email.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Phone:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.phone.$model" type="text" />
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Number of Teacher Subscriptions Requested:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.subscription_count.$model" type="text" />
          </div>
        </div>

        <div v-if="showReferral">
          <div class="form-group">
            <label class="col-xs-4 control-label">How Did You Find Out About Lyrics2Learn?</label>
            <div class="col-xs-8">


              <select class="form-control" v-model="v$.form.referral.$model">
                <option value="">Please select one</option>
                <option value="Pinterest">Pinterest</option>
                <option value="A Friend Referred Me">A Friend Referred Me</option>
                <option value="Search Engine">Search Engine</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="Other">Other</option>
              </select>

            </div>
          </div>

          <div class="form-group">
            <label class="col-xs-4 control-label">Referral Code (optional)</label>
            <div class="col-xs-8">
              <input  class="form-control" type="text" v-model="v$.form.referralCode.$model">
            </div>
          </div>
        </div>

      </form>
    </div>

    <div slot="modal-footer">
      <div v-if="successMessage && successMessage.length">
        <a class="btn btn-success" @click.prevent="reset()">Submitted!</a>
      </div>
      <div v-else>
        <a class="btn btn-secondary mr-2" @click.prevent="showDialog = false">
          Cancel
        </a>
        <a class="btn btn-primary" :disabled="v$.form.$invalid || submitting" @click.prevent="submit()">
          <span v-if="submitting">Sending...</span>
          <span v-else>Generate Quote</span>
        </a>
      </div>
    </div>

  </b-modal>

</div>

</template>

<script>
  import useVuelidate from "@vuelidate/core"
  import {decimal, email, maxLength, minLength, required, requiredIf, requiredUnless} from "@vuelidate/validators"

  export default {
    setup() {
      return {
        v$: useVuelidate(),
      }
    },
    props: {
      btnClass: {
        type: String,
        required: false,
        default: 'btn-success'
      },
      btnLabel: {
        type: String,
        required: false,
        default: 'Get a Site License Quote'
      },
      referralCode: {
        type: String,
        required: false,
        default: '',
      }
    },
    data(){
      return {
        submitting: false,
        showDialog: false,
        successMessage: '',
        errorMessage: '',
        form: {
          school: '',
          address: '',
          city: '',
          state: '',
          zip: '',
          firstname: '',
          lastname: '',
          title: '',
          email: '',
          phone: '',
          subscription_count: '',
          referral: this.referralCode ? "A Friend Referred Me" : '',
          referralCode: this.referralCode || '',
        },
        showReferral: !this.referralCode
      };
    },
    methods: {
      reset() {
        for(var k in this.form) {
          this.form[k] = '';
        }
        this.showDialog = false;
      },
      submit() {
        // token - is the token object
        // args - is an object containing the billing and shipping address if enabled
        // do stuff...

        this.successMessage = '',
        this.errorMessage = '';
        this.submitting = true;
        axios.post('/school_pricing_invoice', this.form)
        .then((response) => response.data)
        .then((data) => {
          if(data.success) {
            // redirect.
            this.successMessage = data.message;
          } else {
            this.errorMessage = data.message
          }
        }).catch((error) => {
          try {
            if(error.response.data.errors) {
              var msg = '';
              for(var k in error.response.data.errors) {
                msg += error.response.data.errors[k][0];
              }
              this.errorMessage = msg;
            }
          } catch {
            this.errorMessage = error.toString();
          }
        }).finally(() => {
          this.submitting = false;
        });
      },
    },
    validations: {
      form: {
        school: { required },
        address: { required },
        city: { required },
        state: { required },
        zip: {required },
        firstname: { required },
        lastname: { required },
        title: {},
        email: { required, email },
        phone: {},
        subscription_count: { required },
        referral: { required },
        referralCode: {},
      }
    }
  }
 </script>
