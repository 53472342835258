<template>
<!-- href="{{ route('teacher.reports.classroom', [
		'student_id' => $student_id,
		'from_date' => $from_date,
		'to_date' => $to_date,
		'type' => 'pdf',
		'download' => true ]) }}" -->
		<!-- <a v-show="valid" target="_blank" class="btn btn-danger" :disabled="generating" @click.prevent="generate()">{{ title }}</a> -->

	<form method="POST" :action="url">
		<input type="hidden" name="start_date" :value="from">
		<input type="hidden" name="end_date" :value="to">
		<input type="hidden" name="_token" :value="csrf">
		<input type="hidden" name="media" value="pdf">
		<input type="hidden" name="download" value="true">
		<input type="hidden" v-for="chart in charts" :key="chart.type" :name="chart.type" :value="chart.image">
		<input v-show="valid" type="submit" class="btn btn-danger" :class="btnClass" :disabled="generating" :value="title">
	</form>
</template>

<script>
import { EventBus } from '../event_bus';

export default {
	mounted() {
		this.init()
	},
	data() {
		return {
			generating: false,
			valid: false,
			charts: [],
			title: 'Generate PDF'
		}
	},
	props: {
		url: {
			type: String,
			required: true,
		},
		studentId: {
			type: String,
			required: true
		},
		from: {
			type: String,
			required: true,
		},
		to: {
			type: String,
			required: true,
		},
		csrf: {
			type: String,
			required: true,
		},
		graphCount: {
			type: Number,
			required: true
		},
		btnClass: {
			type: String,
			required: false,
			default: ''
		}
	},
	methods: {
		generate() {
			this.title = "Generating...";
			this.generating = true;
        	axios.post(this.url, { charts: this.charts })
        	.then((response) => response.data)
        	.then((data) => {
				//
        	}).catch((error) => {
          		console.error(error);
        	}).finally(() => {
			  this.generating = false;
			  this.title = "Generate PDF";
        	})
		},
		init() {
			EventBus.$on('graph-ready', (evt) => {
				if(this.studentId == evt.student_id)
				{
					var replacingIndex = -1;
					this.charts.forEach((item, index) => {
						if(item.type==evt.type) {
							replacingIndex = index;
						}
					});

					if(replacingIndex >= 0) {
						this.charts[replacingIndex] = evt;
					} else {
						this.charts.push(evt);
					}

					this.valid = (this.charts.length==this.graphCount);
				}
			})
		}
	}
}
</script>
