<script>
import axios from "axios"
import FluencyReviewPopup from "./FluencyReviewPopup.vue"

const CLASS_SUMMARY_ENDPOINT = "/teacher/{teacher}/dashboard/class_summary"
const FLUENCY_GRADES_ENDPOINT = "/teacher/recordings/all"

export default {
  components: {FluencyReviewPopup},

  props: {
    teacherId: {
      type: String,
      required: true,
    },
    studentId: {
      type: String,
      default: "overall",
    },
    startDate: {
      type: String,
      default: undefined,
    },
    endDate: {
      type: String,
      default: undefined,
    },
  },

  data() {
    return {
      loading: false,
      response: null,
      error: null,
      selectedStudent: null,
      showViewStudent: false,
      fluencyGrades: {},
    }
  },

  computed: {
    summaryEndpoint() {
      return CLASS_SUMMARY_ENDPOINT.replace("{teacher}", `${this.teacherId}`)
    },
    students() {
      return this.response?.data?.students ?? []
    },
    groups() {
      return this.response?.data?.groups ?? []
    },
    viewStudentPopupTitle() {
      const student = this.selectedStudent
      if (!student) return "(no student selected)"
      return `Recent and Ungraded Videos for ${student.name}`
    },
  },

  watch: {
    students(students) {
      students.forEach(student => (student.cold || student.hot || student.benchmarks) && this.fetchFluencyGrades(student.id))
    },
  },

  methods: {
    load() {
      this.loading = true
      const params = {
        type: "new",
        student_id: this.studentId,
        start_date: this.startDate,
        end_date: this.endDate,
      }
      axios
        .get(this.summaryEndpoint, {params})
        .then(response => this.response = response)
        .catch(error => this.error = error.response)
        .finally(() => this.loading = false)
    },

    fetchFluencyGrades(student_id) {
      axios
        .get(FLUENCY_GRADES_ENDPOINT, {params: {student_id}})
        .then(response => this.fluencyGrades = {...this.fluencyGrades, [student_id]: response.data})
        .catch(error => console.log(error))
    },

    renderPct(val) {
      return val === null ? "--" : `${val}%`
    },

    viewStudent(student) {
      this.selectedStudent = student
      this.showViewStudent = true
    },

    onHideViewStudent() {
      this.selectedStudent = null
    },

    goalStatus(student) {
      const goal = Object.entries(student.goals).find(([_, v]) => v)[0]
      return goal && goal !== "none" ? goal : null
    },

    isUngraded(type, video, student) {
      return video.type === type
        && video.transcode_status === 'Complete'
        && !video.graded_at
        && student.id === video.student_id // FIXME: API should be filtering this, not the client
    }
  },

  created() {
    this.load()
  },
}

/*
type Student = {
  id: number
  name: string
  lessons: number|null
  comprehension: number|null
  avg_wpm: number|null
  avg_lexile: number|null
  goals: {
    met: number
    approaching: number
    below: number
    none?: number
  }
}

const example_student: Student = {
  "id": 1197642,
  "name": "Allison Reynolds",
  "lessons": 0,
  "comprehension": null,
  "avg_wpm": null,
  "avg_lexile": null,
  "goals": {
    "met": 0,
    "approaching": 0,
    "below": 0,
    "none": 1
  },
  "cold": 0,
  "hot": 0,
  "benchmarks": 0
}

*/
</script>

<template>
  <div class="class-summary-table">
<!--        <pre>{{ {fluencyGrades} }}</pre>-->
    <div class="card card-sm card-body-height">
      <div class="table-responsive datatable-custom">
        <table class="table table-borderless card-table">
          <thead class="thead-light text-center">
          <tr>
            <th>&nbsp;<!-- view button --></th>
            <th>Student</th>
            <th>Lessons</th>
            <th>Stories</th>
            <th>Comprehension Proficiency</th>
            <th>Cold Read</th>
            <th>Hot Read</th>
            <th>Benchmarks</th>
            <th>Fluency Proficiency</th>
          </tr>
          </thead>

          <tbody>
          <tr v-for="student in students" :key="student.id">
            <td class="view-button">
              <div class="btn btn-link p-0" @click="viewStudent(student)">
                <i class="bi bi-search"></i>
              </div>
            </td>
            <td class="name">{{ student.name }}</td>
            <td class="lessons">{{ student.lessons }}</td>
            <td class="stories">{{ student.stories }}</td>
            <td class="comprehension">{{ renderPct(student.comprehension) }}</td>
            <td class="cold">
              {{ student.cold }}
              <span
                v-if="fluencyGrades[student.id]?.find(video => isUngraded('Cold Read', video, student))"
                class="new-badge"
                @click="viewStudent(student)"
              >
                new
              </span>
            </td>
            <td class="hot">
              {{ student.hot }}
              <span
                v-if="fluencyGrades[student.id]?.find(video => isUngraded('Hot Read', video, student))"
                class="new-badge"
                @click="viewStudent(student)"
              >
                new
              </span>
            </td>
            <td class="benchmark">
              {{ student.benchmarks }}
              <span
                v-if="fluencyGrades[student.id]?.find(video => isUngraded('Benchmark', video, student))"
                class="new-badge"
                @click="viewStudent(student)"
              >
                new
              </span>
            </td>
            <td class="fluency-goal">
              <div v-if="goalStatus(student)" :class="`goal-${goalStatus(student)}`">
                {{ goalStatus(student) }} goal
              </div>
              <div v-else class="text-muted">-</div>
            </td>
          </tr>
          <tr v-if="groups.length">
            <td colspan="9" style="padding: 0; margin: 0;">&nbsp;</td>
          </tr>
          <tr v-if="groups.length">
            <td colspan="8" style="border-top: 1px solid #ccc;"></td>
          </tr>
          <tr v-for="group in groups" :key="group.id">
            <td><!-- groups don't have a view button --></td>
            <td class="name">
              <!--
                Hack that takes advantage of group names being hardwired right now.
                We'll want to add a 'color' column to student_groups at some point...
              -->
              <span :class="['group', group.name.split(' ')[0].toLowerCase()]">{{ group.name }}</span>
            </td>
            <td class="lessons">{{ group.lessons }}</td>
            <td class="stories">{{ group.stories }}</td>
            <td class="comprehension">{{ renderPct(group.comprehension) }}</td>
            <td class="cold">{{ group.cold }}</td>
            <td class="hot">{{ group.hot }}</td>
            <td class="benchmark">{{ group.benchmarks }}</td>
            <td class="fluency-goal">
              <div v-for="status in ['met', 'approaching', 'below', 'none']" :key="status" :class="`goal-${status}`">
                <div v-if="group.goals[status]">
                  {{ status }}: {{ group.goals[status] }}
                </div>
              </div>
            </td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>

    <b-modal
      v-model="showViewStudent"
      :title="viewStudentPopupTitle"
      hide-footer
      size="xl"
      @hide="onHideViewStudent"
    >
      <FluencyReviewPopup v-if="selectedStudent" :student-id="selectedStudent.id"/>
      <!--<pre>{{selectedStudent}}</pre>-->
    </b-modal>
  </div>
</template>

<style lang="scss" scoped>
@import "../../sass/bs5_theme_vars";

.class-summary-table {
  &:deep(.card-body-height) {
    // cards normally have a pretty conservative height
    height: inherit;
    max-height: 50rem;
  }
}

th {
  text-align: center;
  vertical-align: middle !important;
  font-weight: bold !important;
}

td {
  white-space: nowrap;
  text-align: center;

  &.view-button {
    max-width: 1rem;
  }

  &.name {
    text-align: inherit;
  }

  &.cold {
    color: $bs-blue;
  }

  &.hot {
    color: $bs-red;
  }

  &.benchmark {
    color: $bs-gray;
  }

  &.fluency-goal {
    font-variant: small-caps;
  }
}

.goal-met {
  color: $bs-success;
}

.goal-approaching {
  color: $bs-warning;
}

.goal-below {
  color: $bs-danger;
}

.group {
  border: 1px solid #ccc;
  padding: 0.25rem 1rem;
  border-left-width: 1.25rem;

  &.green {
    border-left-color: $bs-green;
  }

  &.yellow {
    border-left-color: $bs-yellow;
  }

  &.blue {
    border-left-color: $bs-blue;
  }

  &.pink {
    border-left-color: $bs-pink;
  }
}

.new-badge {
  cursor: pointer;
  font-size: 0.75rem;
  font-weight: bold;
  background-color: $bs-blue;
  color: $bs-white;
  padding: 0.15rem 0.5rem;
  border-radius: 1rem;
}
</style>
