<script lang="ts" setup="">
import {computed, ref} from "vue";

const props = withDefaults(defineProps<{
  videoId: string | number
  thumbnail: string
  alt?: string
  h?: string|undefined  // required for non-public videos
  params?: Record<string, string>
}>(), {
  alt: "",
  h: undefined,
  params: () => ({}),
})
// const emit = defineEmits<{}>()

const showModal = ref(false)

const queryString = computed(() => {
  const params = new URLSearchParams({autoplay: "1", ...props.params});
  props.h && params.set("h", props.h);
  return params.toString();
})

const playerUrl = computed(() => `https://player.vimeo.com/video/${props.videoId}?${queryString.value}`)

</script>

<template>
  <div class="vimeo-popout">
    <div class="video-thumbnail-container" @click="showModal = true">
      <img class="video-thumbnail" :src="thumbnail" :alt="alt">
      <div class="play-button-overlay"> ▷</div>
    </div>

    <b-modal v-model="showModal" title="" size="lg" hide-header hide-footer>
      <div class="video-frame">
        <iframe v-if="showModal" :src="playerUrl" allow="autoplay; fullscreen; picture-in-picture"></iframe>
      </div>
    </b-modal>
  </div>
</template>

<style scoped lang="scss">

.video-thumbnail-container {
  position: relative;
}

.video-thumbnail {
  border-radius: 0.5rem;
}

iframe {
  width: 100%;
  height: 100%;
}

.play-button-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
  color: #ccc;
  background-color: rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: color 0.2s;
}

.play-button-overlay:hover {
  color: white;
}

.video-frame {
  display: flex;
}

.video-frame iframe {
  min-height: 30rem;
}
</style>
