<script>
import FluencyGradeButton from "~components/teacher/fluency/FluencyGradeButton.vue"

export default {
  components: {FluencyGradeButton},

  props: {
    videos: {type: Array, required: true},
  },

  methods: {
    deleteRecording(row) {
      axios.delete(`/api/v2/recording_upload/${row.id}`).then(() => window.location.reload())
    }
  }
}
</script>

<template>
  <datatable
    :columns="[{label: 'First Name', field: 'firstname'},
               {label: 'Last Name', field: 'lastname'},
               {label: 'Created At', field: 'created_at'},
               {label: 'Lesson', field: 'lesson_title'},
               {label: 'Type', field: 'type'},
               {label: '', field: '', sortable: false}]"
    :data="videos"
    class="table table-striped table-responsive table-bordered"
    v-slot="{row}"
  >
    <tr>
      <td>{{ row.firstname }}</td>
      <td>{{ row.lastname }}</td>
      <td>{{ row.created_at }}</td>
      <td>{{ row.lesson_title }}</td>
      <td>{{ row.type }}</td>
      <td>
        <FluencyGradeButton
          v-if="row.graded_at"
          :video-id="row.id"
          label="View Grades"
          variant="secondary"
        />
        <div v-else style="display:flex; justify-content: space-between;">
          <div v-if="row.transcode_status === 'Complete'">
            <FluencyGradeButton
              v-if="row.type === 'Comparison'"
              label="View Comparison"
              variant="primary"
              :video-id="row.id"
              @graded="$emit('graded', row.id)"
            />
            <FluencyGradeButton
              v-else
              label="Grade Video"
              variant="primary"
              :video-id="row.id"
              @graded="$emit('graded', row.id)"
            />
          </div>
          <div v-else>
            <!-- Usually Waiting... -->
            {{ row.transcode_status }}...
          </div>
          <!-- TODO: use ActionButton (need fontawesome in teacher UI first) -->
          <b-button
            v-if="row.type.includes(' Read')"
            variant="danger"
            @click="deleteRecording(row)"
          >
            <i class="bi bi-trash"></i>&nbsp;Delete
          </b-button>
        </div>
      </td>
    </tr>
  </datatable>
</template>
