<template>
  <div class="search-students" @keyup.enter="search">
    <div class="role-selector">
      <div class="field">
        <input
          v-model="query.role"
          id="da-query-rb-teacher"
          type="radio"
          value="teacher"
          name="type"
        >
        <label for="da-query-rb-teacher">Search Teachers</label>
      </div>
      <div class="field" style="max-width: 15em">
        <input
          v-model="query.role"
          id="da-query-rb-student"
          type="radio"
          value="student"
          name="type"
        >
        <label for="da-query-rb-student">Search Students</label>
      </div>
    </div>

    <div class="field">
      <label for="da-query-q">Login/Name/Email</label>
      <input id="da-query-q" class="form-control" v-model="query.q"/>
    </div>

    <datatable v-if="teachers && teachers.length" :columns="teacherColumns" :data="teachers">
      <template #default="{row: teacher}">
        <tr>
          <td>
            <tooltip :text="`Log in as ${teacher.username}`">
              <a class="impersonate-link" :href="`/impersonate/take/${teacher.id}`">
                <i class="bi bi-box-arrow-in-right"></i>
              </a>
            </tooltip>
            {{ teacher.username }}
          </td>
          <td>{{ teacher.firstname }}</td>
          <td>{{ teacher.lastname }}</td>
          <td>{{ teacher.email }}</td>
          <td>{{ teacher.schoolname }}</td>
        </tr>
      </template>
    </datatable>

    <datatable v-if="students && students.length" :columns="studentColumns" :data="students">
      <template #default="{row: student}">
        <tr>
          <td>
            <a class="impersonate-link" :href="`/impersonate/take/${student.id}`">
              <i class="bi bi-box-arrow-in-right"></i>
            </a>
            {{ student.username }}
          </td>
          <td>{{ student.firstname }}</td>
          <td>{{ student.lastname }}</td>
          <td>{{ student.teachername }}</td>
          <td>{{ student.schoolname }}</td>
        </tr>
      </template>
    </datatable>
  </div>
</template>

<style lang="scss" scoped>
.search-students {
  .impersonate-link {
    font-weight: bold;
    font-size: 1.5rem;
    padding: 0.25em;
  }

  .role-selector {
    margin-bottom: 2em;
  }

  input[type="radio"] {
    width: 1.5rem;
    height: 1.5rem;
    margin-right: 1rem;
  }

  .field {
    display: flex;
    align-items: center;

    label {
      margin: 0.25em 1em;
    }
  }
}

</style>

<script>
import {Tooltip} from 'uiv';

// The query involves a lot of 'or' conditions, which object services can't currently express
const SEARCH_ENDPOINT = '/district_admin/search_postback'

export default {
  components: {Tooltip},

  data() {
    return {
      query: {
        role: 'teacher',
        q: null
      },
      loading: false,
      response: null,

      teacherColumns: [
        {label: 'Username', field: 'username'},
        {label: 'First Name', field: 'firstname'},
        {label: 'Last Name', field: 'lastname'},
        {label: 'Email', field: 'email'},
        {label: 'School', field: 'schoolname'},
      ],

      studentColumns: [
        {label: 'Username', field: 'username'},
        {label: 'First Name', field: 'firstname'},
        {label: 'Last Name', field: 'lastname'},
        {label: 'Teacher', field: 'teacher'},
        {label: 'School', field: 'schoolname'},
      ],
    }
  },

  computed: {
    teachers() {
      const teachers = this.response?.data?.data?.teachers || []
      return teachers.map(t => ({
        id: t.userid,
        username: t.login,
        firstname: t.firstname,
        lastname: t.lastname,
        email: t.email,
        schoolname: t.school.schoolname
      }))
    },

    students() {
      const students = this.response?.data?.data?.students || []
      return students.map(s => ({
        id: s.userid,
        username: s.login.replace(/^.*~#~/, ''),
        firstname: s.firstname,
        lastname: s.lastname,
        teachername: `${s.teacher?.firstname} ${s.teacher?.lastname} (${s.teacher.email})`,
        schoolname: s.school.schoolname,
      }))
    }
  },

  methods: {
    async search() {
      console.log("SEARCH", this.query)
      try {
        this.loading = true
        this.response = await axios.post(SEARCH_ENDPOINT, this.query)
      } finally {
        this.loading = false
      }
    },
  },
}

</script>
