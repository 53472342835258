import {useFetcher} from "@/common/utils/fetch"

type LogoutArgs = {to?: string}

export function useLogoutFunction(args: LogoutArgs = {}) {
  const logout = useFetcher("/logout", {immediate: false}).post()
  logout.onFetchResponse(() => window.location.href = args.to ?? "/")
  return logout.execute
}

// Not a fan of the type name, but I don't want to just call it User.  UserInfo it is, I guess...
export type UserInfo = {
  id: number
  fullname: string
  nickname: string
  role: string
  impersonated: boolean
}
