<script setup lang="ts">
import {route} from "@/common/utils/ziggy"
import UserAvatar from "~components/UserAvatar.vue"
import {computed} from "vue"
import {useLogoutFunction, UserInfo} from "@/common/utils/auth"

const props = defineProps<{user: UserInfo}>()

const logout = useLogoutFunction()

const isSchoolAdmin = computed(() => props.user.role === "School Admin")
const isDistrictAdmin = computed(() => props.user.role === "District Admin")
const isTeacher = computed(() => !isSchoolAdmin.value && !isDistrictAdmin.value)

function reportRoute(name: string) {
  return route(`teacher.reports.${name}`, {student_id: "overall"})
}
</script>

<template>

  <b-navbar toggleable="md" class="site-nav-bar">
    <b-navbar-brand href="/">
      <img src="/assets/images/newLogo.png" class="logo">
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse" />

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav v-if="isTeacher">
        <b-nav-item-dropdown text="Class Scores and Data">
          <b-dropdown-item :href="reportRoute('seven_day')">Student 7 Day Progress</b-dropdown-item>
          <b-dropdown-item :href="reportRoute('classroom')">Class Performance</b-dropdown-item>
          <b-dropdown-item :href="reportRoute('detailed')">Detailed Reports</b-dropdown-item>
          <b-dropdown-item :href="reportRoute('scores_by_story')">Class Scores By Story</b-dropdown-item>
          <b-dropdown-item :href="reportRoute('conference_reports')">Conference Reports</b-dropdown-item>
          <!-- may need to be conditional -->
          <b-dropdown-item :href="reportRoute('fluency_reports')">Fluency Reports</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Manage Students">
          <b-dropdown-item :href="route('teacher.assign')">Assign Lessons</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.students.edit')">Add or Edit Students</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.students.groups')">Edit Student Groups</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.students.email')">Email Parents</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.settings')">Personalize Learning</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Fluency">
          <b-dropdown-item :href="reportRoute('fluency_reports')">Fluency Overview</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.recordings')">Recorded Fluency Videos</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Resources">
          <b-dropdown-item :href="route('teacher.tutorials')">Tutorial Videos</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.lessons')">View All Lessons</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.worksheets')">Worksheets</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.boost_fluency')">Printable Resources</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.scope_and_sequence')">Scope and Sequence</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav v-else-if="isSchoolAdmin">
        <b-nav-item-dropdown text="Reports">
          <b-dropdown-item :href="route('school_admin.reports.detailed')">School Use and Data</b-dropdown-item>
          <b-dropdown-item :href="route('school_admin.reports.concept_scores')">Concept Scores</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Admin">
          <b-dropdown-item :href="route('school_admin.teachers')">Usernames and Passwords</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.lessons')">View All Lessons</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Resources">
          <b-dropdown-item :href="route('teacher.tutorials')">Tutorial Videos</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.lessons')">View All Lessons</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.worksheets')">Worksheets</b-dropdown-item>
          <b-dropdown-item href="/du-summary">Research and Evidence</b-dropdown-item>
          <b-dropdown-item :href="route('school_admin.request_quote')">Bring L2L to your District</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <b-navbar-nav v-else-if="isDistrictAdmin">
        <b-nav-item-dropdown text="Admin">
          <b-dropdown-item :href="route('district_admin.search')">Search Teachers & Students</b-dropdown-item>
          <b-dropdown-item :href="route('district_admin.settings')">Edit My District</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown text="Resources">
          <b-dropdown-item :href="route('teacher.tutorials')">Tutorial Videos</b-dropdown-item>
          <b-dropdown-item href="/du-summary">Research and Evidence</b-dropdown-item>
          <b-dropdown-item :href="route('school_admin.request_quote')">Bring L2L to your District</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>

      <!-- right-aligned items are common to all users (use v-if on individual items for any exceptions) -->
      <b-navbar-nav class="ml-auto">
        <b-nav-item-dropdown text="Help" right style="margin-top: .3rem">
          <b-dropdown-item :href="route('teacher.how_to_use')">How To Use Lyrics2Learn</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.tutorials')">Tutorial Videos</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.qna')">Q & A</b-dropdown-item>
          <b-dropdown-item :href="route('teacher.feedback')">Feedback / Questions</b-dropdown-item>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown right>
          <template #button-content>
            <UserAvatar :user="user" :size="32" />
          </template>
          <div v-if="user.impersonated" class="signed-in-as impersonated">IMPERSONATING: {{ user.fullname }}</div>
          <div v-else class="signed-in-as">Signed in as {{ user.fullname }}</div>
          <hr class="m-3">
          <b-dropdown-item :href="route('teacher.settings')">Settings and Preferences</b-dropdown-item>
          <b-dropdown-item v-if="user.impersonated" href="/impersonate/leave">Return to Admin</b-dropdown-item>
          <b-dropdown-item v-else @click="logout">Sign Out</b-dropdown-item>
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<style scoped lang="scss">
@import '../../../../sass/bs5_theme_vars';

.site-nav-bar {
  position: sticky;
  top: 0;
  z-index: 9999;
  max-height: 3rem;

  background-color: lighten($bs-blue, 26%);
  border-bottom: 1px solid lighten($bs-blue, 18%);

  .navbar-collapse.show {
    background-color: white;
    border: 3px solid #ccc;
    border-radius: 1rem;
    padding: 1rem;

  }

  &:deep(.nav-link) {
    color: $bs-dark;
  }

  &:deep(.navbar-nav) {
    // menu dropdowns are center-aligned, but that looks too high next to the logo image,
    // so we push them down a bit so it's more like a baseline alignment.
    margin-top: 0.5rem;
  }

  &:deep(.navbar-toggler) {
    // Opposite problem with the toggler, it's too low
    transform: translateY(-0.5rem);
  }

  &:deep(.dropdown-item) {
    font-size: 1rem;
  }
}

img.logo {
  max-height: 2.5rem;
}

@media(max-width: 768px) {
  img.logo {
    transform: translateY(-0.5rem);
  }
}

.profile-icon {
  font-weight: bold;
}

.signed-in-as {
  text-align: center;
  white-space: nowrap;

  &.impersonated {
    color: $bs-red;
  }
}

</style>
