<template>
  <div>
    <table class="table table-striped table-bordered table-hover text-center">
      <thead>
      <tr>
        <th>&nbsp;</th>
        <th>Last 30 Days</th>
        <th>School Year</th>
      </tr>
      </thead>

      <tbody>
      <tr>
        <td>Student Learning</td>
        <td>{{ minutes(thirtyDay.student_learning_time) }}</td>
        <td>{{ minutes(schoolYear.student_learning_time) }}</td>
      </tr>
      <tr>
        <td>Teacher Investment</td>
        <td>{{ minutes(thirtyDay.teacher_investment_time) }}</td>
        <td>{{ minutes(schoolYear.teacher_investment_time) }}</td>
      </tr>
      <tr>
        <td>Active Students</td>
        <td>{{ thirtyDay.active_students }}</td>
        <td>{{ schoolYear.active_students }}</td>
      </tr>
      <tr>
        <td>Active Teachers</td>
        <td>{{ thirtyDay.active_teachers }}</td>
        <td>{{ schoolYear.active_teachers }}</td>
      </tr>
      <tr>
        <td>Choral & Repeated Reading Fluency Practice</td>
        <td>{{ minutes(thirtyDay.minutes_spent_reading) }}</td>
        <td>{{ minutes(schoolYear.minutes_spent_reading) }}</td>
      </tr>
      <tr>
        <td>Literal, Inferential, Complex Comprehension Practice</td>
        <td>{{ minutes(thirtyDay.comprehension_practice_time) }}</td>
        <td>{{ minutes(schoolYear.comprehension_practice_time) }}</td>
      </tr>
      <tr>
        <td>Fiction Lessons Completed</td>
        <td>{{ thirtyDay.genres[0].count }}</td>
        <td>{{ schoolYear.genres[0].count }}</td>
      </tr>
      <tr>
        <td>Non-Fiction Lessons Completed</td>
        <td>{{ thirtyDay.genres[1].count }}</td>
        <td>{{ schoolYear.genres[1].count }}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<style scoped>
</style>

<script>

export default {

  components: {},

  props: {
    summary: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      // ...
    }
  },

  computed: {
    thirtyDay() {
      return this.summary.last_30_days.breakdown[0].overall
    },

    schoolYear() {
      return this.summary.school_year.breakdown[0].overall
    },
  },

  methods: {
    minutes(minutes) {
      return minutes.toLocaleString() + ' minutes'
    }
  },

}
</script>
