<template>
  <div>
    <div v-for="(conceptBlock, idx) in yearConcepts" :key="`blk-${idx}`">
      <h3>{{ conceptBlock.title }}</h3>
      <table>
        <thead>
        <th>Concept</th>
        <th>Correct Answers</th>
        <th>Total Questions</th>
        <th>Accuracy</th>
        </thead>

        <tr v-for="(concept, idx) in conceptBlock.concepts" :key="`k1-${idx}`">
          <td>{{ concept.name }}</td>
          <td>{{ concept.correct }}</td>
          <td>{{ concept.total }}</td>
          <td>
            <concept-meter :percentage="parseInt(concept.percentage) || 0" :total="concept.total" :color="concept.color"/>
          </td>
        </tr>
      </table>
    </div>
  </div>
</template>

<style scoped>
</style>

<script>


export default {

  components: {},

  props: {
    summary: {
      type: Object,
      required: true,
    }
  },

  data() {
    return {
      // ...
    }
  },

  computed: {
    yearConcepts() {
      return this.summary.school_year.breakdown[0].concepts;
    }
  },

}
</script>
