<template>

<div>
  <section class="">
    <header>Select Parent Email Addresses</header>

    <div class="">
      <div class="">
        <input class="search" v-model="filter" placeholder="Search">
        <div>
          <button class="btn btn-primary" @click="selectAll(true)">Select All</button>
          <button class="btn btn-secondary" @click="selectAll(false)">Select None</button>
        </div>
      </div>
      <datatable :columns="columns" :data="students" :filter-by="filter">
        <template v-slot="{ row }">
          <tr :class="{selected: row.selected}"">
            <td class="shrinkwrap">
              <input type="checkbox" v-model="row.selected" @change="selectionChanged(row)">
            </td>
            <td @click="toggleRow(row)" class="shrinkwrap" >
              {{ row.name }}
            </td>
            <td @click="toggleRow(row)">
              {{ row.parentEmail }}
            </td>
          </tr>
        </template>
      </datatable>
    </div>

    <span class="tip">
      Note: If you don't see a student above that you're expecting, please check whether the parent is subscribed
      in the "Remote Learning / Parent Email" tab of your <a href="/teacher/students">classroom settings</a>
    </span>
  </section>
</div>

</template>


<style scoped>

  tr {
    cursor: pointer;
  }

  td {
    padding: 0.5rem;
  }

  .selected {
    background-color: lightblue;  /* todo: theme color */
  }

  .tip {
    font-style: italic;
    font-size: small;
    text-align: center;
  }

  input.search {
    margin-bottom: 1em;
  }

  /* Useful, but not very reusable: it's no good for long content, and you can't make all columns shrinkwrap */
  .shrinkwrap {
    width: 0.1%;
    white-space: nowrap;
  }
</style>

<script>

const TEST_ROWS = [
  {id: 111, name: 'foo bar', parentEmail: 'parent_1@example.com', selected: false},
  {id: 222, name: 'blah woof', parentEmail: 'parent_2@example.com', selected: false},
]

export default {

  props: {
    students: {
      type: Array,
      required: true,
    }
  },


  data() {
    return {
      columns: [],
      filter: '',
    }
  },

  computed: {
    selectedStudents() {
      return this.students.filter(r => r.selected)
    }
  },

  methods: {

    toggleRow(row) {
      row.selected = !row.selected
      this.selectionChanged(row)
    },

    selectionChanged(row) {
      this.$emit('input', this.selectedStudents)
    },

    selectAll(selected) {
      this.students.forEach(s => s.selected = selected)
      this.$emit('input', this.selectedStudents)
    }

  }

}

</script>
