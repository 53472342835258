<script setup lang="ts">
import {computed} from "vue"

type GoalStatus = "met" | "approaching" | "below" | "none"

const props = defineProps<{status: GoalStatus}>()
const variants = {met: "success", approaching: "warning", below: "danger", none: "muted"} as const
const variant = computed(() => variants[props.status] ?? "normal")
const label = computed(() => props.status === "none" ? "-" : `${props.status} goal`)
</script>

<template>
  <div class="fluency-goal-status" :class="`text-${variant}`">
    {{ label }}
  </div>
</template>

<style lang="scss" scoped>
.fluency-goal-status {
  display: inline;
  font-variant: small-caps;
  font-weight: bold;
}
</style>
