<script setup lang="ts">
import {reactive} from 'vue'
import {useFetcher} from "@/common/utils/fetch";

const BOOK_CALL_ENDPOINT = '/tutor/book-call'

const form = reactive({
  firstName: '',
  lastName: '',
  email: '',
  channel: 'tutor',
})

const postForm = useFetcher(BOOK_CALL_ENDPOINT, {immediate: false}).post(form).json()
</script>

<template>
  <b-card>
    <!--
      Book call buttons pop up an email capture for first name, last name and email. When they submit, they populate
      in the Reading Mastery pipeline as email capture (https://lyrics2learn.user.com/v2/crm/deal/), then go to the
      student intake form here: https://lyrics2learn.user.com/landing/8o0ibx/.
      That automatically moves them in the pipeline to Survey Lead.
    -->
    <h3 class="text-center mb-4">Book A Call with Lyrics2Learn</h3>

    <b-alert v-if="postForm.error.value" variant="soft-danger" show>
      <!-- errors should not ever be throw from this endpoint, so just give the status code and a generic message -->
      An error occurred ({{ postForm.statusCode }}). Please try again.
      If the problem continues, please <a href="/contact">contact us</a> and let us know.
    </b-alert>

    <b-alert v-if="postForm.data?.value?.success" variant="success" show>
      Thanks for your interest! You should receive an email shortly with a link to schedule your call.
    </b-alert>

    <form v-else method="post" @submit.prevent="postForm.execute()">
      <b-form-group label="First Name">
        <b-input v-model="form.firstName" maxlength="45" required trim/>
      </b-form-group>

      <b-form-group label="Last Name">
        <b-input v-model="form.lastName" maxlength="45" required trim/>
      </b-form-group>

      <b-form-group label="Email Address">
        <b-input v-model="form.email" type="email" required trim/>
      </b-form-group>

      <b-button type="submit" variant="primary" size="lg" class="d-block w-100">
        Book a Call
      </b-button>
    </form>
  </b-card>
</template>
