<style lang="scss" scoped>
</style>

<template>
  <div class="feedback-form">
    <div v-if="errorMessage.length" class="alert alert-danger">{{ errorMessage }}</div>
    <div v-if="successMessage.length" class="alert alert-success">{{ successMessage }}</div>

    <form novalidation>
      <p>We appreciate feedback!  It helps us improve. Please provide your feedback, ideas or suggestions in the space provided.</p>
      <div class="form-group">
        <textarea style="height: 200px" required placeholder="Enter feedback here" name="message" class="form-control"
          v-model="v$.message.$model">
        </textarea>
        <div class="error" v-if="!v$.message.required">Field is required</div>
        <div class="error" v-if="!v$.message.minLength">Field must have at least {{v$.message.$params.minLength.min}} letters.</div>
      </div>
      <button type="button" class="btn btn-success" @click.prevent="send()" :disabled="v$.$invalid || sending">
        <span v-if="sending">Sending...</span>
        <span v-else>Send</span>
      </button>
    </form>
  </div>
</template>

<script>

  import useVuelidate from "@vuelidate/core"
  import {decimal, email, maxLength, minLength, required, requiredIf, requiredUnless} from "@vuelidate/validators"

  export default {
    setup() {
      return {
        v$: useVuelidate(),
      }
    },

      mounted() {
        console.log('Component mounted.')
      },
      data() {
        return {
          sending: false,
          message: "",
          errorMessage: '',
          successMessage: ''
        }
      },
      props: {
        endpoint: {
          type: String,
          required: true,
        }
      },
      validations: {
        message: {
          required,
          minLength: minLength(3)
        }
      },
      methods: {
        send() {
          this.sending = true;
          this.errorMessage = this.successMessage = '';
          axios.post(this.endpoint, { message: this.message })
          .then((response) => response.data)
          .then((data) => {
            if(data.success) {
              this.successMessage = data.message;
              this.message = '';
            } else {
              this.errorMessage = data.message;
            }
          }).catch((error) => {
            this.errorMessage = error;
          }).finally(() => {
            this.sending = false;
          })
        }
      }
    }
</script>
