<style lang="scss" scoped>
    label {
        // Temporary until everything is consistently using labels
        font-weight: initial;
    }
</style>


<template>
<div class="container bg-white bg-opacity-75 card p-6">
	<h1 class="text-primary">My Settings and Preferences</h1>
	<div v-if="successMessage" align="center" class="text-success" style="font-size:14px">{{ successMessage }}<br><br></div>

	<div v-if="loading">
		<h1>Loading...</h1>
	</div>
	<div v-else>
		<form novalidate>
			<div v-if="isActualTeacher" class="card mb-2">
				<div class="card-header">
					<h4 style="margin: 0;">Student Accountability Preferences</h4>
				</div>

				<div class="card-body">
					<div v-if="studentForm.errorMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-danger" role="alert">
								{{ studentForm.errorMessage}}
							</div>
						</div>
					</div>

					<div v-if="studentForm.successMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-success" role="alert">
								{{ studentForm.successMessage }}
							</div>
						</div>
					</div>

					<div v-if="isActualTeacher" class="row">
						<div class="col-sm-4">Weekly Report</div>
						<div class="col-sm-8">
							<div class="checkbox">
								<label class="text-success">
									<input type="checkbox" name="send_weekly_report" v-model="v$.student.send_weekly_report.$model" id="send_weekly_report"/>
									I am interested in receiving a weekly report via email about the progress
									of my students.<br>(Strongly Recommended)
								</label>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Retake Exam</div>
						<div class="col-sm-8">
							<div class="checkbox">
								<label>
									<input type="checkbox" name="retake_exm" v-model="v$.student.retake_exm.$model" id="retake_exm"/>
									Require students to retake a quiz if they score below 75%.
								</label>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Fluency Video</div>
						<div class="col-sm-8">
							<div class="checkbox">
								<label>
									<input type="checkbox" name="unlink_video" v-model="v$.student.unlink_video.$model" id="unlink_video"/>
									Require my students to complete their fluency
									practice (choral/repeated reading 3x)
									before progressing to the quiz.
								</label>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Slow Fluency Video</div>
						<div class="col-sm-8">
							<div class="checkbox">
								<label>
									<input type="checkbox" name="slow_video" v-model="v$.student.slow_video.$model" value="1" id="slow_video"/>
									Slow down the fluency video for my students.
								</label>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Student Username</div>
						<div class="col-sm-8">
							<div class="checkbox">
								<label>
									<input type="checkbox" name="student_username" v-model="v$.student.student_username.$model" value="1" id="student_username"/>
									I want my students to type their username rather than choosing it from a list
								</label>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Lesson Extensions</div>
						<div class="col-sm-8">
							<div class="checkbox">
								<label>
									<input type="checkbox" name="complete_lessons_extensions" v-model="v$.student.complete_lessons_extensions.$model"
									id="complete_lessons_extensions"/>
									Requires student to complete the constructed response extension for each
									lesson
								</label>
							</div>
						</div>
					</div>

					<div v-if="fluencyRecordingPossible">
						<div class="row">
							<div class="col-sm-4">Record Fluency Progress</div>
							<div class="col-sm-8">
								<div class="checkbox">
									<label>
										<input type="checkbox" name="record_student_fluency" v-model="v$.student.record_student_fluency.$model"
										id="record_student_fluency"/>
										Record student fluency performances to help assess progress.
									</label>
								</div>
							</div>
						</div>

						<div v-if="v$.student.record_student_fluency.$model" class="additional-fluency-questions">
							<div class="row hidden">
								<div class="col-sm-4"></div>
								<div class="col-sm-8" style="margin-bottom: 0px;">
									<div class="checkbox">
										<label>
											<input type="checkbox"
											name="student_fluency_have_parent_permission" v-model="v$.student.student_fluency_have_parent_permission.$model"
											id="student_fluency_have_parent_permission"/>
											All students have signed and returned a parent permission slip
											<div style="padding: 15px 0 0 25px;">
												<!-- teacher.file.download -->
												<a :href="hrefs.permission_slip" target="_blank">
													Download sample permission slip
												</a>
											</div>
										</label>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-sm-4"></div>
								<div class="col-sm-8">
									<div class="checkbox">
										<label>
											<input type="checkbox"
											name="student_fluency_have_admin_approval" v-model="v$.student.student_fluency_have_admin_approval.$model"
											id="student_fluency_have_admin_approval"/>
											I have received administrative approval from my principal to
											record student fluency videos
										</label>
									</div>
								</div>
							</div>

							<div class="row">
								<div class="col-sm-4">
									<label for="fluency_recording_mode">
										When recording fluency, only show:
									</label>
								</div>
								<div class="col-sm-8">
									<div class="form-group">
									<select
										class="form-control form-select"
										id="fluency_recording_mode"
										name="fluency_recording_mode"
										v-model="v$.student.student_record_fluency_lyrics_only.$model"
									>
										<option :value="false">Video Only</option>
										<option :value="true">Lyrics Only</option>
									</select>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Leave a Message For My Class</div>
						<div class="col-sm-8">
							<div class="form-group" style="display: flex">
								<input type="text" class="form-control" name="student_welcome_message"
								id="student_welcome_message" v-model="v$.student.welcome_message.$model"/>
              				</div>
						</div>
					</div>
				</div> <!-- card body -->

				<div class="card-footer text-right" v-if="v$.student.$anyDirty">
					<button type="submit" name="btn_student_accountability" class="btn btn-success btn-md" :disabled="studentForm.saving || v$.student.$invalid"
						@click.prevent="updateStudent()">
						<span v-if="studentForm.saving">Saving..</span>
						<span v-else>Save</span>
					</button>
				</div>
			</div>
		</form>



    <div v-if="isActualTeacher" class="card mb-2">
			<div class="card-header">
				<h4 style="margin: 0;">Subscription Information</h4>
			</div>
      <div v-if="subscriptionInformationLoading" class="card-body">
        <h4>Updating...</h4>
      </div>
			<div class="card-body" v-else>
				<div v-if="subscriptionForm.errorMessage" class="row">
					<div class="col-sm-12">
						<div class="alert alert-danger" role="alert">
							{{ subscriptionForm.errorMessage}}
						</div>
					</div>
				</div>

				<div v-if="subscriptionForm.successMessage" class="row">
					<div class="col-sm-12">
						<div class="alert alert-success" role="alert">
							{{ subscriptionForm.successMessage }}
						</div>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-4">Payment Method</div>
					<div class="col-sm-8">
						<strong v-if="subscription.subscription_channel=='stripe'">Credit Card</strong>
						<strong v-if="subscription.subscription_channel=='google'">Google Play</strong>
						<strong v-if="subscription.subscription_channel=='apple'">Apple Store</strong>
					</div>
				</div>
				<div class="row">
					<div class="col-sm-4">Subscription Type</div>
					<div class="col-sm-8">
						<strong>{{ subscriptionType }}</strong>
					</div>
				</div>
				<div class="row" v-if="subscriptionExpirationDateLabel">
					<div class="col-sm-4">{{subscriptionExpirationDateLabel}}</div>
					<div class="col-sm-8">
						<strong>
							{{ subscriptionExpirationDate }}
						</strong>
					</div>
				</div>
				<div class="row" v-if="subscriptionCardDetails">
					<div class="col-sm-4">Current Card</div>
					<div class="col-sm-8">
						<strong>
							{{ subscriptionCardDetails }}
						</strong>
						<vue-stripe-checkout
							v-if="subscriptionButtons.indexOf('update')>=0"
							ref="checkoutRef"
							image="/apple-touch-icon-120x120.png"
							name="Lyrics2Learn"
							label="Update Credit Card Details"
							:publishable-key="apiKey"
							locale="auto"
							:email="info.email"
							panelLabel="Update Credit Card"
							:allow-remember-me="false"
							@done="cardDone"
							@canceled="cardCancelled"
						>
						</vue-stripe-checkout>
						<button v-if="subscriptionButtons.indexOf('update')>=0"
							class="btn-primary btn btn-sm ml-2" @click="cardUpdate">
							Update Credit Card Details
						</button>
					</div>
				</div>
				<div v-if="subscriptionButtons.length>=0">
					<hr/>

					<div class="row">
						<div class="col-sm-12 text-center">
							<!-- subscribe button -->
							<a v-if="subscriptionButtons.indexOf('subscribe')>=0"
								class="btn btn-primary mr-2" :href="hrefs.stripe_pay">
								Subscribe Now
							</a>
							<button v-if="subscriptionButtons.indexOf('pause')>=0" class="btn btn-primary mr-2"
								@click.prevent="pauseSubscription()">
								Pause My Subscription
							</button>
							<button v-if="subscriptionButtons.indexOf('resume')>=0" class="btn btn-primary mr-2"
								@click.prevent="resumeSubscription()">
								Resume My Subscription
							</button>
							<button v-if="subscriptionButtons.indexOf('cancel')>=0" class="btn btn-primary mr-2"
								@click.prevent="cancelSubscription()">
								Cancel My Subscription
							</button>
							<div v-if="subscriptionButtons.indexOf('google-disclosure')>=0">
								<p>You setup your account with your Android device.  You must manage your account using the device..</p>
							</div>
							<div v-if="subscriptionButtons.indexOf('apple-disclosure')>=0">
								<p>You setup your account with your Apple device.  You must manage your account using the device.</p>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<form novalidate>
			<div class="card mb-2">
				<div class="card-header">
					<h4 style="margin: 0;">Login Information</h4>
					<div>
						<span style="color:#CC0000">*</span>
						<span style="font-size:11px">indicates required fields.</span>
					</div>
				</div>
				<div class="card-body">
					<div v-if="loginForm.errorMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-danger" role="alert">
								{{ loginForm.errorMessage }}
							</div>
						</div>
					</div>

					<div v-if="loginForm.successMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-success" role="alert">
								{{ loginForm.successMessage }}
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Username<span style="color:#CC0000">*</span></div>
						<div class="col-sm-8">
							<div class="form-group">
								<input required type="text" class="form-control" name="username" id="username"
									v-model="v$.login.username.$model"/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Password<span style="color:#CC0000">*</span></div>
						<div class="col-sm-8">
							<div class="form-group">
								<input required type="text" class="form-control" name="password"
								id="password" v-model="v$.login.password.$model"/>
							</div>
						</div>
					</div>
				</div>

				<div class="card-footer text-right" v-if="v$.login.$anyDirty">
					<button type="submit" value="btn_login_info" name="btn_login_info" @click.prevent="updateLogin()" class="btn btn-success btn-md" :disabled="v$.login.$invalid">
						<span v-if="loginForm.saving">Saving...</span>
						<span v-else>Update Login Information</span>
					</button>
				</div>
			</div>
		</form>

		<form novalidate>
			<div class="card mb-2">
				<div class="card-header">
					<h4 style="margin: 0;">My Information</h4>
					<div>
						<span style="color:#CC0000">*</span>
						<span style="font-size:11px">indicates required fields.</span>
					</div>
				</div>

				<div class="card-body">
					<div v-if="infoForm.errorMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-danger" role="alert">
								{{ infoForm.errorMessage }}
							</div>
						</div>
					</div>

					<div v-if="infoForm.successMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-success" role="alert">
								{{ infoForm.successMessage }}
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">First name <span style="color:#CC0000">*</span></div>
						<div class="col-sm-8">
							<div class="form-group">
								<input type="text" class="form-control" name="firstname" id="firstname"
								v-model="v$.info.firstname.$model"/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Last name <span style="color:#CC0000">*</span></div>
						<div class="col-sm-8">
							<div class="form-group">
								<input type="text" class="form-control" name="lastname" id="lastname"
									v-model="v$.info.lastname.$model"/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Email <span style="color:#CC0000">*</span></div>
						<div class="col-sm-8">
							<div class="form-group">
								<input type="text" class="form-control" name="email" id="email"
									v-model="v$.info.email.$model"/>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Address 1</div>
						<div class="col-sm-8">
							<div class="form-group">
								<textarea id="mailaddress1" class="form-control" name="mailaddress1" v-model="v$.info.mailaddress1.$model">
								</textarea>
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">City</div>
						<div class="col-sm-8">
							<div class="form-group">
								<input type="text" class="form-control" name="mailcity" id="mailcity"
									v-model="v$.info.mailcity.$model" />
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">State</div>
						<div class="col-sm-8">
							<div class="form-group">
								<input type="text" class="form-control" name="mailstate" id="mailstate"
									v-model="v$.info.mailstate.$model" />
							</div>
						</div>
					</div>

					<div class="row">
						<div class="col-sm-4">Zip</div>
						<div class="col-sm-8">
							<div class="form-group">
								<input type="text" class="form-control" name="mailzip" id="mailzip"
									v-model="v$.info.mailzip.$model" />
							</div>
						</div>
					</div>

				</div>

				<div class="card-footer text-right" v-if="v$.info.$anyDirty">
					<button type="submit" name="btn_user_info" class="btn btn-success btn-md" :disabled="v$.info.$invalid" @click.prevent="updateInfo()">
						<span v-if="infoForm.saving">Saving...</span>
						<span v-else>Save</span>
					</button>
				</div>
			</div>
		</form>

        <form v-if="isActualTeacher" novalidate>
        <div class="card mb-2">
            <div class="card-header">
                <h4 style="margin: 0;">How I Teach</h4>
            </div>

            <div class="card-body">
                <div v-if="howTeachForm.errorMessage" class="row">
                    <div class="col-sm-12">
                        <div class="alert alert-danger" role="alert">
                            {{ howTeachForm.errorMessage}}
                        </div>
                    </div>
                </div>

                <div v-if="howTeachForm.successMessage" class="row">
                    <div class="col-sm-12">
                        <div class="alert alert-success" role="alert">
                            {{ howTeachForm.successMessage }}
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4">
                        <label for="how_i_teach">
                            I teach reading using:
                        </label>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                        <select
                            class="form-control form-select"
                            id="how_i_teach"
                            name="how_i_teach"
                            v-model="v$.howTeach.how_i_teach.$model"
                        >
                            <option value="">Select</option>
                            <option
                                v-for="how in howTeachItems"
                                :value="how.value"
                                :key="how.value"
                            >{{ how.label }}
                            </option>
                        </select>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-sm-4">
                        <label for="device_number">
                            Number of devices in my classroom
                        </label>
                    </div>
                    <div class="col-sm-8">
                        <div class="form-group">
                        <select
                            class="form-control"
                            id="device_number"
                            name="device_number"
                            v-model="v$.howTeach.device_number.$model"
                        >
                            <option value="">Select</option>
                            <option
                                v-for="(quantity, index) in numberOfDevices"
                                :value="quantity"
                                :key="index"
                            >{{ quantity }}
                            </option>
                        </select>
                        </div>
                    </div>

                    <div class="card-footer text-right" v-if="v$.howTeach.$anyDirty">
                        <button type="submit" name="btn_how_i_teach" class="btn btn-success btn-md" :disabled="v$.howTeach.$invalid" @click.prevent="updateHowTeach()">
                            <span v-if="howTeachForm.saving">Saving...</span>
                            <span v-else>Save</span>
                        </button>
                    </div>

                </div>


            </div>
        </div>
        </form>

        <form v-if="false && isActualTeacher" name="frm3" novalidate>
          <!-- LL2L-401 removed this.  Probably for good, but leaving it just hidden for now -->
			<div class="card mb-2">
				<div class="card-header">
					<h4 style="margin: 0;">Enter Free Month Subscription Promo Code</h4>
				</div>

				<div class="card-body">
					<div v-if="promoForm.errorMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-danger" role="alert">
								{{ promoForm.errorMessage }}
							</div>
						</div>
					</div>

					<div v-if="promoForm.successMessage" class="row">
						<div class="col-sm-12">
							<div class="alert alert-success" role="alert">
								{{ promoForm.successMessage }}
							</div>
						</div>
					</div>

					<div class="form-group">
						<input type="text" class="form-control" name="free_month_subscription_promo" v-model="v$.promo.code.$model"/>
					</div>
				</div>

				<div class="card-footer text-right" v-if="v$.promo.$anyDirty" >
					<button name="btn_free_month_subscr_promo" type="submit" class="btn btn-success btn-md"
								:disabled="promoForm.saving || v$.promo.code.$invalid"
								@click.prevent="submitPromo">
						<span v-if="promoForm.saving">Validating...</span>
						<span v-else>Save</span>
					</button>
				</div>
			</div>
		</form>
	</div>

	<b-modal v-model="showCancelDialog" title="Welcome" ref="modal" hide-header>
		<form>
			<div v-if="cancelForm.errorMessage" class="row">
				<div class="col-sm-12">
					<div class="alert alert-danger" role="alert">
						{{ cancelForm.errorMessage}}
					</div>
				</div>
			</div>

			<div v-if="cancelForm.successMessage" class="row">
				<div class="col-sm-12">
					<div class="alert alert-success" role="alert">
						{{ cancelForm.successMessage }}
					</div>
				</div>
			</div>

			<h3>I am canceling because (choose any that apply):</h3>
			<div class="row">
				<div class="col-sm-8">
					<div class="checkbox">
						<label>
								<input value="1" type="checkbox" v-model="v$.cancel.reason_codes.$model" />

								1. I chose not to use L2L with my student/s.
							</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-8">
					<div class="checkbox">
						<label>
							<input value="2" type="checkbox" v-model="v$.cancel.reason_codes.$model" />
								2. L2L did not work on my tablet or computer
						</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-8">
					<div class="checkbox">
						<label>
							<input value="3" type="checkbox" v-model="v$.cancel.reason_codes.$model" />
								3. I did not get school funding for L2L
						</label>
					</div>
				</div>
			</div>

			<div class="row">
				<div class="col-sm-8">Other
					<input v-model="v$.cancel.reason.$model" type="text" class="form-control">
				</div>
			</div>
		</form>

		<div slot="modal-footer">
			<button class="btn btn-danger" @click.prevent="submitCancel()" :disabled="cancelForm.saving || v$.cancel.$invalid">
					<span v-if="cancelForm.saving">Cancelling subscription...</span>
					<span v-else>Cancel My Subscription</span>
			</button>
			<button class="btn btn-default" @click.prevent="showCancelDialog = false">
				Cancel
			</button>
		</div>
	</b-modal>

	<b-modal v-model="showPauseDialog" title="Pause Subscription" ref="modal" hide-header>
		<form>
			<h3>Pause Subscription</h3>
			<div v-if="pauseForm.errorMessage" class="row">
				<div class="col-sm-12">
					<div class="alert alert-danger" role="alert">
						{{ pauseForm.errorMessage}}
					</div>
				</div>
			</div>

			<div v-if="pauseForm.successMessage" class="row">
				<div class="col-sm-12">
					<div class="alert alert-success" role="alert">
						{{ pauseForm.successMessage }}
					</div>
				</div>
			</div>

			<p>
				<strong>NOTE: </strong>
				This feature will pause billing on your account until the date you specify.
				Our billing system works on a 30 day cycle.  Please do not use this feature to pause subscriptions
				over the weekend or during times when you will be inactive for less than a 30 day billing cycle.
				Otherwise, it could result in your account being double billed.
			</p>
			<div class="row">
				<div class="col-sm-12">
					<div class="row">
						<div class="col-sm-6">
							Pause subscription until this date:
						</div>
						<div class="col-sm-4">
							<input v-model="v$.pause.until.$model"
								class="form-control" size="10" type="text">
							<small>&nbsp;&nbsp;(yyyy-mm-dd)</small>
						</div>
					</div>
				</div>
			</div>
		</form>

		<div slot="modal-footer">
			<button class="btn btn-default" @click.prevent="showPauseDialog = false">
				Cancel
			</button>
			<button class="btn btn-danger" @click.prevent="submitPause()" :disabled="pauseForm.saving || v$.pause.$invalid">
					<span v-if="pauseForm.saving">Pausing subscription...</span>
					<span v-else>Pause My Subscription</span>
			</button>
		</div>
	</b-modal>


	<b-modal v-model="showResumeDialog" title="Welcome" ref="modal" hide-header>
		<form>
			<div v-if="resumeForm.errorMessage" class="row">
				<div class="col-sm-12">
					<div class="alert alert-danger" role="alert">
						{{ resumeForm.errorMessage}}
					</div>
				</div>
			</div>

			<div v-if="resumeForm.successMessage" class="row">
				<div class="col-sm-12">
					<div class="alert alert-success" role="alert">
						{{ resumeForm.successMessage }}
					</div>
				</div>
			</div>

			<h3>Resume Your Subscription</h3>
			<p>By clicking on Resume below, you will re-instate your subscription.</p>
		</form>

		<div slot="modal-footer">
			<button class="btn btn-default" @click.prevent="showResumeDialog = false">
				Cancel
			</button>
			<button class="btn btn-primary" @click.prevent="submitResume()" :disabled="resumeForm.saving">
					<span v-if="resumeForm.saving">Resuming subscription...</span>
					<span v-else>Resume My Subscription</span>
			</button>
		</div>
	</b-modal>
</div>

</template>

<script>
  import {email, maxLength, minLength, required} from '@vuelidate/validators'
  import Vue from 'vue';
  import VueStripeCheckout from 'vue-stripe-checkout';
  import moment from 'moment';
  import useVuelidate from "@vuelidate/core"
  import {dateToYMD} from "@/common/utils/index.js"

  // non real key.
	Vue.use(VueStripeCheckout, 'pk_test_O4233q7Kji8tPVNd2qna4MIO');

	export default {
    setup() {
      return {
        v$: useVuelidate()
      }
    },

			mounted() {
				this.loadSettings();
			},
			// components: {
			//   'vue-stripe-checkout': VueStripeCheckout
			// },
			data(){
				return {
					loading: true,
          fluencyRecordingPossible: false,
					subscriptionInformationLoading: true,
					successMessage: '',
					email: '', // for stripe
					publishableKey: '',
					hrefs: {
						permission_slip: '/teacher/file/download/student-fluency-permission-slip.docx',
						stripe_pay: '/subscribe'
					},
					isActualTeacher: false,
					studentForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					loginForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					infoForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					promoForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					subscriptionForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					cancelForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					resumeForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},
					pauseForm: {
						errorMessage: '',
						successMessage: '',
						saving: false
					},

                  howTeachForm: {
                    errorMessage: '',
                    successMessage: '',
                    saving: false
                  },

                  // "howTeach" may be caveman-speak, but it's less confusible with how_i_teach
                  howTeach: {
                    how_i_teach: '',
                    device_number: '',
                  },

					showCancelDialog: false,
					showPauseDialog: false,
					showResumeDialog: false,
					promo: {
						code: ''
					},
					login: {
						username: '',
						password: '',
					},
					info: {
						firstname: '',
						lastname: '',
						email: '',
						mailaddress1: '',
						mailcity: '',
						mailstate: '',
						mailzip: ''
					},
					subscription: {
						is_paid_by_school: false,
						is_in_free_trial: false,
						is_paused: false,
						is_cancelled: false,
						status: '',
						type: '',
						valid_until_timestamp_utc: '',
						valid_until_pretty: '2 years from now',
						valid_until: '',
						is_paused_until: '',
						is_legacy_stripe: false,
						card_details: '',
						subscription_channel: ''
					},
					student: {
						send_weekly_report: false, // true, false
						retake_exm: false, // retake exam
						unlink_video: false, // Require my students to complete their fluency
						slow_video: false,
						student_username: false,
						complete_lessons_extensions: false,
						record_student_fluency: false,
						student_fluency_have_parent_permission: false,
						student_fluency_have_admin_approval: false,
						student_record_fluency_lyrics_only: false,
						welcome_message: "hello, class"
					},
					cancel: {
						reason_codes: [],
						reason: ''
					},
					pause: {
						until: ''
					},

                  numberOfDevices: [
                    "1-3 devices",
                    "4 or more devices",
                  ],
                  howTeachItems: [
                    {
                      value: 'teach reading centers',
                      label: 'I often use centers and literacy stations when I teach reading.'
                    },
                    {
                      value: 'teacher whole group',
                      label: 'Most of my reading lessons are done as a class (whole group instruction).'
                    }
                  ],

                  ALLDATA: null
                };
			},
			computed: {
        endpoint() {
          return `/teacher/${this.teacherId}/settings`
        },
				subscriptionType: function() {
					var type = "Unknown";
					if(this.subscription.is_paid_by_school) {
						type = "Paid for by School";
					} else if(this.subscription.is_in_free_trial) {
						type = "Free Trial";
					} else if(this.subscription.is_paused) {
						type = "Paused";
					} else if(this.subscription.is_cancelled || this.subscription.status=='canceled') {
						type = "Cancelled";
					} else {
						type = this.subscription.type;
					}
					return type;
				},
				subscriptionExpirationDateLabel: function() {
					var label = null;
					if(this.subscription.is_paid_by_school) {
						label = "Subscription expiration date";
					} else if(this.subscription.is_in_free_trial) {
						label = "Your free trial expiration date";
					} else if(this.subscription.is_paused) {
						if(this.subscription.valid_until_timestamp_utc > (Date.now()/1000)) {
							label = "Status"; // don't show anythng, because we have a sentence next to us.
						} else {
							label = "Paused Until";
						}
					} else if(this.subscription.is_cancelled || this.subscription.status=='canceled') {
						if(this.subscription.valid_until_pretty.length) {
							label = "Subscription Expires";
						} else {
							// expired.  don't show anything.
							label = null;
						}
					} else {
						label = "Your subscription renewal date";
					}
					return label;
				},
				subscriptionExpirationDate: function() {
					var dateStr = null;
					var formattedDate;

					if(this.subscription.is_paid_by_school) {
						formattedDate = dateToYMD(this.subscription.valid_until);
						dateStr = `${formattedDate} (${this.subscription.valid_until_pretty})`;
					} else if(this.subscription.is_in_free_trial) {
						formattedDate = dateToYMD(this.subscription.valid_until);
						dateStr = `${formattedDate} (${this.subscription.valid_until_pretty})`;
					} else if(this.subscription.is_paused) {
						formattedDate = dateToYMD(this.subscription.valid_until);
						var formattedResume = dateToYMD(this.subscription.is_paused_until);
						if(this.subscription.valid_until_timestamp_utc > (Date.now()/1000)) {
							dateStr = `Active until ${formattedDate}, then paused until ${formattedResume}`;
						} else {
							dateStr = `${formattedResume}`;
						}
					} else if(this.subscription.is_cancelled || this.subscription.status=='canceled') {
						dateStr = this.subscription.valid_until_pretty;
					} else {
						var formattedDate = dateToYMD(this.subscription.valid_until);
						dateStr = `${formattedDate} (${this.subscription.valid_until_pretty})`;
					}
					return dateStr;
				},
				subscriptionCardDetails: function() {
					var cardDetails = null;
					if(this.subscription.is_paid_by_school) {
					} else if(this.subscription.is_in_free_trial) {
					} else if(this.subscription.is_cancelled || this.subscription.status=='canceled') {
					} else if(this.subscription.is_paused) {
					} else {
						cardDetails = this.subscription.card_details;
					}

					return cardDetails;
				},
				subscriptionButtons: function() {
					var buttons = [];

					if(this.subscription.subscription_channel=='stripe')
					{
						if(this.subscription.is_paid_by_school) {
							// doubt this works, but let's try it.
						} else if(this.subscription.is_in_free_trial) {
							buttons = ['subscribe'];
						} else if(this.subscription.is_paused) {
							buttons = ['resume', 'cancel' ];
						} else if(this.subscription.is_cancelled || this.subscription.status=='canceled') {
							buttons = ['subscribe'];
						} else {
							if(this.subscription.type == 'Yearly') {
								buttons = ['update','cancel'];
							} else {
								buttons = ['update','cancel','pause'];
							}
						}
					} else if(this.subscription.subscription_channel == 'google') {
						buttons = ['google-disclosure'];
					} else if(this.subscription.subscription_channel == 'apple') {
						buttons = ['apple-disclosure'];
					}

					console.log("Buttons", buttons);
					return buttons;
				}
			},
			props: {
				apiKey: {
					type: String,
					required: true
				},
        teacherId: {
          type: Number,
          required: true,
        }
			},
			methods: {
				loadSettings() {
					this.loading = true;
					axios.post(this.endpoint, { verb: 'load' })
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.login.username = data.username;
							this.login.password = data.password;

							this.info.firstname = data.firstname;
							this.info.lastname = data.lastname;
							this.info.email = data.email;
							this.info.mailaddress1 = data.mailaddress1;
							this.info.mailcity = data.mailcity;
							this.info.mailstate = data.mailstate;
							this.info.mailzip = data.mailzip;

							this.isActualTeacher = data._is_actual_teacher;

							this.publishableKey = data.publishableKey;

							this.student = data.student;

							this.subscription = data.subscription;

							this.howTeach = data.howTeach;

							this.fluencyRecordingPossible = data.fluencyRecordingPossible;

							this.ALLDATA = data
						}
					}).catch((error) => {
						try {
							if(error.response.data.errors.login[0]) {
								this.showError("Invalid username or password.  Please try again");
							}
						} catch {
							this.showError(error.toString());
						}
					}).finally(() => {
						this.loading = false;
						this.subscriptionInformationLoading = false;
					})
				},
				updateLogin() {
					this.loginForm.saving = true;
					this.loginForm.successMessage = '';
					this.loginForm.errorMessage = '';

					var p = {
						verb: 'update_login'
					};
					for(var k in this.login) {
						p[k] = this.login[k];
					}

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.login.password = data.password;
							this.loginForm.successMessage = data.message;
							this.v$.login.$reset();
						} else {
							this.loginForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.loginForm.errorMessage = error.toString()
					}).finally(() => {
						this.loginForm.saving = false;
					})
				},

              // My favorite flavor of pasta is "copy" ಠ︿ಠ
              updateHowTeach() {
                this.howTeachForm.saving = true
                this.howTeachForm.successMessage = ''
                this.howTeachForm.saving = ''

                var p = {
                  verb: 'update_howteach'
                };
                for(var k in this.howTeach) {
                  p[k] = this.howTeach[k];
                }

                axios.post(this.endpoint, p)
                  .then((response) => response.data)
                  .then((data) => {
                    if(data.success) {
                      this.howTeach.how_i_teach = data.how_i_teach
                      this.howTeach.device_number = data.device_number
                      this.howTeachForm.successMessage = data.message;
                      this.v$.howTeach.$reset();
                    } else {
                      this.howTeachForm.errorMessage = data.message;
                    }
                  }).catch((error) => {
                  this.howTeachForm.errorMessage = error.toString()
                }).finally(() => {
                  this.loginForm.saving = false;
                })

              },


              updateStudent() {
                // we need a reusable solution to this...
                if (this.v$.student.welcome_message.$dirty && !this.v$.student.welcome_message.$model.trim()) {
                  return this.clearWelcomeMessage()
                }

                this.studentForm.saving = true;
					this.studentForm.successMessage = '';
					this.studentForm.errorMessage = '';

					var p = {
						verb: 'update_params'
					};
					for(var k in this.student) {
						p[k] = this.student[k];
					}

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							// this.login.password = data.password;
							// this.loginForm.successMessage = data.message;

							// TODO: move this all into one object and use ObjectEditor
							this.student.send_weekly_report = data.send_weekly_report;
							this.student.retake_exm = data.retake_exm;
							this.student.unlink_video = data.unlink_video;
							this.student.slow_video = data.slow_video;
							this.student.student_username = data.student_username;
							this.student.complete_lessons_extensions = data.complete_lessons_extensions;
							this.student.record_student_fluency = data.record_student_fluency;
							this.student.student_fluency_have_parent_permission = data.student_fluency_have_parent_permission;
							this.student.student_fluency_have_admin_approval = data.student_fluency_have_admin_approval;
							this.student.student_record_fluency_lyrics_only = data.student_record_fluency_lyrics_only;
							this.student.welcome_message = data.welcome_message;
							this.studentForm.successMessage = data.message;

							if(data.reload_nav) {
								// reload the navigation bar.
								window.location.reload(); // FIXME: don't reload the page until the message has been seen, hide the buttons.
							}

							this.v$.student.$reset();
						} else {
							this.studentForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.studentForm.errorMessage = error.toString()
					}).finally(() => {
						this.studentForm.saving = false;
					})
				},



				updateInfo() {
					this.infoForm.saving = true;
					this.infoForm.successMessage = '';
					this.infoForm.errorMessage = '';

					var p = {
						verb: 'update_info'
					};
					for(var k in this.info) {
						p[k] = this.info[k];
					}

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
//this.login.password = data.password;
							this.infoForm.successMessage = data.message;
							this.v$.info.$reset();
						} else {
							this.infoForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.infoForm.errorMessage = error.toString()
					}).finally(() => {
						this.infoForm.saving = false;
					})
				},

        clearWelcomeMessage() {
          this.v$.student.welcome_message.$model = ''

          this.studentForm.saving = true;
          this.studentForm.successMessage = '';
          this.studentForm.errorMessage = '';

          axios.post(this.endpoint, {verb: 'clear_welcome_message'})
            .then((response) => response.data)
            .then((data) => {
              if(data.success) {
                this.student.welcome_message = '';
              } else {
                this.studentForm.errorMessage = data.message;
              }
            }).catch((error) => {
            this.studentForm.errorMessage = error.toString()
          }).finally(() => {
            this.studentForm.saving = false;
            this.v$.student.$reset()
          })
        },

				submitPromo() {
					this.promoForm.saving = true;
					this.promoForm.successMessage = '';
					this.promoForm.errorMessage = '';

					var p = {
						verb: 'update_promo'
					};
					for(var k in this.promo) {
						p[k] = this.promo[k];
					}

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.promo.code = '';
							this.promoForm.successMessage = data.message;
							this.v$.promo.$reset();
						} else {
							this.promoForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.promoForm.errorMessage = error.toString()
					}).finally(() => {
						this.promoForm.saving = false;
					})
				},


				async cardUpdate () {
					// token - is the token object
					// args - is an object containing the billing and shipping address if enabled
					const { token, args } = await this.$refs.checkoutRef.open();
				},
				cardDone ({token, args}) {
					// token - is the token object
					// args - is an object containing the billing and shipping address if enabled
					// do stuff...

					var p = {
						verb: 'update_card',
						token: token.id
					};

					this.subscriptionForm.errorMessage = '';
					this.subscriptionForm.successMessage = '';
					this.subscriptionForm.saving = true;
					this.subscriptionInformationLoading = true;

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.subscriptionForm.successMessage = data.message;
							this.subscription = data.subscription;
						} else {
							this.subscriptionForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.subscriptionForm.errorMessage = error.toString()
					}).finally(() => {
						this.subscriptionForm.saving = false;
            this.subscriptionInformationLoading = false;
					});
				},
				cardCancelled() {
				},

				resumeSubscription() {
					this.showResumeDialog = true;
				},
				cancelSubscription() {
					this.showCancelDialog = true;
				},
				pauseSubscription() {
					this.showPauseDialog = true;
				},
				submitPause() {
					var p = {
						verb: 'pause_subscription',
						until: this.pause.until
					};
					this.pauseForm.errorMessage = '';
					this.pauseForm.successMessage = '';
					this.pauseForm.saving = true;

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.pauseForm.successMessage = data.message;
							this.showPauseDialog = false;

							// now reload the page (header and settings has changed)
							window.location.reload(); // FIXME: don't reload the page until the message has been seen, hide the buttons.
						} else {
							this.pauseForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.pauseForm.errorMessage = error.toString()
					}).finally(() => {
						this.pauseForm.saving = false;
					});
				},
				submitResume() {
					var p = {
						verb: 'resume_subscription',
					};
					this.resumeForm.errorMessage = '';
					this.resumeForm.successMessage = '';
					this.resumeForm.saving = true;

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.resumeForm.successMessage = data.message;
							this.showResumeDialog = false;

							// now reload the page (header and settings has changed)
							window.location.reload(); // FIXME: don't reload the page until the message has been seen, hide the buttons.
						} else {
							this.resumeForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.resumeForm.errorMessage = error.toString()
					}).finally(() => {
						this.resumeForm.saving = false;
					});
				},
				submitCancel() {
					var p = {
						verb: 'cancel_subscription',
						reason_codes: this.cancel.reason_codes,
						reason: this.cancel.reason
					};

					this.cancelForm.errorMessage = '';
					this.cancelForm.successMessage = '';
					this.cancelForm.saving = true;

					axios.post(this.endpoint, p)
					.then((response) => response.data)
					.then((data) => {
						if(data.success) {
							this.cancelForm.successMessage = data.message;
							this.showCancelDialog = false;

							// now reload the page (header and settings has changed)
							window.location.reload(); // FIXME: don't reload the page until the message has been seen, hide the buttons.
						} else {
							this.cancelForm.errorMessage = data.message;
						}
					}).catch((error) => {
						this.cancelForm.errorMessage = error.toString()
					}).finally(() => {
						this.cancelForm.saving = false;
					});
				},
      },
			validations: {
				login: {
					username: {
						required,
						minLength: minLength(4),
						maxLength: maxLength(20),
					},
					password: {
						required,
						minLength: minLength(6),
						maxLength: maxLength(32)
					}
				},
				student: {
					// done this way so $anyDirty will work, but not have any required validation.
					send_weekly_report: {},
					retake_exm: {},
					unlink_video: {},
					slow_video: {},
					student_username: {},
					complete_lessons_extensions: {},
					record_student_fluency: {},
					student_fluency_have_parent_permission: {},
					student_fluency_have_admin_approval: {},
					student_record_fluency_lyrics_only: {},
					welcome_message: {}
				},

              howTeach: {
				  // same deal as student above
                how_i_teach: {},
                device_number: {},
              },

				promo: {
					code: {
						required,
						minLength: minLength(2),
						maxLength: maxLength(26)
					}
				},
				cancel: {
					reason_codes: {
					},
					reason: {}
				},
				pause: {
					until: {
						required,
						minLength: minLength(8),
						maxLength: maxLength(10)

					}
				},
				info: {
					firstname: {
						required,
					},
					lastname: {
						required,
					},
					email: {
						required,
						email
					},
					mailaddress1:{
						// done this way so $anyDirty will work, but not have any required validation.
					},
					mailcity: {

					},
					mailstate: {

					},
					mailzip: {
					}
				}
			}
		}
</script>
