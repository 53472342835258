<script>

  // This is basically a copy of SignupForm, stripped of unused pieces (like the social signin buttons) and with
  // the code cleaned up a bit. Merging this and SignupForm back together at some point would be a good idea.

  import useVuelidate from "@vuelidate/core"
  import {required, maxLength, minLength, email, requiredIf, requiredUnless, decimal} from "@vuelidate/validators"

  export default {
    setup() {
      return {
        v$: useVuelidate(),
      }
    },

    props: {

      initialUserType: {
        type: String,
        required: true,
      },

      initialFirstName: {
        type: String,
        required: true,
      },

      initialLastName: {
        type: String,
        required: true,
      },

      initialGradeLevel: {
        type: String,
        required: true,
      },

      initialSource: {
        type: String,
        required: true,
      },

      initialSchoolId: {
        type: String,
        required: true,
      }

    },

    data() {
      return {
        userType: this.initialUserType,
        firstName: this.initialFirstName,
        lastName: this.initialLastName,
        gradeLevel: this.initialGradeLevel,
        source: this.initialSource,

        schoolZipCode: '',
        selectedSchool: '',

        schoolsLoaded: false,
        loadingSchools: false,
        availableSchools: [],

        errorMessage: '',
        submitting: false,

        foreign: false,

        gradeLevels: [
          "Kindergarten",
          "1st Grade",
          "2nd grade",
          "3rd grade",
          "4th grade",
          "5th grade",
          "6th grade or higher",
          "Multiple grade levels",
        ],

        referralTypes: [
          "Pinterest",
          "A Friend Referred Me",
          "Search Engine",
          "Facebook",
          "Twitter",
          "Instagram",
          "Other",
          "Unknown"
        ],
      };
    },

    computed: {
      enableuserType() {
        return !this.initialUserType
      },

      enableSource() {
        return !this.initialSource || this.initialSource === 'Unknown'
      },

      enableSchool() {
        return this.userType === 'teacher' && !this.initialSchoolId
      },
    },

    methods: {
      onSubmit() {
        const payload = {
          userType: this.userType,
          firstName: this.firstName,
          lastName: this.lastName,
          gradeLevel: this.gradeLevel,
          source: this.source,
          selectedSchool: this.selectedSchool,
        };

        this.submitting = true;
        axios.post('/teacher/complete_signup_postback', payload)
          .then((response) => response.data)
          .then((data) => {
            if (data.success) {
              window.location = data.redirect;
            } else {
              // show the error. // FIXME
              this.errorMessage = data.message;
            }
          }).catch((error) => {
          try {
            if (error.response.data.errors) {
              var msg = '';
              for (var k in error.response.data.errors) {
                msg += error.response.data.errors[k][0];
              }
              this.errorMessage = msg;
            }
          } catch {
            this.errorMessage = error.toString();
          }
        }).finally(() => {
          this.submitting = false;
        });
      },

      isNumber(event) {
        var evt      = event || window.event;
        var charCode = evt.which || evt.keyCode;
        if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
          evt.preventDefault();
        } else {
          return true;
        }
      },

      onZipChange() {
        if (this.schoolZipCode.length == 5) {
          this.lookupByZip(this.schoolZipCode)
        }
      },

      async lookupByZip(zip) {
        this.loadingSchools   = true;
        const response        = await fetch(`/support/schoolsByZip/${zip}`)
        this.availableSchools = await response.json();
        this.loadingSchools   = false;
        this.schoolsLoaded    = true;
      }
    },

    validations() {
      const validations = {
        userType: {required},
        firstName: {required, minLength: minLength(2), maxLength: maxLength(45)},
        lastName: {required, minLength: minLength(1), maxLength: maxLength(45)},
        gradeLevel: {required},
        source: {required},
      }

      if (this.enableSchool && !this.foreign) {
        validations.selectedSchool = {required}
      }

      return validations
    },

  }
</script>

<template>
    <div>

        <form method="post" name="frmSignUp" class="signup-form" @submit.prevent="onSubmit">

            <div class="alert alert-danger" v-if="errorMessage">
                <b>Error: </b>{{ errorMessage }}
            </div>

            <div v-if="enableuserType" class="form-group row" :class="{ 'has-error': v$.userType.$error }">
                <div class="col-md-4 control-label">
                    <label for="userType">User Type:</label>
                </div>
                <div class="col-md-8">
                    <select id="userType" name="userType" v-model="v$.userType.$model" class="form-control" required>
                        <option value="">--Select user type--</option>
                        <option value="teacher">Teacher</option>
                        <option value="parent">Parent</option>
                    </select>
                    <div class="error" v-if="!v$.userType.required">Field is required</div>
                </div>
            </div>

            <div class="form-group row" :class="{ 'has-error': v$.firstName.$error }">
                <div class="col-md-4 control-label">
                    <label for="firstName">First Name:</label>
                </div>
                <div class="col-md-8">
                    <input type="text" class="form-control" name="firstName" v-model.trim="v$.firstName.$model"/>
                    <div class="error" v-if="!v$.firstName.required">Field is required</div>
                    <div class="error" v-if="!v$.firstName.minLength">Field must have at least
                        {{v$.firstName.$params.minLength.min}} letters.
                    </div>
                </div>

            </div>

            <div class="form-group row" :class="{ 'has-error': v$.lastName.$error }">
                <div class="col-md-4 control-label">
                    <label for="lastname">Last Name:</label>
                </div>
                <div class="col-md-8">
                    <input type="text" class="form-control" name="lastName" v-model.trim="v$.lastName.$model"/>
                    <div class="error" v-if="!v$.lastName.required">Field is required</div>
                    <div class="error" v-if="!v$.lastName.minLength">Field must have at least
                        {{v$.lastName.$params.minLength.min}} letters.
                    </div>
                </div>
            </div>

            <div v-if="enableSchool">
                <div class="form-group row">
                    <div class="col-md-4 control-label">
                        <label for="txt_password">School Zip Code:</label>
                    </div>

                    <div class="col-md-8">
                      <div>
                        <input id="cb-non-us" type="checkbox" v-model="foreign">
                        <label for="cb-non-us" style="font-weight: normal; font-style: italic;">
                            I am not in the United States
                        </label>
                      </div>

                      <div v-if="!foreign">
                        <input
                            type="text"
                            class="form-control"
                            @input="onZipChange"
                            v-model="schoolZipCode"
                            maxlength="5"
                            @keypress="isNumber($event)"
                        />
                        <p v-show="loadingSchools"><strong>Loading Schools...</strong></p>
                        <p><strong>Note:</strong> After entering the school zip
                            code, please select your school.</p>
                        </div>
                    </div>
                </div>
                <div v-if="!foreign && schoolsLoaded" class="form-group row" :class="{ 'has-error': v$.selectedSchool.$error }">
                    <div class="col-md-4 control-label">
                        <label for="school">Choose Your School:</label>
                    </div>
                    <div class="col-md-8">
                        <select id="school" name="school" class="form-control" v-model="v$.selectedSchool.$model">
                            <option value="">Choose Your School</option>
                            <option v-for="school in availableSchools" :key="school.id" :value="school.id">
                              {{school.name }}
                            </option>
                            <option value="1">My School Is Not Listed</option>
                        </select>
                        <div class="error" v-if="!v$.selectedSchool.required">Field is required</div>
                    </div>
                </div>

            </div>

            <div class="form-group row" :class="{ 'has-error': v$.gradeLevel.$error }">
                <div class="col-md-4 control-label">
                    <label for="inf_custom_GradeLevelofStudents">Grade Level of Students</label>
                </div>
                <div class="col-md-8">
                    <select class="select_new form-control" v-model="v$.gradeLevel.$model">
                        <option value="">--Select grade level--</option>
                        <option v-for="(grade, index) in gradeLevels" :value="grade" :key="index">{{ grade }}</option>
                    </select>
                    <p>
                        <strong>Note:</strong>
                        This question will not limit access to content.
                    </p>
                </div>
            </div>

            <div v-if="enableSource" class="form-group row" :class="{ 'has-error': v$.source.$error }">
                <div class="col-md-4 control-label">
                    <label for="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn">How
                        Did You Find Out About Lyrics2Learn?</label>
                </div>
                <div class="col-md-8">
                    <select
                        class="select_new form-control"
                        id="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
                        name="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn" v-model="v$.source.$model"
                    >
                        <option value="">--Select a choice--</option>
                        <option v-for="(how, index) in referralTypes" :value="how" :key="index">{{ how }}</option>
                    </select>
                </div>
            </div>

            <div class="alert alert-danger" v-if="errorMessage">
                <b>Error: </b>{{ errorMessage }}
            </div>

            <div class="form-group row">
                <div class="col-xs-12">
                    <button type="submit" id="btnSubmit" class="button_submit" :disabled="v$.$invalid && !submitting">
                        <span v-if="submitting">Submitting..</span>
                        <span v-else>Complete Your Signup</span>
                    </button>
                </div>
            </div>
        </form>
    </div>
</template>

<style lang="scss" scoped>
    @keyframes shakeError {
        0% {
            transform: translateX(0);
        }
        15% {
            transform: translateX(0.375rem);
        }
        30% {
            transform: translateX(-0.375rem);
        }
        45% {
            transform: translateX(0.375rem);
        }
        60% {
            transform: translateX(-0.375rem);
        }
        75% {
            transform: translateX(0.375rem);
        }
        90% {
            transform: translateX(-0.375rem);
        }
        100% {
            transform: translateX(0);
        }
    }

    .signup-form {

        .form-group {
            .error {
                display: none;
            }

            &.has-error {
                animation-name: shakeError;
                animation-fill-mode: forward;
                animation-duration: .6s;
                animation-timing-function: ease-in-out;

                .error {
                    color: red;
                    display: block;
                }
            }
        }

        button[type=submit] {
            -webkit-appearance: button;
            cursor: pointer;
            width: 100%;
            height: 54px;
            color: white;
            font-weight: 700;
            font-size: 26px;
            border: none;
            border-radius: 5px;
            background: #c41717;
            background: -moz-linear-gradient(top, #c41717 0%, #b01415 100%);
            background: -webkit-linear-gradient(top, #c41717 0%, #b01415 100%);
            background: linear-gradient(to bottom, #c41717 0%, #b01415 100%);
            filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#c41717', endColorstr='#b01415', GradientType=0);

            &:disabled {
                opacity: 0.75;
                background: #aaa;
                cursor: default;
            }
        }

        @media screen and (max-width: 700px) and (min-width: 450px) {
            button[type=submit] {
                font-size: 20px;
            }
        }

        @media screen and (max-width: 449px) {
            button[type=submit] {
                font-size: 14px;
            }
        }
    }
</style>
