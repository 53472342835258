<script setup lang="ts">
import {computed, nextTick, ref} from "vue"
import {LessonRow, StudentRow} from "~components/teacher/assign/assignment"
import {useVModels} from "@vueuse/core"
import {countAndPluralize} from "@/common/utils/english"
import LessonTable from "~components/teacher/assign/LessonTable.vue"
import StudentTag from "~components/teacher/assign/StudentTag.vue"
import LessonTag from "~components/teacher/assign/LessonTag.vue"

const props = defineProps<{
  lessons: LessonRow[]
  selectedStudents: StudentRow[]
}>()

const emit = defineEmits(["submit", "back"])
const {selectedStudents} = useVModels(props, emit)

const assign = ref<LessonRow[]>([])
const remove = ref<LessonRow[]>([])

const showAssignButton = computed(() => assign.value.length || remove.value.length)

const assignButtonLabel = computed(() => {
  const parts = []
  if (assign.value.length) {
    parts.push(`Assign ${countAndPluralize(assign.value.length, "lesson")}`)
  }
  if (remove.value.length) {
    parts.push(`Remove ${countAndPluralize(remove.value.length, "lesson")}`)
  }
  return parts.join(", ")
})

function removeStudent(student: StudentRow) {
  selectedStudents.value = selectedStudents.value.filter(s => s.id !== student.id)
  nextTick(() => selectedStudents.value.length || emit("back"))
}

function clearAssign(lesson: LessonRow) {
  assign.value = assign.value.filter(l => l.id !== lesson.id)
}

function clearRemove(lesson: LessonRow) {
  remove.value = remove.value.filter(l => l.id !== lesson.id)
}

function onSubmit() {
  emit("submit", {students: selectedStudents.value, assign: assign.value, remove: remove.value})
}

function clearAllLessons() {
  assign.value = []
  remove.value = []
}

// const studentSummary = computed(() => selectedStudents.value.map(s => ({
//   name: s.name,
//   assigned_ids: s.assigned_lessons.map(l => l.id),
//   completed_ids: s.completed_lessons.map(l => l.id),
// })))

</script>

<template>
  <div class="assign-lessons-page">
    <section class="student-list">
      <header>You are assigning lessons to:</header>
      <ul class="tags">
        <li v-for="student in selectedStudents" :key="student.id">
          <StudentTag
            :student="student"
            show-grade-level
            class="cursor-pointer"
            @click="removeStudent(student)"
          />
        </li>
      </ul>
    </section>

    <section v-if="assign.length" class="lesson-list">
      <header>Assigning Lessons:</header>
      <LessonTag
        v-for="lesson in assign"
        :key="lesson.id"
        :lesson="lesson"
        class="cursor-pointer m-1"
        @click="clearAssign(lesson)"
      />
    </section>

    <section v-if="remove.length" class="lesson-list">
      <header>Removing Lessons:</header>
      <LessonTag
        v-for="lesson in remove"
        :key="lesson.id"
        :lesson="lesson"
        class="cursor-pointer"
        @click="clearRemove(lesson)"
      />
    </section>

    <section class="tips">
      Click any student or lesson above to remove it from the list
    </section>

    <section class="buttons">
      <b-button
        v-if="showAssignButton"
        variant="primary"
        style="width: 24em;"
        @click="onSubmit"
      >
        {{ assignButtonLabel }}
      </b-button>

      <b-button
        v-if="showAssignButton"
        variant="secondary"
        @click="clearAllLessons"
        class="ml-5"
      >
        Clear Lesson Selection
      </b-button>

      <b-button v-if="!showAssignButton" variant="outline-secondary" disabled style="width: 24em;">
        Select Lessons Below
      </b-button>
    </section>

    <section class="debug">
      <!--<pre>{{ {assign, remove} }}</pre>-->
    </section>

    <section class="table">
      <LessonTable :lessons="lessons" :students="selectedStudents" :assign.sync="assign" :remove.sync="remove" />
    </section>
  </div>
</template>

<style lang="scss" scoped>

.cursor-pointer {
  cursor: pointer;
}

section {
  margin-top: 1rem;

  &.buttons {
    text-align: center;
  }

  &.tips {
    font-size: small;
    font-style: italic;
    text-align: center;
  }
}

ul.tags {
  list-style-type: none;
  columns: 4;
}

@media(max-width: 768px) {
  ul.tags {
    columns: 1;
  }
}
</style>
