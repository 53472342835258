<template>
  <div class="report-criteria-selector">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <form :action="selector.targetUrl" method="GET" class="d-flex">

        <v2-datepicker-range
          v-model="selector.range"
          lang="en"
          format="MM/DD/YYYY"
          :picker-options="pickerOptions"
          @change="changed()"
        ></v2-datepicker-range>

        <select
          v-if="showTeachers"
          class="form-control"
          v-model="selector.selectedTeacherId"
          @change="changed()"
        >
          <option v-for="teacher in teachers" :value="teacher.id" :key="teacher.id">
            {{ teacher.name }}
          </option>
        </select>

        <select
          v-if="showStudents"
          class="form-control"
          v-model="selector.selectedStudentId"
          @change="changed()"
        >
          <option v-for="student in students" :value="student.id" :key="student.id">
            {{ student.name }}
          </option>
        </select>

        <div v-if="refreshMethod==='button' && dirty">
          <input class="btn btn-primary" type="submit" value="Update" />
        </div>
      </form>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.load()
  },
  data() {
    return {
      students_loaded: false,
      teachers_loaded: false,
      students: [],
      teachers: [],
      dirty: false,
      selector: {
        targetUrl: "",
        selectedStudentId: "",
        selectedTeacherId: this.teacherId,
        range: ["01/09/2018", "01/10/2018"],
      },
      pickerOptions: {},
    }
  },
  props: {
    url: {
      type: String,
      required: true,
    },
    adminId: {
      type: Number,
      required: false,
    },
    teacherId: {
      type: String,
      required: false,
      default: "overall",
    },
    schoolId: {
      type: String,
      required: false,  // maybe should be required, but i haven't looked at all the usages yet
      default: "",
    },
    studentId: {
      type: String,
      required: true,
    },
    startDate: {
      type: String,
      required: true,
      default: "2001-01-01",
    },
    endDate: {
      type: String,
      required: true,
      default: "2001-01-01",
    },
    refreshMethod: {
      type: String,
      required: false,
      default: "change", // change, emit, button
    },
    allTimeStartDate: {
      type: String,
      required: false,
      default: "2001-01-01",
    },
    showStudents: {
      type: Boolean,
      required: false,
      default: true,
    },

    // For school admins
    showTeachers: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  computed: {
    loading() {
      return ((this.showTeachers && !this.teachers_loaded) || (this.showStudents && !this.students_loaded))
    },
  },
  methods: {
    changed() {
      var changed = false
      const oldStart = this.dateFromString(this.startDate, true)
      const oldEnd = this.dateFromString(this.endDate, false)

      if (this.selector.selectedStudentId != this.studentId) {
        console.log(`Student changed from ${this.studentId} to ${this.selector.selectedStudentId}`)
        changed = true
      }
      if (this.selector.selectedTeacherId != this.teacherId) {
        console.log(`Teacher changed from ${this.teacherId} to ${this.selector.selectedTeacherId}`)
        changed = true
      }
      if (this.selector.range[0].getTime() != oldStart.getTime()) {
        console.log(`Start date changed from ${oldStart} to ${this.selector.range[0]}`)
        changed = true
      }
      if (this.selector.range[1].getTime() != oldEnd.getTime()) {
        console.log(`End date changed from ${oldEnd} to ${this.selector.range[1]}`)
        changed = true
      }

      this.selector.targetUrl = this.reformatUrl()

      if (changed) {
        switch (this.refreshMethod) {
          case "emit":
            // emit the changes.
            break
          case "button":
            // must click to update
            this.dirty = true
            break
          case "change":
            // do it!
            console.log(`Redirect to ${this.selector.targetUrl}`)
            window.location.href = this.selector.targetUrl
            break
        }
      } else {
        this.dirty = false
      }
    },
    buildPickerOptions() {
      const end = new Date()
      const lastWeekStart = new Date()
      lastWeekStart.setTime(lastWeekStart.getTime() - 3600 * 1000 * 24 * 7)
      const last30Start = new Date()
      last30Start.setTime(last30Start.getTime() - 3600 * 1000 * 24 * 30)

      const last90Start = new Date()
      last90Start.setTime(last90Start.getTime() - 3600 * 1000 * 24 * 90)

      const schoolYearStart = new Date()
      const year = end.getFullYear()
      const month = end.getMonth() + 1 // +1 to be calendar vs. 0 based.
      var start = new Date()

      schoolYearStart.setMonth(7 - 1)
      schoolYearStart.setDate(1)
      schoolYearStart.setHours(0, 0, 0, 0)

      if (month < 8) {
        schoolYearStart.setFullYear(year - 1)
      } else {
        schoolYearStart.setFullYear(year)
      }

      const allTimeStart = this.dateFromString(this.allTimeStartDate, true)

      this.pickerOptions = {
        shortcuts: [{
          text: "Last Week",
          onClick(picker) {
            picker.$emit("pick", [lastWeekStart, end])
          },
        }, {
          text: "Last 30 Days",
          onClick(picker) {
            picker.$emit("pick", [last30Start, end])
          },
        }, {
          text: "Last 90 Days",
          onClick(picker) {
            picker.$emit("pick", [last90Start, end])
          },
        }, {
          text: "School Year",
          onClick(picker) {
            picker.$emit("pick", [schoolYearStart, end])
          },
        }, {
          text: "All Time",
          onClick(picker) {
            picker.$emit("pick", [allTimeStart, end])
          },
        }],
      }
    },
    reformatUrl() {
      var url = this.url
      url = url.replace("ADMIN_ID", this.adminId)
      url = url.replace("SCHOOL_ID", this.schoolId)
      url = url.replace("STUDENT_ID", this.selector.selectedStudentId)
      url = url.replace("TEACHER_ID", this.selector.selectedTeacherId)
      url = url.replace("START_DATE", this.getDateAsString(this.selector.range[0]))
      url = url.replace("END_DATE", this.getDateAsString(this.selector.range[1]))
      return url
    },
    getDateAsString(d) {
      let year = d.getFullYear()
      var month = d.getMonth() + 1
      var day = d.getDate()

      if (month < 10) month = "0" + month
      if (day < 10) day = "0" + day

      return `${year}-${month}-${day}`
    },
    dateFromString(s, resetTime) {
      // gonna be in the YYYY-MM-DD format.
      var d = new Date()
      var parts = s.split("-")
      if (parts.length == 3) {
        d.setFullYear(parseInt(parts[0]))
        d.setMonth(parseInt(parts[1] - 1))
        d.setDate(parseInt(parts[2]))
        if (resetTime) {
          d.setHours(0, 0, 0, 0)
        }
      }

      return d
    },

    load() {
      // setup the start/end range.
      this.selector.range[0] = this.dateFromString(this.startDate, true)
      this.selector.range[1] = this.dateFromString(this.endDate, false)
      this.selector.selectedStudentId = this.studentId

      this.selector.targetUrl = this.reformatUrl()
      this.buildPickerOptions()

      if (this.showStudents) {
        this.loadStudents()
      }
      if (this.showTeachers) {
        this.loadTeachers()
      }
    },

    loadStudents() {
      this.students_loaded = false
      axios.get(`/teacher/reports/criteria/student_list/${this.selector.selectedTeacherId}`)
        .then((response) => response.data)
        .then((data) => {
          this.students = [{id: "overall", name: "All Students"}].concat(data)
        }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.students_loaded = true
      })
    },

    loadTeachers() {
      this.teachers_loaded = false

      var url = `/teacher/reports/criteria/admin_teachers/${this.adminId}`
      if (this.schoolId) {
        url += `?school_id=${this.schoolId}`
      }
      axios.get(url)
        .then((response) => response.data)
        .then((data) => {
          this.teachers = [{id: "overall", name: "All Teachers"}].concat(data)
        }).catch((error) => {
        console.error(error)
      }).finally(() => {
        this.teachers_loaded = true
      })
    },
  },
}
</script>

<style scoped lang="scss">
select {
  max-width: 15rem;
}
</style>
