<script>
import {EventBus} from "../event_bus"
import Time from "./widgets/Time.vue"
import {currentTimeZoneName} from "../common/utils"

export default {
  components: {Time},

  props: {
    studentId: {type: String, required: true},
    type: {type: String, required: true},
  },

  data() {
    const columns = [
      {label: "Lesson", field: "lesson_title"},
      {label: "Most Recent Activity", field: "most_recent_date"},
      {label: "Day 1 Exam", field: "day_1_date"},
      {label: "Day 1 Score", field: "day_1_pct"},
      {label: "Day 2 Exam", field: "day_2_date"},
      {label: "Day 2 Score", field: "day_2_pct"},
      {label: "Day 3 Exam", field: "day_3_date"},
      {label: "Day 3 Score", field: "day_3_pct"},
      {label: "Overall Score", field: "overall_pct"},
      {label: "Extension", field: "extension_score"},
    ]

    return {
      rawData: [],
      loading: false,
      globalShowReset: false,
      resetting: false,
      filter: "",
      columns: columns,
      defaultSort: {
        by: columns.find(col => col.field === "most_recent_date"),
        direction: "desc",
      },
    }
  },

  computed: {
    title() {
      return {
        completed: "Completed Lesson Performance",
        assigned: "Assigned Lesson Performance",
        incompleted: "Incompleted Lesson Performance",
      }[this.type]
    },

    flattenedScores() {
      // returns a row structure that our basic datatable widget can search and sort
      return this.rawData.map(row => ({
        scores: {...row.scores},

        lesson_id: row.id,
        lesson_title: row.title,
        most_recent_date: (row.scores && row.scores.most_recent_date) || 0,  // null sorts before others
        day_1_date: _examDate(row, 1),
        day_1_pct: _examPct(row, 1),
        day_2_date: _examDate(row, 2),
        day_2_pct: _examPct(row, 2),
        day_3_date: _examDate(row, 3),
        day_3_pct: _examPct(row, 3),
        overall_pct: _overallPct(row),
        extension_score: row.scores && row.scores.extension && parseInt(row.scores.extension.points),
      }))
    },

    showReset() {
      return this.globalShowReset && !this.resetting
    },
  },

  mounted() {
    this.load()

    // because if we reset the scores on a complete lesson, it will change tables.
    // so this is how we handle that without having to do full reloads.
    EventBus.$on("lesson-score-table-reload", (evt) => {
      if (evt.requested_by !== this.type) {
        this.load()
      }
    })
  },

  methods: {
    async load() {
      try {
        this.loading = true
        await this._load()
      } finally {
        this.loading = false
      }
    },

    async _load() {
      const uri = `/teacher/reports/detailed/${this.studentId}/data`
      const response = await axios.post(uri, {type: this.type, verb: "view"})
      this.rawData = response.data
    },

    async reset(evt) {
      if (!confirm("Are you sure you want to reset scores for this lesson?")) return
      this.resetting = true
      try {
        await this._reset(evt)
      } finally {
        this.resetting = false
      }
    },

    async _reset(evt) {
      const params = {type: this.type, lesson_id: evt.resetData.id}

      if (evt.resetData.day === "overall") {
        params.verb = "reset_overall"
      } else {
        params.verb = "reset"
        params.day = evt.resetData.day
      }

      const uri = `/teacher/reports/detailed/${this.studentId}/data`
      const response = await axios.post(uri, params)
      this.rawData = response.data

      if (!this.rawData.some(row => row.id === params.lesson_id)) {
        // if we reset something in a completed lesson, it is now incomplete, so reload the tables
        EventBus.$emit("lesson-score-table-reload", {requested_by: this.type})
      }
    },

    renderDate(row, day) {
      return _examDate(row, day) || "-"
    },

    renderScore(row, day) {
      const pct = _examPct(row, day)
      if (pct === null) return "-"
      const scores = row.scores[day]
      return `${scores.correct}/${scores.solved} (${pct}%)`
    },

    currentTzName() {
      return currentTimeZoneName() || "UNKNOWN"
    },

    extension_url(lesson_id) {
      return `/teacher/reports/extension_answer?lesson_id=${lesson_id}&student_id=${this.studentId}`
    },
  },
}

function _examDate(row, day) {
  return row?.scores?.[day]?.date
}

function _examPct(row, day) {
  const {correct, solved} = row?.scores?.[day] ?? {}
  return solved ? Math.round(100 * correct / solved) : null
}

function _overallPct(row) {
  const scores = [1, 2, 3].map(day => _examPct(row, day)).filter(pct => pct !== null)
  return scores.length === 3 ? scores.reduce((a, b) => a + b, 0) / 3 : null // all days must be completed
}
</script>

<template>
  <div>
    <div v-if="loading">Loading...</div>
    <div v-if="!loading && flattenedScores.length">
      <h2>{{ title }}</h2>
      <div class="d-flex justify-content-between mb-1">
        <input placeholder="Search..." class="searchbar form-control non-print" v-model="filter">

        <small class="align-self-end">All times shown in the local timezone ({{ currentTzName() }})</small>

        <b-button
          v-if="!globalShowReset"
          variant="soft-danger"
          class="non-print"
          @click="globalShowReset = true"
        >
          Reset Scores...
        </b-button>
        <b-button
          v-else
          variant="soft-primary"
          class="non-print"
          @click="globalShowReset = false"
        >
          Hide Reset Buttons
        </b-button>

      </div>
      <div class="table table-striped table-responsive table-bordered">

        <datatable :columns="columns" :data="flattenedScores" :filter-by="filter" :default-sort="defaultSort">
          <template v-slot="{ row }">

            <tr>
              <td>{{ row.lesson_title }}</td>

              <td>
                <Time v-if="row.most_recent_date" :value="row.most_recent_date" />
                <span v-else> - </span>
              </td>

              <td>
                <Time :value="renderDate(row, 1)" />
              </td>

              <td>
                {{ renderScore(row, 1) }}
                <b-button
                  v-if="showReset && row.day_1_date"
                  variant="soft-danger"
                  class="p-1 non-print"
                  @click="reset({resetData: {id: row.lesson_id, day: 1}})"
                >
                  <i class="bi bi-trash"></i>
                </b-button>
              </td>

              <td>
                <Time :value="renderDate(row, 2)" />
              </td>

              <td>
                {{ renderScore(row, 2) }}
                <b-button
                  v-if="showReset && row.day_2_date"
                  variant="soft-danger"
                  class="p-1 non-print"
                  @click="reset({resetData: {id: row.lesson_id, day: 2}})"
                >
                  <i class="bi bi-trash"></i>
                </b-button>
              </td>

              <td>
                <Time :value="renderDate(row, 3)" />
              </td>

              <td>
                {{ renderScore(row, 3) }}
                <b-button
                  v-if="showReset && row.day_3_date"
                  variant="soft-danger"
                  class="p-1 non-print"
                  @click="reset({resetData: {id: row.lesson_id, day: 3}})"
                  :disabled="resetting"
                >
                  <i class="bi bi-trash"></i>
                </b-button>
              </td>

              <td>
                <div v-if="row.overall_pct">
                  {{ Math.round(row.overall_pct) }}%
                </div>
              </td>

              <td style="white-space: nowrap">
                <a v-if="row.extension_score" :href="extension_url(row.lesson_id)">
                  {{ row.extension_score }} (view)
                </a>
                <span v-else> - </span>
                <br>
                <b-button
                  v-if="showReset && typeof row.extension_score === 'number'"
                  variant="soft-danger"
                  class="p-1 mt-3 non-print"
                  @click="reset({resetData: {id: row.lesson_id, day: 4}})"
                >
                  <i class="bi bi-trash"></i>
                </b-button>
              </td>
            </tr>
          </template>
        </datatable>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
.searchbar {
  max-width: 10em;
  align-self: flex-end;
}
</style>
