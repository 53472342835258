<script>

import FluencyGradeButton from "~components/teacher/fluency/FluencyGradeButton.vue";

const MAX_ROWS = 10
const ENDPOINT = "/teacher/recordings/all"

export default {
  components: {FluencyGradeButton},

  props: {
    studentId: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      loading: false,
      rawData: null,
      errorMessage: undefined,
      columns: [
        {label: 'Created At', field: 'created_at'},
        {label: 'Lesson', field: 'lesson_title'},
        {label: 'Type', field: 'type'},
        {label: '', field: '', sortable: false},
      ],
    }
  },

  computed: {
    videos() {
      return this.rawData?.filter(video => video.student_id === this.studentId).slice(0, MAX_ROWS) ?? []
    },
  },

  methods: {
    load() {
      this.loading = true
      axios
        .get(ENDPOINT, {params: {student_id: this.studentId}})
        .then(response => this.rawData = response.data)
        .catch(error => {
          console.log(error)
          this.errorMessage = error
        })
        .finally(() => this.loading = false)
    },
    graded(_id) {
      window.location.reload()  // nothing else works to refresh the state
    },
    deleteRecording(row) {
      axios.delete(`/api/v2/recording_upload/${row.id}`)
        .then(() => {
          this.load()
        })
        .catch(error => {
          console.log(error)
          this.errorMessage = error
        })
    }
  },

  created() {
    this.load()
  },
}

/*
const sample_video = {
  "id": 14980,
  "student_id": 1197638,
  "firstname": "John",
  "lastname": "Bender",
  "lesson_title": "Early Reader Intro",
  "type": "Comparison",
  "created_at": "3 days ago",
  "graded_at": null,
  "transcode_status": "Complete",
}
*/

</script>

<template>
  <div>
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <div v-if="loading">Loading...</div>
    <div v-else>
      <div v-if="!videos.length" style="padding: 10px">
        No fluency videos are available at this time
      </div>
      <datatable
        v-else
        :columns="columns"
        :data="videos"
        #default="{ row }"
        class="table table-striped table-bordered"
      >
        <tr>
          <td>{{ row.created_at }}</td>
          <td>{{ row.lesson_title }}</td>
          <td>{{ row.type }}</td>
          <td>
            <FluencyGradeButton
              v-if="row.graded_at"
              label="View Grades"
              type="secondary"
              :video-id="row.id"
            />
            <div v-else style="display: flex; justify-content: space-between">
              <FluencyGradeButton
                v-if="row.transcode_status === 'Complete'"
                :label="row.type === 'Comparison' ? 'View Comparison' : 'Grade Video'"
                type="primary"
                :video-id="row.id"
                @graded="graded"
              />
              <div v-else>
                {{ row.transcode_status }}... <!-- usually 'waiting' or 'error' -->
              </div>

              <!-- TODO: use ActionButton (need fontawesome in teacher UI first) -->
              <b-button
                v-if="!row.graded_at && row.type.includes(' Read')"
                variant="danger"
                @click="deleteRecording(row)"
              >
                <i class="bi bi-trash"></i>&nbsp;Delete
              </b-button>
            </div>
          </td>
        </tr>
      </datatable>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../sass/bs5_theme_vars";

th {
  text-align: center;
  vertical-align: middle !important;
  font-weight: bold !important;
}

td {
  white-space: nowrap;
  text-align: center;
}
</style>
