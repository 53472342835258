<script setup lang="ts">
import FluencyGrader from "~components/teacher/fluency/FluencyGrader.vue"
import {FluencyRecording, FluencyGrade, FluencyRecordingSet} from "~app/fluency"
import axios from "axios"
import {computed, ref} from "vue"

const props = defineProps<{videoId: number}>()
const emit = defineEmits(["graded", "abort", "error"])

const selectedTab = ref("")
const readings = ref<FluencyRecordingSet>({} as FluencyRecordingSet)

// makes volar happy, since v-for="reading in readings" somehow makes reading nullable?  idk.
const readingsList = computed<FluencyRecording[]>(() => Object.values(readings.value))

////

load()

////

async function load() {
  const url = `/teacher/recordings/${props.videoId}`
  const response = await axios.get(url)
  const {id, selected, entries} = response.data
  if (id !== props.videoId) {
    console.error(`ID MISMATCH: props id=${props.videoId} response id=${id}`)
    return
  }
  readings.value = entries
  selectedTab.value = selected
}

async function onGraded({reading, grade}: {reading: FluencyRecording, grade: FluencyGrade}) {
  console.log("GRADED", {reading, grade})
  await load()  // don't really need the info, we'll just blindly refresh
}

async function onAbort() {
  // "abort" is actually just a regular close operation, not necessarily a cancel
  window.location.reload()  // nuclear option: nothing else I do refreshes the buttons
}

</script>

<template>
  <div class="mt-4">
    <b-tabs lazy>
      <b-tab
        v-for="reading in readingsList"
        :key="reading.classification"
        :title="reading.label"
        :active="reading.classification === selectedTab"
      >
        <FluencyGrader
          :reading="reading"
          :all-readings="readings"
          @graded="onGraded"
          @abort="onAbort"
        />
      </b-tab>
    </b-tabs>
  </div>
</template>
