<style lang="scss" scoped>
img {
  max-width: 305px;
  margin-bottom: 20px;
  cursor: pointer;
}
</style>

<template>
<div>
  <a @click.prevent="formOpen = true">
    <img src="/assets/images/school-trial-interested.png" width="50%" alt="School Trial Interested">
  </a>

  <b-modal v-model="formOpen" title="Sign Up for FREE!" ref="modal" size="lg">
    <div class="container-fluid">
      <h4 v-if="!succesfullySubmitted" class="alert alert-warning">To Receive Free Access For Your Entire School, Fill Out The Information Below</h4>
      <div class="col-sm-offset-2 col-sm-8 col-xs-12">
        <div v-if="errorMessage.length" class="alert alert-danger">{{ errorMessage }}</div>
        <div v-if="successMessage.length" class="alert alert-success">{{ successMessage }}</div>
        <section v-if="succesfullySubmitted">
        	<p>Thank you for considering Lyrics2Learn. After experiencing our program, we are confident you'll want to bring it into your school full-time!</p>
          <p>Teachers lessen their workload and get an easy to use literacy center.  Administrators won't need a grant to purchase the program, and your students will love the fusion of music and reading, allowing them to interact directly with text.</p>
          <p>I will contact you via email or phone to find out what date and time would work best for you to discuss our program in more detail.</p>
          <p>If you would prefer to e-mail me immediately, please use this address:  <a href="mailto:admin@lyrics2learn.com">admin@lyrics2learn.com</a>.</p>
          <p>Feel free to pass this offer on to other like-minded administrators.</p>
          <p>I look forward to communicating with you soon!</p><br>
          <p class="text-uppercase text-bold">Jeremy Spartz<br>
            Creator/Founder<br>
            Lyrics2Learn</p>
        </section>

        <form class="form-horizontal infusion-form" role="form" v-if="!succesfullySubmitted">
          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_custom_NameofSchool">School: *</label>
            </div>
            <div class="col-xs-8">
              <input class="infusion-field-input-container form-control" v-model="v$.form.school.$model" type="text" />
              <div class="error" v-if="!v$.form.school.required">Field is required</div>
              <div class="error" v-if="!v$.form.school.minLength">Field must have at least {{v$.form.school.$params.minLength.min}} letters.</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_field_FirstName">First Name: *</label>
            </div>
            <div class="col-xs-8">
              <input class="infusion-field-input-container form-control" v-model="v$.form.firstname.$model" type="text" />
              <div class="error" v-if="!v$.form.firstname.required">Field is required</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_field_LastName">Last Name: *</label>
            </div>
            <div class="col-xs-8">
              <input class="infusion-field-input-container form-control" v-model="v$.form.lastname.$model" type="text" />
              <div class="error" v-if="!v$.form.lastname.required">Field is required</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_field_Email">Email: *</label>
            </div>
            <div class="col-xs-8">
              <input class="infusion-field-input-container form-control" v-model="v$.form.email.$model" type="text" />
              <div class="error" v-if="!v$.form.email.required">Field is required</div>
              <div class="error" v-if="!v$.form.email.email">A valid email address is required.</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_field_Phone1">Phone: *</label>
            </div>
            <div class="col-xs-8">
              <input class="infusion-field-input-container form-control" v-model="v$.form.phone.$model" type="text" />
              <div class="error" v-if="!v$.form.phone.required">Field is required</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_custom_SchoolCity">City: *</label>
            </div>
            <div class="col-xs-8">
              <input class="infusion-field-input-container form-control" v-model="v$.form.city.$model" type="text" />
              <div class="error" v-if="!v$.form.city.required">Field is required</div>
            </div>
          </div>

          <div class="form-group">
            <div class="col-xs-4">
              <label for="inf_custom_SchoolState">State: *</label>
            </div>
            <div class="col-xs-8">
              <select class="form-control" v-model="v$.form.state.$model">
                <option value="AL">Alabama</option>
                <option value="AK">Alaska</option>
                <option value="AR">Arkansas</option><option value="AZ">Arizona</option><option value="CA">California</option><option value="CO">Colorado</option><option value="CT">Connecticut</option><option value="DC">District of Columbia</option><option value="DE">Delaware</option><option value="FL">Florida</option><option value="GA">Georgia</option><option value="HI">Hawaii</option><option value="ID">Idaho</option><option value="IL">Illinois</option><option value="IN">Indiana</option><option value="IA">Iowa</option><option value="KS">Kansas</option><option value="KY">Kentucky</option><option value="LA">Louisiana</option><option value="ME">Maine</option><option value="MD">Maryland</option><option value="MA">Massachusetts</option><option value="MI">Michigan</option><option value="MN">Minnesota</option><option value="MS">Mississippi</option><option value="MO">Missouri</option><option value="MT">Montana</option><option value="NE">Nebraska</option><option value="NV">Nevada</option><option value="NH">New Hampshire</option><option value="NJ">New Jersey</option><option value="NM">New Mexico</option><option value="NY">New York</option><option value="NC">North Carolina</option><option value="ND">North Dakota</option><option value="OH">Ohio</option><option value="OK">Oklahoma</option><option value="OR">Oregon</option><option value="PA">Pennsylvania</option><option value="RI">Rhode Island</option><option value="SC">South Carolina</option><option value="SD">South Dakota</option><option value="TN">Tennessee</option><option value="TX">Texas</option><option value="UT">Utah</option><option value="VT">Vermont</option><option value="VA">Virginia</option><option value="WA">Washington</option><option value="WV">West Virginia</option><option value="WI">Wisconsin</option><option value="WY">Wyoming</option>
              </select>
              <div class="error" v-if="!v$.form.state.required">Field is required</div>
            </div>
          </div>

          <div class="form-group">
          	<div class="col-xs-4"></div>
            <div class="col-xs-8">
            	<div class="g-recaptcha" data-sitekey="6LfSGhgTAAAAAMrmCtPUXDWP9XBkrGkCCjPoHP0X"></div>
            </div>
          </div>

          <div class="col-xs-12">
            <button class="btn-block btn-lg btn-success" @click.prevent="send()" :disabled="v$.$invalid">
              <span v-if="submitting">Submitting..</span>
              <span v-else>Submit</span>
            </button>
          </div>
        </form>
      </div>
    </div>

  </b-modal>
</div>
</template>

<script>
import useVuelidate from "@vuelidate/core"
import {email, minLength, required} from "@vuelidate/validators"

export default {
    setup() {
      return {
        v$: useVuelidate(),
      }
    },
      mounted() {
        console.log('Component mounted.')
      },
      data() {
        return {
          submitting: false,
          formOpen: false,
          succesfullySubmitted: false,
          errorMessage: '',
          successMessage: '',
          form: {
            school: '',
            firstname: '',
            lastname: '',
            email: '',
            phone: '',
            city: '',
            state: ''
          }
        }
      },
      validations: {
        form: {
          school: {
            required,
            minLength: minLength(3)
          },
          firstname: {
            required,
          },
          lastname: {
            required,
          },
          email: {
            required,
            email
          },
          phone: {
            required
          },
          city: {
            required
          },
          state: {
            required
          }
        }
      },
      methods: {
        send() {
          this.submitting = true;
          this.errorMessage = this.successMessage = '';
          axios.post('/school/free_trial', this.form)
          .then((response) => response.data)
          .then((data) => {
            if(data.success) {
              this.successMessage = data.message;
              this.message = '';
              this.succesfullySubmitted = true;
            } else {
              this.errorMessage = data.message;
            }
          }).catch((error) => {
            this.errorMessage = error;
          }).finally(() => {
            this.submitting = false;
          })
        }
      }
    }
</script>
