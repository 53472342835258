<template>
<span :class="classes">{{renderedDate}}</span>
</template>

<style scoped lang="scss">

.widget-time {
}

</style>

<script>
import {momentFormat} from '../../common/utils';

export default {

  props: {
    value: {
      type: String,
      required: true,
    },
    local: {
      type: Boolean,
      required: false,
      default: true,
    },
    format: {
      type: String,
      required: false,
      default: 'YYYY-MM-DD hh:mm:ss'
    },
    invalidStr: {
      type: String,
      required: false,
      default: '-'
    }
  },

  computed: {
    renderedDate() {
      return momentFormat(this.value, this.format, this.local) || this.invalidStr
    },
    classes() {
      return ['widget', 'widget-time', ...(this.local ? ['time-local'] : [])]
    }
  }

}

</script>
