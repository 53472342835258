<style lang="scss" scoped>
.teacher-email-summary {
  .text-round-block {
    text-align: center;
    // border: 2px solid #ccc;
    border-radius: 5px;
    padding: 15px;
    background: transparent;
    margin-bottom: 15px;
  }

  .text-round-block ol {
    padding-left: 15px;
    text-align: left;
  }

  .right_section_text input[type="text"], .right_section_text textarea {
    border-radius: 5px;
    border: 1px solid #ccc;
    font-size: 14px;
    margin-top: 0px;
    padding: 0 10px;
  }

  .right_section_text textarea {
    padding: 10px;
    width: 100%;
  }

  .input-group {
    margin-bottom: 10px;
  }

  .lefty-board-wrap {
    margin-top: 114px;
    padding-top: 50px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
    border-color: #c10000;
    background-color: white;
  }

  .lefty-board {
    position: absolute;
    left: 40%;
    top: 0px;
  }

  .modal-dialog {
    width: 900px !important;
  }

  p {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  }
}
</style>

<template>
  <div class="teacher-email-summary">
    <div class="text-round-block" style="text-align: left;">
      <section class="col-sm-12 card">
        <div v-if="!sent" class="text-round-block">
          <p>This email will be sent to yourself and any email addresses you enter below.</p>
          <div v-for="(item, index) in emails">
            <label>Email: </label>&nbsp;<input type="text" v-model="item.value" class="referralEmail">
          </div>

          <p><strong>Please add things you want people to know about Lyrics2Learn, then click send!</strong></p>
          <p>
            <input type="button" class="btn btn-success mr-2" value="Add Email" @click.prevent="addEmail()">
            <input type="button"
                   class="btn btn-danger"
                   value="Remove Email"
                   @click.prevent="removeEmail()"
                   :disabled="emails.length<=3">
          </p>
          <div class="form-group editor">
            <editor api-key="7vji1erxmio5rx613x4mehl92wm1rwzhqrrzv3o1t41i6a51" :init="editorInit" v-model="message" />
          </div>
          <div v-if="!sent" class="form-group">
            <button type="button" class="btn btn-secondary mr-2" @click.prevent="showPreview()">Preview
              Email
            </button>
            <button
              type="submit"
              class="btn btn-primary"
              @click.prevent="send()"
              :disabled="!hasOneOrMoreValidEmail() && !sending"
            >
              <span v-if="sending">Sending..</span>
              <span v-else>Send</span>
            </button>
          </div>
        </div>
        <div v-if="errorMessage" class="alert alert-soft-danger">{{ errorMessage }}</div>
        <div v-if="successMessage" class="alert alert-soft-success">{{ successMessage }}</div>
      </section>

    </div>

    <b-modal v-model="previewOpen" title="Email Preview" ref="modal" size="xl">
      <div v-html="preview"></div>
      <div style="clear: both;"></div>
    </b-modal>
  </div>
</template>

<script>
import Editor from "@tinymce/tinymce-vue"
import {VALID_EMAIL_REGEX} from "@/common/utils/validation.js"

export default {
  components: {
    "editor": Editor,
  },
  mounted() {
    console.log("Component mounted.")
    this.message = this.defaultMessage
    this.resetEmails()
  },
  data() {
    return {
      sending: false,
      sent: false,
      emails: [],
      message: "After a few weeks using Lyrics2Learn, students are highly engaged with text, receive daily fluency practice and independently answer 4 levels of comprehension with each story.  I am already seeing significant improvements! L2L gives me real-time data I can use to guide instruction, hold kids accountable, and compare to school and district assessments to prove an increased rate of student growth in my class. There's no training time or cost to get up and running, and set up is easy. L2L’s curriculum is designed for grades K–5. This program would be a huge value-add for myself, and even our entire school. Please give it your consideration.",
      preview: "<h1>Preview Not Available</h1>",  // Will be replaced
      previewOpen: false,
      errorMessage: "",
      successMessage: "",
      editorInit: {
        plugins: "wordcount",
        height: "200px",
        menubar: false,
        toolbar1: "undo redo | bold italic underline | forecolor backcolor",
      },
    }
  },
  props: {
    defaultMessage: {
      type: String,
      required: true,
    },
    placeholders: {
      type: String,
      required: true,
    },
    view: {
      type: String,
      required: true,
    },
  },
  methods: {
    addEmail() {
      this.emails = this.emails.concat([{value: "", placeholder: ""}])
    },
    removeEmail() {
      this.emails = this.emails.slice(0, this.emails.length - 1)
    },
    validEmails() {
      return this.emails.filter(addr => addr.value.match(VALID_EMAIL_REGEX)).map(m => m.value)
    },
    hasOneOrMoreValidEmail() {
      return (this.validEmails().length > 0)
    },
    getEndpoint() {
      return "/teacher/email/endpoint"
    },
    showPreview() {
      axios.post(this.getEndpoint(), {type: "preview", view: this.view, message: this.message})
        .then((response) => response.data)
        .then((data) => {
          this.preview = data
          this.previewOpen = true

          // make the dialog show up!
        }).catch((error) => {
        console.error(error)
      })
    },
    resetEmails() {
      let parts = this.placeholders.split(",")
      var m = []
      parts.forEach(function(placeholder) {
        m.push({value: "", placeholder: placeholder})
      })

      this.emails = m
    },
    send() {
      this.sending = true
      this.errorMessage = this.successMessage = ""
      axios.post(this.getEndpoint(), {type: "send", view: this.view, emails: this.validEmails(), message: this.message})
        .then((response) => response.data)
        .then((data) => {
          if (data.success) {
            this.successMessage = data.message
            this.resetEmails()
          } else {
            this.errorMessage = data.message
          }
        }).catch((error) => {
        this.errorMessage = error
      }).finally(() => {
        this.sending = false
        this.sent = true
      })
    },
  },
}
</script>
