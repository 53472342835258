<script setup lang="ts">
import FluencyGradingTabs from "~components/teacher/fluency/FluencyGradingTabs.vue"
import {ref} from "vue"
import {FluencyRecording} from "~app/fluency"

withDefaults(defineProps<{
  videoId: number
  label: string
  variant?: string
}>(), {
  variant: "secondary",
})

const showModal = ref(false)

function show() {
  showModal.value = true
}

function hide() {
  showModal.value = false
}

// TODO: route messages and errors to a global alert channel for displaying on-page
function onGraded(reading: FluencyRecording, message: unknown) {
  message && console.log(message)
  hide()
}

function onAbort(reading: FluencyRecording, message: unknown = null) {
  message && console.log(message)
  hide()
}

function onError(err: unknown) {
  console.error(err)
  hide()
}
</script>

<template>
  <div>
    <b-button :variant="variant" @click="show">{{ label }}</b-button>
    <b-modal v-model="showModal" size="xl" hide-header hide-footer>
      <FluencyGradingTabs :video-id="videoId" @graded="onGraded" @abort="onAbort" @error="onError" />
    </b-modal>
  </div>
</template>
