<script>
export default {
  created() {
    this.load()
  },

  data() {
    return {
      loading: false,
      questions: [],
      rubric: [
        {id: 1, points: 1, label: "I answered part of this, but not much. I have no support for my answer"},
        {
          id: 2,
          points: 2,
          label: "I answered all of this, but may not have used the text to support my statement or opinion.",
        },
        {
          id: 3,
          points: 3,
          label: "I have double checked this!  I have answered every part of this question and supported my statement or opinion with examples from the text.",
        },
      ],
    }
  },

  props: {
    lesson: {
      type: Number,
      required: true,
    },
    day: {
      type: Number,
      required: true,
    },
  },

  methods: {
    load() {
      this.loading = true
      axios.post("/student/examData", {lesson: this.lesson, day: this.day, mode: "take"})
        .then(response => this.questions = response.data.questions)
        .finally(() => this.loading = false)
    },
    clearAnswers() {
      this.questions.forEach(question => question.answer = "")
    },
  },
}
</script>

<template>
  <div class="exam">
    <div v-if="loading" class="alert alert-warning">Loading Questions...</div>
    <div v-else>
      <div v-if="day === 4">
        <div v-for="(question, index) in questions" :key="question.id">
          <div>
            <div class="box">
              <div class="hed">{{ index + 1 }}) <span v-html="question.question"></span></div>
              <textarea v-model="question.answer" style="width: 100%; height: 10em;"></textarea>
              <div class="text-center">Use correct spelling, punctuation, capitalization and grammar.</div>
            </div>

            <div class="rubric">
              Don't Forget to Score Your Answer!
              Use the rubric to be sure you are getting the top score!

              <div class="radio" v-for="rub in rubric" :key="rub.id">
                <label>
                  {{ rub.points }} Point{{ rub.points === 1 ? "" : "s" }}: {{ rub.label }}
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-else>
        <div v-for="(question, index) in questions" :key="question.id">
          <div>
            <div class="box">
              <div class="text-bold">
                <div v-if="question.question_voice_url" class="voiceover-question">
                  <voiceover-button :source="question.question_voice_url"></voiceover-button>
                </div>
                {{ index + 1 }}) <span v-html="question.question"></span><!-- span to esape ldquo things -->
              </div>
              <div v-if="question.answers_voice_url" class="voiceover-answers">
                <voiceover-button :source="question.answers_voice_url"></voiceover-button>
              </div>
              <ol>
                <li v-for="answer in question.answers" :key="answer.id">
                  <span :class="{'correct-answer': answer.correct }">
                    <strong>{{ answer.letter }}) </strong>
                    <span :class="{ selected: answer.selected }" v-html="answer.answertext"></span>
                  </span>
                </li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div>
        <input v-if="day === 4"
               type="reset"
               value="Clear"
               class="btn btn-lg btn-block btn-warning"
               @click.prevent="clearAnswers()"
        >
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.exam {
  .voiceover-question {
    float: right;
  }

  .voiceover-answers {
    float: right;
  }

  ol {
    padding-left: 15px;
    line-height: 20px;
  }

  .large {
    font-size: 20px;

    .correct {
      color: #009500;
    }

    .incorrect {
      color: #950000;
    }
  }

  .rubric {
    font-weight: bold;
  }

  .correct-answer {
    font-weight: bold;
    color: #009500;
  }
}
</style>
