<script>
export default {
  mounted() {
    // show it! (we will not mount it if we don't want to show it) (this could be changed)
    this.showModal = true
  },
  data() {
    return {
      showModal: false,
    }
  },
  props: {
    title: {
      type: String,
      default: "Welcome to Lyrics2Learn!",
    },
  },
}
</script>

<template>
  <b-modal
    v-model="showModal"
    class="lefty-modal"
    hide-header
    hide-footer
    hide-header-close
    @hide="$emit('hide')"
  >
    <div class="lefty-speech-wrap">
      <header class="lefty-speech header">
        <span class="modal-title">{{ title }}</span>
        <button type="button" class="close" @click.prevent="showModal = false">×</button>
      </header>

      <div class="lefty-speech">
        <slot></slot>
      </div>
    </div>
  </b-modal>
</template>

<style lang="scss" scoped>
::v-deep {
  .modal-dialog {

    .modal-content {
      background: none !important;
      border: 0px !important;
      box-shadow: none !important;

      .lefty-speech-wrap {
        background: url(/images/lefty-speech.png) no-repeat left bottom;
        background-size: 100px;
        min-height: 130px;
      }

      .lefty-speech {
        background: #fff;
        padding: 15px;
        border-radius: 0 0 10px 10px;
        margin-left: 85px;
        margin-bottom: 80px;

        &.header {
          margin-bottom: 0px;
          border-radius: 10px 10px 0 0;
          background: #c41717;
          color: #fff;
          box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.5);

          button.close {
            float: right; // yes i do use the occasional float
            border: none;
            background-color: transparent;
          }
        }

        h1 {
          text-align: center;
        }
      }
    }
  }
}
</style>
