<script>
import {GChart} from 'vue-google-charts/legacy';

export default {
  components: {GChart},

  props: {
    from: {type: String, default: '2000-01-01'},
    to: {type: String, default: '2500-01-01'},
    aggregateType: {type: String, default: 'class'},
    studentId: {type: String, default: 'overall'},
    teacherId: {type: String, default: ''},
    schoolId: {type: String, default: ''},
    districtId: {type: String, default: ''},
  },

  data() {
    return {
      loading: false,
      loaded: false,
      nodata: false,
      noDataMessage: '',
      wpmTarget: undefined,
      chart: undefined,
      title: '',
      chartOptions: {},
      chartData: [],
      tableData: [],
      horizontalLineStyle: {
        display: 'none',
        left: 0,
        width: 0,
        top: 0
      },
      graphTitle: 'Benchmark Progress',
      leftColumnHeader: 'WPM',
      rightColumnHeader: 'Expression',
      chartEvents: {
        'ready': () => {
          if (this.wpmTarget) {
            const cli = this.chart.getChartLayoutInterface();
            let bounds = cli.getChartAreaBoundingBox();
            this.horizontalLineStyle.display = 'block';
            this.horizontalLineStyle.left = bounds.left + 'px';
            this.horizontalLineStyle.width = bounds.width + 'px';
            this.horizontalLineStyle.top = cli.getYLocation(this.wpmTarget, 0) + 'px';
          }
        }
      },
    }
  },

  computed: {
    defaultChartOptions() {
      return {
        title: "",
        titlePosition: "none",
        curveType: "none",
        legend: {position: "bottom"},
        pointSize: 10,
        chartArea: {
          top: 30,
          backgroundColor: {
            strokeWidth: 2,
            stroke: '#eee',
          }
        },
        vAxes: {
          0: {minValue: 0, maxValue: 200},  // WPM
          1: {minValue: 1, maxValue: 5},    // expression (always goes to 6 on the actual graph)
        },
        series: {
          0: {
            pointShape: "square",
            targetAxisIndex: 0,
            color: "#04CD77"
          },
          1: {
            pointShape: "triangle",
            targetAxisIndex: 1,
            color: "#e2431e"
          }
        },
        hAxis: {
          slantedText: true,
          slantedTextAngle: 60
        },
      };
    },

    queryParams() {
      const params = {
        type: 'fluency_benchmark',
        student_id: this.studentId,
        from_date: this.from,
        to_date: this.to,
        aggregate_type: this.aggregateType
      }
      switch (this.aggregateType) {
        case 'class':
          if (this.teacherId) {
            params.teacher_id = this.teacherId;
          }
          break
        case 'school':
          if (this.teacherId) {
            params.teacher_id = this.teacherId
          }
          params.school_id = this.schoolId
          break
        case 'district':
          params.district_id = this.districtId
          break
      }
      return params
    },
  },

  methods: {
    load() {
      this.loading = true

      this.chartOptions = this.defaultChartOptions

      axios.get('/teacher/reports/graph', {params: this.queryParams})
        .then((response) => response.data)
        .then((data) => {
          console.log(data);
          this.noDataMessage = data.noDataMessage;

          this.chartData = data.data;
          this.nodata = (this.chartData.length === 1);

          this.tableData = data.tableData;
          this.wpmTarget = undefined;
          this.loaded = true;

          this.wpmTarget = data.wpmTarget;
          this.graphTitle = data.header;
          this.title = data.title;
          this.leftColumnHeader = 'WPM';
          this.chartOptions.vAxes['0'].minValue = 0;
          this.chartOptions.vAxes['0'].maxValue = data.wpmTarget + 10;
          this.rightColumnHeader = 'Expression';
          this.chartOptions.vAxes['1'].minValue = 1;
          this.chartOptions.vAxes['1'].maxValue = 5;
        }).finally(() => {
        this.loading = false;
      });
    },
    onChartReady(chart, google) {
      this.chart = chart;
      this.google = google;
    }
  },

  mounted() {
    this.load();
  },
};
</script>

<template>
  <div ref="reportGraph">
    <div v-if="loading">Loading...</div>
    <div v-else>
      <strong v-if="nodata">{{ noDataMessage }}</strong>
      <div v-else>
        <h3 class="text-center">{{ graphTitle }}</h3>
        <div class="chart-header-text">
          <div class="left">{{ leftColumnHeader }}</div>
          <div class="center">{{ title }}</div>
          <div class="right">{{ rightColumnHeader }}</div>
        </div>
        <GChart
          :settings="{ packages: ['corechart', 'line' ] }"
          type="LineChart"
          :data="chartData"
          :options="chartOptions"
          :events="chartEvents"
          @ready="onChartReady"
        />
        <table v-if="loaded" class="table table-condensed">
          <tr v-for="row in tableData">
            <th>{{ row.title }}</th>
            <td v-for="value in row.data">
              {{ value }}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.chart-header-text {
  display: flex;
  justify-content: space-between;
  align-items: center;

  .left {
    padding-left: 80px;
    font-weight: bold;
    color: #04CD77;
  }

  .center {
    flex: 1 1 auto;
    text-align: center
  }

  .right {
    padding-right: 80px;
    font-weight: bold;
    color: #e2431e;
  }
}
</style>
