<script>
import NewFluencyRecordingsTable from "~components/teacher/fluency/NewFluencyRecordingsTable.vue"
import AllFluencyRecordingsTable from "~components/teacher/fluency/AllFluencyRecordingsTable.vue"

export default {
  components: {AllFluencyRecordingsTable, NewFluencyRecordingsTable},

  props: {
    teacherId: {type: String, required: true}, // for later use
  },

  data() {
    return {
      loading_new: false,
      newRecordedVideos: [],
      loading_all: false,
      allRecordedVideos: [],
      errorMessage: undefined,
    }
  },

  methods: {
    loadVideos() {
      this.loadAllVideos()
      this.loadNewVideos()
    },

    loadAllVideos() {
      this.loading_all = true
      axios
        .get("/teacher/recordings/all")
        .then((response) => response.data)
        .then((data) => {
          console.log("loaded: ", data)
          this.allRecordedVideos = data
        })
        .catch((error) => {
          this.errorMessage = error
        })
        .finally(() => {
          this.loading_all = false
        })
    },

    loadNewVideos() {
      this.loading_new = true
      axios
        .get("/teacher/recordings/by_student?type=new")
        .then((response) => response.data)
        .then((data) => {
          console.log("loaded: ", data)
          this.newRecordedVideos = data
        })
        .catch((error) => {
          this.errorMessage = error
        })
        .finally(() => {
          this.loading_new = false
        })
    },
  },

  created() {
    this.loadVideos()
  },
}
</script>

<template>
  <div>
    <div v-if="errorMessage" class="alert alert-danger">{{ errorMessage }}</div>
    <b-tabs>
      <b-tab active title="New Fluency Videos">
        <div v-if="loading_new">
          Loading...
        </div>
        <div v-else>
          <div v-if="!newRecordedVideos.length">
            No fluency videos are available at this time
          </div>
          <NewFluencyRecordingsTable v-else :videos="newRecordedVideos" @graded="loadVideos()" />
        </div>
      </b-tab>
      <b-tab title="All Fluency Recordings">
        <div v-if="loading_all">
          Loading...
        </div>
        <div v-else>
          <div v-if="!allRecordedVideos.length" style="padding: 10px">
            No fluency videos are available at this time
          </div>
          <AllFluencyRecordingsTable v-else :videos="allRecordedVideos" @graded="loadVideos()" />
        </div>
      </b-tab>
    </b-tabs>
  </div>
</template>
