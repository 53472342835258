<script>

var imageAssets = {
  play: "/assets/images/play-btn.png",
  pause: "/assets/images/pause-btn.png",
  muted: "/assets/images/vol-mute-btn.png",
  unmuted: "/assets/images/volume-max-btn.png",
}

export default {
  mounted() {
    this.parseVideos()
  },
  props: {
    videos: String,
    quizLink: String,
  },
  data() {
    return {
      progressBarWidth: "0%",
      bufferBarWidth: "0%",
      progressBarClass: "",
      loaderClass: "",
      playBtnImageSrc: imageAssets.play,
      volumeBtnImageSrc: imageAssets.unmuted,
      watchedLessonCount: 0,
      replaycount: 0,
      recordButtonImage: undefined,
      videoTypes: [],
    }
  },
  watch: {
    videos: function(newVal, oldVal) {
      // console.log("Prop changed to "+newVal);
      this.parseVideos()
      this.video().currentTime = 0
    },
  },
  methods: {
    noop() {
      // do nothing.
    },
    parseVideos() {
      try {
        // console.log(this.videos);
        var types = JSON.parse(this.videos)
        var validTypes = []
        var model = this
        types.forEach(function(element) {
          if (model.canPlayVideo(element.type)) {
            validTypes.push(element)
          }
        })
        this.videoTypes = validTypes
      } catch (e) {
        console.error("Unable to set videos: ", e)
      }
    },
    takeQuiz() {
      window.location = this.quizLink
    },
    video() {
      return this.$refs.player
    },
    onWaiting() {
      this.progressBarClass = "progress-bar-striped active"
      this.loaderClass = "showloader"
    },
    onPlaying() {
      this.progressBarClass = ""
      this.loaderClass = ""
    },
    onProgress(evt) {
      this.updateProgress()
    },
    onTimeUpdate() {
      this.updateProgress()
    },
    onPlay() {
      this.playBtnImageSrc = imageAssets.pause
    },
    onPause() {
      this.playBtnImageSrc = imageAssets.play
    },
    onVolumeChange() {
      if (this.video().muted) {
        this.volumeBtnImageSrc = imageAssets.muted
      } else {
        this.volumeBtnImageSrc = imageAssets.unmuted
      }
    },
    onEnded() {
      this.video().pause()
      this.watchedLessonCount = this.watchedLessonCount + 1
    },
    updateProgress() {
      // Work out how much of the media has played via the duration and currentTime parameters
      var percentage = Math.floor((100 / this.video().duration) * this.video().currentTime)
      var percentage1 = 0
      if (this.video().buffered.length) {
        percentage1 = Math.floor((100 / this.video().duration) * this.video().buffered.end(0))
      }
      this.progressBarWidth = percentage + "%"
      this.bufferBarWidth = percentage1 + "%"
    },
    // Toggles the media player's mute and unmute status
    toggleMute() {
      if (this.video().muted) {
        this.volumeBtnImageSrc = imageAssets.unmuted
        // Unmute the media player
        this.video().muted = false
      } else {
        this.volumeBtnImageSrc = imageAssets.muted
        // Mute the media player
        this.video().muted = true
      }
    },
    togglePlay() {
      if (this.video().paused || this.video().ended) {
        this.playBtnImageSrc = imageAssets.pause
        // Play the media
        this.video().play()
      }
      // Otherwise it must currently be playing
      else {
        // Change the button to be a play button
        this.playBtnImageSrc = imageAssets.play
        // Pause the media
        this.video().pause()
      }
    },
    stop() {
      this.video().pause()
      this.video().currentTime = 0
    },
    // Replays the media currently loaded in the player
    replay() {
      if (this.watchedLessonCount != 0) {
        this.replaycount += 1
      }
      this.video().currentTime = 0
      this.video().play()
    },
    // Checks if the browser can play this particular type of file or not
    canPlayVideo(mimeType) {
      var ableToPlay = this.video().canPlayType(mimeType)
      if (ableToPlay == "") return false
      else return true
    },
  },
}
</script>

<template>
  <section class="video-player" @contextmenu.prevent="noop()">
    <video id="media-video"
           ref="player"
           @waiting="onWaiting()"
           @playing="onPlaying()"
           @progress="onProgress()"
           @timeupdate="onTimeUpdate()"
           @pause="onPause()"
           @play="onPlay()"
           @volumechange="onVolumeChange()"
           @ended="onEnded()">
      <source v-for="video in videoTypes" :key="video.type" :type="video.type" :src="video.path" />
    </video>

    <slot></slot>

    <section id="media-controls" class="player-controls-wrap">
      <section class="text-center">
        <section class="common-btn-group">
          <button class="replay" title="replay" @click="replay()">
            <img src="/assets/images/replay-btn.png" alt="Replay Button">
          </button>
          <button class="play" title="play" @click="togglePlay()">
            <img :src="playBtnImageSrc" id="pp-btn" alt="Play Button">
          </button>
          <button class="stopz" title="stop" @click="stop()">
            <img src="/assets/images/stop-btn.png" alt="Stop Buttom">
          </button>
        </section>
      </section>

      <section class="questions-btn" v-if="quizLink">
        <button :href="quizLink" class="quiz_btn btn btn-danger" @click.prevent="takeQuiz()">
          View the Quiz
        </button>
        <span class="quiz-disabled-overlay"></span>
      </section>

      <section class="volume-btn">
        <button class="volume" title="Mute" @click="toggleMute()">
          <img :src="volumeBtnImageSrc">
        </button>
      </section>

      <div class="progress">
        <div id="progress-bar"
             class="progress-bar progress-bar-danger"
             :class="progressBarClass"
             role="progressbar"
             aria-valuenow="0"
             aria-valuemin="0"
             aria-valuemax="100"
             :style="{ width: progressBarWidth }">
        </div>
        <div id="buffer-bar"
             class="progress-bar progress-bar-success buffer"
             role="progressbar"
             aria-valuenow="0"
             aria-valuemin="0"
             aria-valuemax="100"
             :style="{ width: bufferBarWidth }">
        </div>
      </div>
    </section>
  </section>
</template>

<style lang="scss" scoped>
.quiz_btn {
  text-transform: uppercase;
}

.quiz-disabled-overlay {
  display: none;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}

.plays-remaining-container {
  position: absolute;
  right: -97px;
  top: 14px;
}

.need-to-record-container {
  position: absolute;
  right: -164px;
  top: 31px;
}

.player-controls-wrap .progress {
  border: 5px solid red;
  //height: 4px;
  //margin-bottom: 0px;
  //border-radius: 0px;
  //background: linear-gradient(to bottom, #ebebeb 0px, #f5f5f5 50%)
}

#video-player-container {
  margin-top: 30px;
}

#video-player-container .container .video-player {
  position: relative;
}

.video-player #media-video {
  width: 100%;
  border: 15px solid #fff;
  box-shadow: 0px 0px 10px #131313;
}

#media-controls {
  position: absolute;
  bottom: 19px;
  width: 100%;
  padding: 0 15px;
}

#progress-bar {
  position: absolute;
  height: 4px;
  z-index: 1;
}

#buffer-bar {
  position: absolute;
  height: 4px;
  z-index: 0;
}

.progress {
  position: relative;
}

.progress-bar-success.buffer {
  background-image: linear-gradient(to bottom, #f1c5c5 0px, #ffbebe 100%);
}

.showloader {
  display: block !important;
  opacity: 1 !important;
}

.player-controls-wrap .progress {
  height: 4px;
  margin-bottom: 0px;
  border-radius: 0px;
  background: linear-gradient(to bottom, #ebebeb 0px, #f5f5f5 50%)
}

.common-btn-group-wrap {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.44) 100%); /* W3C */
  padding-bottom: 10px;
}

.common-btn-group button {
  background: none;
  margin: 0 15px;
}

.volume-btn button {
  background: none;
}

.questions-btn {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  bottom: 4px;
  left: 15px;
  padding: 15px 25px 15px 15px;
  border-radius: 0 50px 50px 0;
  color: #fff;
}

.volume-btn {
  position: absolute;
  background: rgba(0, 0, 0, 0.2);
  bottom: 4px;
  right: 15px;
  padding: 15px 15px 15px 25px;
  border-radius: 100% 0 0 100%;
}
</style>
