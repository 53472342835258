<script>
export default {
  props: {
    videoId: {type: Number, required: true},
  },

  data() {
    return {
      submitting: false,
      email: "",
      subject: "",
      body: "",
    }
  },

  methods: {
    reset() {
      this.error = undefined

      axios.get(`/teacher/recordings/${this.videoId}/share`)
        .then(response => response.data)
        .then(data => {
          this.visible = true
          this.email = data.email
          this.subject = data.subject
          this.body = data.body
        })
    },

    share() {
      const url = `/teacher/recordings/${this.videoId}/share`
      const data = {to: this.email, subject: this.subject, body: this.body}

      this.submitting = true
      axios.post(url, data)
        .then(() => this.$emit("done"))
        .catch(error => this.$emit("error", error))
        .finally(() => this.submitting = false)
    },
  },

  created() {
    this.reset()
  },
}
</script>

<template>
  <div>
    <form
      method="post"
      name="formShareWithParent"
      class="share-with-parent-form"
      @submit.prevent="share"
    >
      <div class="form-group row">
        <div class="col-md-4 control-label">
          <label for="emailParentEmail">Parent Email:</label>
        </div>
        <div class="col-md-12">
          <input
            required
            type="email"
            class="form-control"
            name="emailParentEmail"
            v-model.trim="email"
            placeholder="Enter the parent's email..."
            :disabled="submitting"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4 control-label">
          <label for="emailSubject">Email Subject:</label>
        </div>
        <div class="col-md-12">
          <input
            required
            type="text"
            class="form-control"
            name="emailSubject"
            v-model.trim="subject"
            placeholder="Enter an email subject..."
            :disabled="submitting"
          />
        </div>
      </div>

      <div class="form-group row">
        <div class="col-md-4 control-label">
          <label for="emailBody">Email Body:</label>
        </div>
        <div class="col-md-12">
          <textarea
            required
            style="height: 200px"
            class="form-control"
            name="emailBody"
            v-model.trim="body"
            placeholder="Enter an email body..."
            :disabled="submitting"
          />
        </div>
      </div>

      <div class="modal-footer">
        <button class="btn btn-default" @click="$emit('cancelled')" :disabled="submitting">
          Cancel
        </button>

        <button type="submit" class="btn btn-primary" :disabled="submitting">
          {{ submitting ? "Sharing..." : "Share" }}
        </button>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
.form-control {
  min-height: inherit;
}

label {
  min-height: 3em;
}
</style>
