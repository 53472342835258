<template>
  <div class="teacher-resources-page container">
    <h1 class="text-primary">Boost Fluency 50-100%</h1>
    <hr>

    <div class=" ">
      <nav class="button-bar">
        <a class="btn planner-button" href="?tab=planner">
          <i class="bi bi-calendar"></i><br>
          Weekly Planner
        </a>

        <a class="btn chart-button" href="?tab=chart">
          <i class="bi bi-bar-chart"></i><br>
          Fluency Chart
        </a>

        <a class="btn curriculum-button" href="?tab=curriculum">
          <i class="bi bi-list"></i><br>
          Curriculum List
        </a>

        <a class="btn sheets-button" href="?tab=sheets">
          <i class="bi bi-file-pdf"></i><br>
          Fluency Passages
        </a>

        <a class="btn implementation-button" href="?tab=implementation">
          <i class="bi bi-file-pdf"></i><br>
          Implementation Guide
        </a>
      </nav>

      <div class="container">
        <div v-if="tab === 'planner'" class="planner-panel">
          <img class="img-responsive w-100" src="/assets/images/boost_fluency_1.png" alt="Weekly L2L Planner">
          <img class="img-responsive w-100" src="/assets/images/boost_fluency_2.png" alt="example from the classroom">
        </div>

        <div v-if="tab === 'chart'" class="chart-panel">
          <a href="/teacher/resources/misc/Fluency Chart.pdf">
            Click to download a printable fluency chart
            <br>
            <img src="/images/fluency_chart_thumb.png" alt="printable fluency chart thumbnail">
          </a>
        </div>

        <div v-if="tab === 'curriculum'" class="curriculum-panel">
          <a href="/teacher/resources/misc/Lyrics2Learn Curriculum 1st-5th Grade.pdf">
            Click to download the full curriculum
            <br>
            <img src="/images/curriculum_thumb.png" alt="curriculum sheets pdf thumbnail">
          </a>
        </div>

        <div v-if="tab === 'sheets'" class="fluencysheets-panel">
          <h3>Download Fluency Sheets</h3>
          <div class="grade-buttons">
            <a v-for="lvl of [1,2,3,4,5]" class="btn grade-level-button" :href="`?tab=sheets&level=${lvl}`" :key="lvl">
              {{ levelname(lvl) }}
            </a>
          </div>

          <table class="table table-striped">
            <thead>
            <tr>
              <th>Level</th>
              <th>Sheet Title</th>
              <th>Sheet Link <i class="bi bi-box-arrow-up-right"></i></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="sheet of fluencySheets" class="document">
              <td>
                {{ sheet.level }}
              </td>

              <td>
                {{ sheet.title }}
              </td>

              <td>
                <a :href="`resources/fluency-sheets-2021-12-15/${sheet.file}`" :target="sheet.title">
                  <i class="bi bi-file-pdf"></i>
                  {{ sheet.title }}
                </a>
              </td>
            </tr>
            </tbody>
          </table>
        </div>

        <div v-if="tab === 'implementation'" class="implementation-panel">
          <a href="/teacher/resources/misc/Lyrics2Learn Fluency Implementation Guide.pdf">
            Read the full Lyrics2Learn Fluency Implementation Guide here
            <br>
            <img src="/images/impl_guide_thumb.png" alt="implementation guide pdf thumbnail">
          </a>
          <hr>
          <a href="/teacher/resources/misc/Lyrics2Learn Fluency Rubric.pdf">
            Read the Lyrics2Learn Fluency Rubric here
            <br>
            <img src="/images/fluency_rubric_thumb.png" alt="fluency rubric pdf thumbnail">
          </a>
        </div>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../sass/bs5_theme_vars";

.teacher-resources-page {

  a.btn, a.btn:hover {
    color: $bs-light;
    font-size: 1.75rem;

    i {
      font-size: 3rem;
    }
  }

  .content {
    margin-top: 2em;
  }

  .btn {
    margin: 0.5rem;
  }

  .planner-button {
    background-color: $bs-blue;
  }

  .chart-button {
    background-color: $bs-green;
  }

  .curriculum-button {
    background-color: $bs-purple;
  }

  .sheets-button {
    background-color: $bs-red;
  }

  .implementation-button {
    background-color: $bs-yellow;
    color: $bs-dark !important;
  }

  .grade-buttons {
    margin: 1rem 0;
  }

  .grade-level-button {
    color: black !important;
    margin: 0.5rem;
    background-color: #eee;
    border: 1px solid #ccc;
    font-size: 1.5rem !important;
  }

  .planner-panel * {
    margin-top: 3rem;
  }

}
</style>

<script>
"use strict"

export default {
  name: "TeacherResources",

  components: {},

  props: {
    tab: {
      type: String,
      default: "boost-fluency",
    },
    level: {
      type: String,
      default: "1",
    },
  },

  data() {
    return {
      response: null,
    }
  },

  computed: {
    resources() {
      return this.response?.data?.resources
    },
    fluencySheets() {
      return this.resources?.["fluency-sheets"].filter(sheet => sheet.level === this.level)
    },
  },

  created() {
    if (this.tab === "sheets") this.fetchResourcesList()
  },

  methods: {
    async fetchResourcesList() {
      this.response = await axios.get("/teacher/resources")
    },
    levelname(level) {
      const idx = parseInt(level, 10)
      const names = ["Kindergarten", "First Grade", "Second Grade", "Third Grade", "Fourth Grade", "Fifth Grade"]
      return names[idx]
    },
  },
}
</script>
