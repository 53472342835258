<style lang="scss" scoped>
.social-button {
	background-color: #286EFA;
	border: 1px solid #1271c4;
	border-radius: 6px;
	color: #fff;
	display: inline-block;
	font-family: 'Intercom Text', 'Helvetica Neue', Helvetica, Arial, sans-serif;
	font-size: 16px;
	min-height: 40px;
	overflow: hidden;
	text-align: center;
	text-decoration: none;
	transition: background-color 0.2s ease;
	vertical-align: middle;
	position: relative;
	width: 100%;

	&:hover {
		cursor: pointer;
	}

	span.label {
		font-family: serif;
		font-weight: normal;
	}

	span.buttonText {
		display: inline-block;
		vertical-align: middle;
		font-size: 14px;
		font-weight: bold;
		font-family: 'Roboto', sans-serif;
		position: absolute;
		left: 30px;
		top: 0;
		bottom: 0;
		right: 0;
		line-height: 38px;
	}

	a {
		position: absolute;
		left: 0;
		top: 0;
		right: 0;
		bottom: 0;
		color: white;
		line-height: 38px;
	}

	&.google {
		span.icon {
			background: url(/assets/images/google_g_logo-36x36.png) #fff 50% 50% no-repeat;
			background-size: 18px;
			display: inline-block;
			vertical-align: middle;
			width: 38px;
			height: 38px;
			float: left;
		}
	}

	&.facebook {
		background-color: #4267b2;
		border: 1px solid #4267b2;

		span.icon {
			background: url(/assets/images/facebook_logo.png) #fff 50% 50% no-repeat;
			background-size: 18px;
			display: inline-block;
			vertical-align: middle;
			width: 38px;
			height: 38px;
			float: left;
			background-color: #4267b2;
		}
	}

	&.clever {
		span.icon {
			background: url(/assets/images/clever_logo.png) #fff 50% 50% no-repeat;
			background-size: 18px;
			display: inline-block;
			vertical-align: middle;
			width: 38px;
			height: 38px;
			float: left;
		}
	}
}

//.social-buttons div nth child needs thhis
@media screen and (max-width: 768px) {
	.social-buttons {
		div {
			padding-top: 10px;
		}

		div:first-child {
			padding-top: inherit;
		}
	}
}
// #1365ff
</style>

<template>
  <div>
    <div class="row social-buttons" v-if="!loading">
      <div class="col-sm-6" v-for="entry in entries" :key="entry.type" style="margin-top: 1rem;">
        <div class="social-button" :class="entry.type">
          <a :href="entry.url">
            <span class="icon"></span>
            <span class="buttonText">{{ entry.label }}</span>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
	mounted() {
		this.load();
	},
	data() {
		return {
			loading: true,
			entries: []
		};
	},
	props: {
		isRegister: {
			type: Boolean,
			required: true,
		},
	},
	methods: {
		load() {
			this.loading = true;
			axios.post('/social/providers', {
				type: this.isRegister ? 'register' : 'login'
			})
			.then((response) => response.data)
			.then((data) => {
				this.entries = data;
			}).catch((error) => {
				console.log("error"); // FIXME
				this.error = error.toString();
			}).finally(() => {
				this.loading = false;
			});
		},

    buttonLabel(provider) {
      const signOrLog = this.isRegister ? "Sign up" : "Login";
      return `${signOrLog} with ${provider}`
    }
	}
}
</script>
