<script>
import {GChart} from 'vue-google-charts/legacy';

export default {
  components: {GChart},

  props: {
    lessonCwpm: {type: Object, required: true},
  },

  data() {
    return {
      chart: undefined,
    }
  },

  computed: {
    chartData() {
      return [
        ['Week', 'Cold Read', 'Hot Read'],
        ...makeChartData(this.lessonCwpm)
      ];
    },

    chartOptions() {
      return {
        isStacked: false,
        legend: {position: "bottom"},
        series: {
          0: {
            color: "#3490dc" // $blue in _variables.scss
          },
          1: {
            color: "#e3342f" // $red in _variables.scss
          }
        },
      }
    },
  },
}

function *makeChartData(cwpm) {
  for (const [title, value] of Object.entries(cwpm)) {
    yield [title, value['cold_read'] ?? 0, value['hot_read'] ?? 0];
  }
}
</script>

<template>
  <div>
    <h3 class="text-center">Fluency Correct Words Per Minute</h3>
    <GChart type="ColumnChart" :data="chartData" :options="chartOptions" />
    <!--    <pre class="debug debug-border-red">{{ {chartData, lessonCwpm} }}</pre>-->
  </div>
</template>

<style lang="scss" scoped>
</style>
