<style lang="scss" scoped>
	.table {
		img {
			max-width: 80px;
		}

		td {
			vertical-align: middle;
		}

	}

	.lesson-tile {
		&.selected {
			border-radius: 10px;
			background-color: rgba(0, 0, 255, .25)
		}

		img {
			margin: 0 auto;
		}

		.description {
			margin-bottom: 10px;
			text-align: center;
			min-height: 100px;
			font-size: 1em;

			h3 {
				font-size: 1.1em !important;
				font-weight: bold;
				height: 2em;
			}

			.label {
				line-height: 15px;
			}
		}
	}
</style>


<template>
	<div>
		<div v-if="loading"><h3>Loading Lessons...</h3></div>
		<div v-else>
			<div style="padding: 5px;">
				<div class="pull-left">
					<input placeholder="Search..." class="form-control" v-model="filter">
				</div>

				<div style="clear: both;"></div>
			</div>


			<div class="table table-striped table-responsive">
				<datatable :columns="columns" :data="displayedRows" :filter-by="filter">

					<template v-slot="{ row }">
						<tr>
							<td scope="row">
								<img :src="row.logo" class="img-responsive"/>
							</td>
							<td>
								{{ row.title }}
							</td>
							<td>
								<GradeLevelTagLabel v-if="row.reading_level" :grade="row.reading_level" />
							</td>
							<td>
								<a :href="row.worksheet" target="_blank">
									<img src="/images/pdf.png"/>
								</a>
							</td>
						</tr>
					</template>
				</datatable>
			</div>
		</div>
	</div>
</template>

<script>
	import GradeLevelTagLabel from "~components/GradeLevelTagLabel.vue"

  export default {
    components: {GradeLevelTagLabel},
		mounted() {
			this.loadLessons();
		},
		data() {
			return {
				loading: false,
				mode: 'list',
				columns: [
					{label: 'Image', field: '', sortable: false, align: 'left'},
					{label: 'Title', field: 'title', align: 'left'},
					{label: 'Reading Level', field: 'reading_level', align: 'left'},
					{label: 'Worksheet', field: 'worksheet', 'sortable': false, align: 'left'},
				],
				lessons: [],
				filter: ''
			}
		},
    computed: {
      rows() {
        // turns lessons_by_level into the old all_lessons format
        return Object.entries(this.lessons)
          .flatMap(([reading_level, lessons]) => lessons.map(lesson => ({...lesson, reading_level})))
      },
      displayedRows() {
        return this.rows.filter(lesson => !/benchmark/i.test(lesson.reading_level))
      }
    },
		methods: {
			loadLessons() {
				this.loading = true;
				axios.get('/teacher/lessons/assign/lessons_by_level')
					.then((response) => response.data)
					.then((data) => {
						this.lessons = data
					})
					.catch((error) => {
						this.errorMessage = error;
					})
					.finally(() => {
						this.loading = false;
					})
			}
		}
	}
</script>
