<style lang="scss">
  .subscribe-button button {
    font-weight: bold;
    font-size: 150%;
    height: 4rem;
  }
</style>

<template>
<div class="subscribe-button">
  <vue-stripe-checkout
    v-if="showDialog"
    ref="checkoutRef"
    auto-open-modal
    image="/apple-touch-icon-120x120.png"
    name="Lyrics2Learn"
    label="Subscribe Now"
    :publishable-key="apiKey"
    locale="auto"
    :email="email"
    panelLabel="Subscribe"
    :description="description"
    :amount="amount"
    :allow-remember-me="false"
    @done="cardDone"
    @canceled="cardCancelled"
  />
  <button class="btn btn-block btn-lg" :class="btnClass" @click="subscribe">
    <span v-if="purchasing">Purchasing...</span>
    <span v-else>{{ btnLabel }}</span>
  </button>
</div>

</template>

<script>

export default {
    // horrifyingly, VueStripeCheckout breaks when we add it to components,,,
    // components: {VueStripeCheckout},

    props: {
      amount: {
        type: Number,
        required: true,
      },
      btnClass: {
        type: String,
        required: false,
        default: 'btn-success'
      },
      btnLabel: {
        type: String,
        required: true
      },
      description: {
        type: String,
        required: true
      },
      apiKey: {
        type: String,
        required: true
      },
      email: {
        type: String,
        required: true
      },
      type: { // one of teacher, parent FIXME: Ideally this woul dhave a stripe plan ID and a duration.
        type: String,
        required: true
      },
      period: { // one of annually, monthly
        type: String,
        required: true
      }
    },
    data(){
      return {
        showDialog: false,
        purchasing: false
      };
    },
    methods: {
      async subscribe () {
        // token - is the token object
        // args - is an object containing the billing and shipping address if enabled

        // LL2L-643: we can only have one vue-stripe-checkout on the page.  The fix is to conditionally render and auto-show
        // const { token, args } = await this.$refs.checkoutRef.open();
        this.showDialog = true
      },
      cardDone ({token, args}) {
        // token - is the token object
        // args - is an object containing the billing and shipping address if enabled
        // do stuff...
        var params = {
          'email': this.email,
          'token': token.id,
          'args': args,
          'type': this.type,
          'period': this.period,
          'amount': this.amount
        };

        this.purchasing = true;
        axios.post('/subscribe', params)
        .then((response) => response.data)
        .then((data) => {
          if(data.success) {
            // redirect.
            window.location = data.redirect;
          } else {
            this.showError(data.message);
          }
        }).catch((error) => {
          try {
            if(error.response.data.errors) {
              var msg = '';
              for(var k in error.response.data.errors) {
                msg += error.response.data.errors[k][0];
              }
              this.showError(msg);
            }
          } catch {
            this.showError(error.toString());
          }
        }).finally(() => {
          this.purchasing = false;
        });
      },
      cardCancelled() {
      },
      showError(error) {
        // FIXME: SHOW THE ERRORS!
        console.log(error);
      }
    },
  }
 </script>
