<script>

  // This could eventually replace the accordion menu widgets,
  // but it'll need a lot more work first, such as breaking
  // out the fetching from pure presentational components.

  import GradeLevelTagLabel from "~components/GradeLevelTagLabel.vue"

  export default {
    name: "LessonTile",
    components: {GradeLevelTagLabel},

    props: {
      lessonId: {
        type: Number,
        required: true,
      },

    },

    data() {
      return {
        lesson: undefined,
      }
    },

    created() {
      this.fetchLesson()
    },

    methods: {
      async fetchLesson() {
        // TODO: use WithServices once it's broken out of admin
        // No; use lessonCache service that loads each lesson once; once SPA for the win.
        const response = await axios.get('/service/objects/lesson/' + this.lessonId)
        this.lesson    = response.data.data;
      },
      clickLesson() {
        this.$emit('selected', this.lesson);
      }

    },

    computed: {
        genreLine() {
            var result = '';
            if(this.lesson)
            {
                var parts = [];

                if(this.lesson.storygenre && this.lesson.storygenre.length) {
                    parts.push(this.lesson.storygenre);
                }

                if(this.lesson.subject && this.lesson.subject.length) {
                    parts.push(this.lesson.subject);
                }

                if(this.lesson.musicgenre && this.lesson.musicgenre.length) {
                    parts.push(this.lesson.musicgenre);
                }

                result = parts.join(' / ');
            }

            return result;
        }
    }

  }
</script>

<template>
    <div>
        <div class="lesson-tile" v-if="lesson" @click="clickLesson()">
            <slot name="logo" v-bind="{lesson}">
                <div class="img-container">
                    <img class="img-responsive" :src="lesson.logo_url">
                </div>
            </slot>
            <slot name="details" v-bind="{lesson}">
                <div class="lesson-details">
                    <div class="title-row">
                        <span class="lesson-title">{{lesson.ltitle}}</span>
                    </div>
                    <div class="">
                        <GradeLevelTagLabel v-if="lesson.gradeLabel" :grade="lesson.gradeLabel" />
                    </div>
                    <span class="">{{ genreLine }} </span>
                    <span class=""><b>Lexile:</b> {{ lesson.lexile_measure }}  <b>WPM:</b> {{ lesson.wordspermin }}</span>
                </div>
            </slot>
        </div>
        <div v-else>
            <slot name="loading" v-bind="{lessonId}">
                <div class="loading">
                    <h3>Loading...</h3>
                </div>
            </slot>
        </div>
    </div>
</template>

<style lang="scss" scoped>
    .lesson-tile {
        display: flex;
        cursor: pointer;

        .img-container {
            width: 80px;
        }

        .lesson-details {
            display: flex;
            flex-direction: column;
            margin-left: 10px;

            .lesson-title {
                font-weight: bold;
                font-size: 2rem;
            }
        }

        &:hover {
            border-radius: 4px;
            background-color: #dcdcdc;
        }
    }

    .loading {
        min-height: 80px;
        border: 1px solid #333333;
        display: flex;
        margin: 2px;
        border-radius: 4px;

        h3 {
            margin: auto;
        }
    }
</style>
