<script lang="ts" setup="">
import {computed} from "vue"
import {UserInfo} from "@/common/utils/auth"

const props = withDefaults(defineProps<{
  user?: UserInfo | null | undefined
}>(), {
  user: null,
})


function initials(name: string) {
  return name.split(" ", 2).map(s => s[0].toUpperCase()).join("")
}

const userInitials = computed(() => initials(props.user?.fullname ?? "? ?"))

const variant = computed(() => props.user?.impersonated ? "danger" : "dark")

</script>

<template>
  <b-avatar class="user-avatar" :text="userInitials" :variant="variant" v-bind="$attrs" ></b-avatar>
</template>

<style scoped lang="scss">
.user-avatar {
  display: inline-flex;
  align-items: center;
  justify-content: center;
}
</style>
