
// Slightly sloppy regex, but trying to parse all valid emails with a regex is insanity itself.
export const VALID_EMAIL_REGEX = /^[A-Za-z0-9_!#$%&'*+\/=?`{|}~^.-:;]+@[A-Za-z0-9.-]+\.[A-Za-z0-9.-]+$/

// https://github.com/monterail/vuelidate/issues/242
// This is the wrapper function: validator is the actual validation function, and delay is in milliseconds.
// Note that the validator function accepts two parameters: the value and a debounce function to wrap
// the asynchronous validation.
export function debounceAsyncValidator(validator, delay) {
  let currentTimer = null
  let currentPromiseReject = null

  function debounce() {
    return new Promise((resolve, reject) => {
      currentTimer = setTimeout(() => {
        currentTimer = null
        currentPromiseReject = null
        resolve()
      }, delay)
      currentPromiseReject = reject
    })
  }

  return function (value) {
    if (currentTimer) {
      currentPromiseReject(new Error('replaced'))
      clearTimeout(currentTimer)
      currentTimer = null
    }

    return validator.call(this, value, debounce)
  }
}

