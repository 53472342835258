// "private" module: support types and functions for the assignment UI.  not generally reusable.

import {computed, Ref} from "vue"
import {useFetcher} from "@/common/utils/fetch"

// TODO: replace with ziggy.route()
const ENDPOINTS = {
  get_all_lessons: '/teacher/lessons/assign/lessons_by_level',
  get_all_students: '/teacher/lessons/assign/students',
  post_assignment: '/teacher/lessons/assign'
}

////////////////////////////////////////////////////////////////
// lessons

export function useLessonsByLevel() {
  const fetcher = useFetcher(ENDPOINTS.get_all_lessons).get().json()
  const lessonsByLevel = computed<Record<string, LessonRow[]>>(() => fetcher.data.value ?? [])
  return {lessonsByLevel, fetcher}
}

export type LessonRow = {
  readonly id: number
  readonly title: string
  readonly grade_level: string // usually the lowest grade; for lessonsByLevel, use the key instead.
  readonly lexile: string | null,
  readonly subject: string | null
  readonly storygenre: string | null
  readonly wpm: string | null  // yes a string, the table filters on all strings
  readonly logo: string | null
  readonly worksheet: string | null
}

////////////////////////////////////////////////////////////////
// students

export function useStudentRows() {
  const fetcher = useFetcher(ENDPOINTS.get_all_students).get().json()
  const students = computed<StudentRow[]>(() => fetcher.data.value ?? [])
  return {students, fetcher}
}

export type StudentRow = {
  id: number
  name: string
  login: string
  level: string
  assigned_lessons: LessonRow[]
  unassigned_lessons: LessonRow[]
  completed_lessons: LessonRow[]
  incompleted_lessons: LessonRow[]
  started_lessons: LessonRow[]
}

////////////////////////////////////////////////////////////////
// assignment

export function usePostAssignments(assignments: Ref<AssignmentInfo>) {
  const input = computed(() => makePostAssignmentInput(assignments.value))
  const fetcher = useFetcher(ENDPOINTS.post_assignment, {immediate: false, refetch: true}).post(input).json()
  return {fetcher}
}

function makePostAssignmentInput(info: AssignmentInfo): PostAssignmentInput {
  const getId = (x: {id: number}) => x.id
  return {
    student_ids: info.students.map(getId),
    assign_lesson_ids: info.assign.map(getId),
    remove_lesson_ids: info.remove.map(getId),
    preserve_progress: info.preserve_progress ?? false,
  }
}

export type AssignmentInfo = {
  students: StudentRow[]
  assign: LessonRow[]
  remove: LessonRow[]
  preserve_progress?: boolean
}

type PostAssignmentInput = {
  student_ids: number[]
  assign_lesson_ids: number[]
  remove_lesson_ids: number[]
  preserve_progress: boolean
}


////////////////////////////////////////////////////////////////
