<script setup lang="ts">
import FluencyGradingLyrics from "~components/teacher/fluency/FluencyGradingLyrics.vue"
import FluencyGradingVideo from "~components/teacher/fluency/FluencyGradingVideo.vue"
import {FluencyGrade, FluencyRecording, FluencyRecordingSet} from "~app/fluency"
import {ref, unref} from "vue"
import axios from "axios"
import FluencyGradingComparisonVideo from "~components/teacher/fluency/FluencyGradingComparisonVideo.vue"
import {useFetcher} from "@/common/utils/fetch";

// "compare" could be factored out to a higher level, but it only pops up in one place
export type FluencyGraderState = "view" | "grade" | "compare" | "submitting" | "submitted"

const props = defineProps<{
  reading: FluencyRecording
  allReadings: FluencyRecordingSet  // used by 'compare' state to retrieve hot and cold reads
}>()
const emit = defineEmits(["graded", "abort", "error"])

const state = ref(initialState())
const grade = ref(initialGrade())

const submissionError = ref("")

async function deleteRecording() {
  if (!confirm("Delete this recording?  This action cannot be undone!")) return // TODO use a modal
  const endpoint = useFetcher(`/api/v2/recording_upload/${props.reading.id}`)
  await endpoint.delete()
  console.log("Delete confirmed", props.reading)
  emit("abort")
}

function initialGrade() {
  return props.reading.grade ?? newGrade()
}

function initialState(): FluencyGraderState {
  if (props.reading.classification === "stitched") return "compare"
  if (props.reading.grade) return "view"
  return "grade" // if no grade exists, automatically open a new grade in edit state
}

async function onSubmit() {
  const url = `/teacher/recordings/${props.reading.id}/grade`
  console.log("SUBMIT", {grade: unref(grade), reading: props.reading})
  try {
    state.value = 'submitting'
    const response = await axios.post(url, grade.value)
    grade.value = response.data
    state.value = 'submitted'
  } catch (error) {
    console.error("Error: ", error)
    submissionError.value = "Unable to submit grade, please try again"
  } finally {
    state.value = 'submitted' // should it go back to initialState?
    emit("graded", {reading: props.reading, grade: grade.value})
  }
}

async function reset() {
  grade.value = props.reading.grade ?? newGrade()
  state.value = initialState()
}

function onNewGrade() {
  grade.value = newGrade()
  state.value = "grade"
}

function newGrade(): FluencyGrade {
  return {
    accuracy: 0,
    expression: 0,
    feedback: "",
    selectedWordIds: [],
    wpm: props.reading.lyrics_only ? -1 : parseInt(props.reading.lesson.wordspermin),
  }
}
</script>

<template>
  <div class="fluency-grader">
    <FluencyGradingComparisonVideo
      v-if="state === 'compare'"
      :reading="reading"
      :cold-read="allReadings.cold_read"
      :hot-read="allReadings.hot_read"
      @abort="$emit('abort')"
    />
    <FluencyGradingVideo
      v-else
      :reading="reading"
      :grade.sync="grade"
      :state.sync="state"
      @submit="onSubmit"
      @new-grade="onNewGrade"
      @reset="reset"
      @abort="$emit('abort')"
      @delete="deleteRecording"
    />

    <FluencyGradingLyrics :reading="reading" :grade.sync="grade" :state="state" />
  </div>
</template>

<style lang="scss" scoped>
.fluency-grader {
  display: flex;
  flex-direction: row;
  height: 90vh;
  overflow-y: hidden;

  > * {
    flex: 1;
    height: 100%;
    overflow-y: auto;
    overflow-x: hidden;
    padding: 1rem;
    border-left: 1px solid #ccc;
  }

  > *:first-child {
    border: none;
  }
}
</style>
