<template>
    <div class="content-space-2 content-space-lg-3">
        <div class="flex-grow-1 mx-auto row" style="max-width: 28rem;">
            <div class="container">
                <fieldset>


                    <div class="text-center mb-5 mb-md-7">
                        <h1 class="h2">Welcome back</h1>
                        <p>Login With Your Teacher's or Parent's ID.</p>
                    </div>

                    <div class="form-bottom">
                        <div v-if="errorMessage" class="form-group text-center text-danger"><strong>{{ errorMessage
                        }}</strong></div>
                        <div v-if="loginUserNames.length" class="form-group">
                            <h4>Your password has been reset. Pick one of the following usernames:</h4>
                            <ul>
                                <li v-for="login in loginUserNames" :key="login">{{ login }}</li>
                            </ul>
                        </div>

                        <form @submit.prevent="loginSubmit()" novalidate>
                            <div class="mb-4">
                                <input type="text" class="form-control form-control-lg" required
                                    placeholder="Enter username" v-model="v$.form.username.$model" maxLength="45" />
                            </div>
                            <div class="mb-4">
                                <input type="password" class="js-toggle-password form-control form-control-lg" required
                                    placeholder="Enter password" v-model="v$.form.password.$model" maxLength="32"
                                    @keyup="testEnter($event, 'login')" />

                            </div>
                            <div class="d-grid mb-3">
                                <button class="btn btn-primary btn-lg" type="submit" :disabled="loggingIn">
                                    <span v-if="loggingIn">Logging in...</span>
                                    <span v-if="!loggingIn">Login to L2L</span>
                                </button>
                            </div>

                            <div class="text-danger" v-if="triedSubmit">
                                <div v-if="!v$.form.username.required">Username is required</div>
                                <div v-if="!v$.form.username.minLength">Username must have at least
                                    {{ v$.form.username.$params.minLength.min }} letters.</div>
                                <div v-if="!v$.form.password.required">Password is required</div>
                                <div v-if="!v$.form.password.minLength">Password must have at least
                                    {{ v$.form.password.$params.minLength.min }} letters.</div>
                            </div>
                        </form>

                        <div>
                            <social-login-buttons :is-register="false"></social-login-buttons>
                        </div>

                        <div>
                            <p></p>
                            <h5 class="text-center">
                                or
                            </h5>
                        </div>

                        <div>
                            <form method="post" @submit.prevent="emailLoginSubmit()" novalidate>
                                <div class="mb-4">
                                    <input type="email" name="email" class="form-control form-control-lg" required
                                        placeholder="Login with your email address" v-model="v$.emailLogin.email.$model"
                                        @keyup="testEnter($event, 'email')" />
                                </div>
                                <div class="d-grid mb-3">
                                    <button class="btn btn-primary btn-lg" type="submit" :disabled="sendingEmailLink">
                                        <span v-if="sendingEmailLink">Sending...</span>
                                        <span v-if="!sendingEmailLink">Send Login Link</span>
                                    </button>
                                </div>

                                <div class="text-danger" v-if="triedEmailSubmit">
                                    <div v-if="!v$.emailLogin.email.required">Email address is required.</div>
                                    <div v-if="!v$.emailLogin.email.email">A valid email address is required.</div>
                                </div>
                            </form>
                        </div>

                        <div class="text-center">
                            <a class="form-label-link" :href="lostPasswordLink" title="Forgot username or password">Forgot username or password ?</a>&nbsp;
                            <p></p>
                            <a class="link" href="/sign-up" title="Need An Account">Need An Account?</a>
                        </div>
                    </div>
                </fieldset>
            </div>
        </div>
    </div>
</template>

<script>

import useVuelidate from "@vuelidate/core"
import {email, maxLength, minLength, required} from "@vuelidate/validators"

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
    mounted() {
        console.log('Component mounted.')
    },
    props: {
        signupLink: {
            type: String,
            required: true
        },
        lostPasswordLink: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            errorMessage: '',
            loginUserNames: [],
            triedSubmit: false,
            loggingIn: false,
            triedEmailSubmit: false,
            sendingEmailLink: false,
            form: {
                username: '',
                password: ''
            },
            emailLogin: {
                email: ''
            }
        }
    },
    methods: {
        loginSubmit() {
            if (this.v$.form.$invalid) {
                this.triedSubmit = true;
            } else {
                // do it.
                // action is postback to this page.
                this.loggingIn = true;
                var email = this.emailLogin.email;
                axios.post('/login', { login: this.form.username, password: this.form.password })
                    .then((response) => response.data)
                    .then((data) => {
                        if (data.success) {
                            window.location = data.redirect;
                        }
                    }).catch((error) => {
                        try {
                            if (error.response.data.errors.login[0]) {
                                this.showError("Invalid username or password.  Please try again");
                            }
                        } catch {
                            this.showError(error.toString());
                        }
                    }).finally(() => {
                        this.loggingIn = false;
                    })
            }
        },
        showError(msg) {
            this.errorMessage = msg;
        },
        emailLoginSubmit() {
          if(this.v$.emailLogin.$invalid) {
            this.triedEmailSubmit = true;
          } else {
            this.sendingEmailLink = true;
            var email = this.emailLogin.email;
            axios.post('/teacher/send_email_link', { email: email })
            .then((response) => response.data)
            .then((data) => {
              if(data.error) {
                this.showError(data.error);
              } else {
                this.showError("An email was sent to "+email+" with a link you can click to login.  Please check your email.");
                this.emailLogin.email = ''; // clear it.
              }
            }).catch((error) => {
              console.error(error);
            }).finally(() => {
              this.sendingEmailLink = false;
            })
          }
        },
        testEnter(event, which) {
            if (event.keyCode == 13) {
                if (which == 'login') {
                    this.loginSubmit();
                } else if (which == 'email') {
                    this.emailLoginSubmit();
                }
            }
        }
    },
    validations: {
        form: {
            username: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(45),
            },
            password: {
                required,
                minLength: minLength(1),
                maxLength: maxLength(32)
            }
        },
        emailLogin: {
            email: {
                required,
                email
            }
        }
    }
}
</script>
