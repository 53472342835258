<script setup lang="ts">
import GradeLevelTagLabel from "~components/GradeLevelTagLabel.vue"
import {computed, ref} from "vue"
import {LessonRow, StudentRow} from "~components/teacher/assign/assignment"
import {useVModels} from "@vueuse/core"
import StudentTag from "~components/teacher/assign/StudentTag.vue"
import {countAndPluralize} from "../../../common/utils/english"

// The only logo that works
const HARDWIRED_BENCHMARK_LOGO = "https://s3.amazonaws.com/media.lyrics2learn.com/production/lessons/204/benchmark.png"


const props = defineProps<{
  students: StudentRow[]
  lessons: DecoratedLessonRow[]
  assign: LessonRow[]
  remove: LessonRow[]
}>()

const emit = defineEmits([])

type DecoratedLessonRow = LessonRow & {reading_level: string}


const {assign, remove} = useVModels(props, emit)

function inAssign(lesson: LessonRow): boolean {
  return assign.value.some(l => l.id === lesson.id)
}

function inRemove(lesson: LessonRow): boolean {
  return remove.value.some(l => l.id === lesson.id)
}

function setAssign(lesson: LessonRow) {
  assign.value = [...assign.value.filter(lsn => lsn.id !== lesson.id), lesson]
  remove.value = [...remove.value.filter(lsn => lsn.id !== lesson.id)]
}

function setRemove(lesson: LessonRow) {
  remove.value = [...remove.value.filter(lsn => lsn.id !== lesson.id), lesson]
  assign.value = [...assign.value.filter(lsn => lsn.id !== lesson.id)]
}

const columns = [
  {label: ""},
  {label: "Image", field: "", sortable: false},
  {label: "Title", field: "title"},
  {label: "Subject", field: "subject"},
  {label: "Genre", field: "storygenre"},
  {label: "Lexile", field: "lexile", sortable: true},
  {label: "WPM", field: "wpm", sortable: false},
  {label: "Worksheet", field: "worksheet", sortable: false},
]

const filter = ref("")

function selectRow(lesson: LessonRow) {
  const currentState = checkboxState(lesson) ?? true // indeterminate is treated as checked
  currentState ? setRemove(lesson) : setAssign(lesson)
}

function studentsAssigned(lesson: LessonRow): StudentRow[] {
  return props.students.filter(student => student.assigned_lessons.some(l => l.id === lesson.id))
}

function studentsCompleted(lesson: LessonRow): StudentRow[] {
  return props.students.filter(student => student.completed_lessons.some(l => l.id === lesson.id))
}

function checkboxState(lesson: LessonRow): boolean | null {
  if (inAssign(lesson)) return true
  if (inRemove(lesson)) return false
  const ids = new Set(studentsAssigned(lesson).concat(studentsCompleted(lesson)).map(lesson => lesson.id))
  if (ids.size === props.students.length) return true // assigned or completed for all students
  if (!ids.size) return false                         // not assigned or completed by any student
  return null                                         // indeterminate
}

const allGradeLevels = computed<string[]>(() => Array.from(new Set(props.lessons.map(lesson => lesson.reading_level))))
const initialSelectedGrades = computed(() => Object.fromEntries(allGradeLevels.value.map(level => [level, false])))
const selectedGrades = ref<Record<string, boolean>>(initialSelectedGrades.value)
const allSelectedGrades = computed(() => Object.values(selectedGrades.value).filter(x => x))

const displayedLessons = computed(() =>
  props.lessons
    .filter(lesson => allSelectedGrades.value.length === 0 || selectedGrades.value[lesson.reading_level])
    .map(lesson => ({...lesson, logo: fixedBenchmarkLogo(lesson)})),
)

function fixedBenchmarkLogo(lesson: DecoratedLessonRow) {
  return /benchmarks/i.test(lesson.reading_level) ? HARDWIRED_BENCHMARK_LOGO : lesson.logo
}

function onSelectGradeLevel(level: string) {
  selectedGrades.value[level] = !selectedGrades.value[level]
}

function resetSelectedGrades() {
  selectedGrades.value = Object.fromEntries(allGradeLevels.value.map(level => [level, false]))
}

</script>

<template>
  <div class="lesson-table">
    <div class="table table-striped table-responsive table-bordered">
      <section class="controls">
        <b-input v-model="filter" placeholder="Search Lessons..." class="filter-input" />
        <b-dropdown text="Filter Grade Levels..." class="btn btn-primary grade-level-dropdown">
          <b-dropdown-item
            v-for="level in allGradeLevels"
            :key="level"
            @click="onSelectGradeLevel(level)"
          >
            <div class="d-flex align-items-center">
              <b-checkbox :checked="selectedGrades[level]" />
              <GradeLevelTagLabel :grade="level" />
            </div>
          </b-dropdown-item>
        </b-dropdown>
        <b-button v-if="allSelectedGrades.length" variant="soft-secondary" @click="resetSelectedGrades">
          Clear filters for {{ countAndPluralize(allSelectedGrades.length, "grade level") }}
        </b-button>

      </section>

      <datatable :columns="columns" :data="displayedLessons" :filter-by="filter" v-slot="{row: lesson}">
        <tr>
          <td class="row-check">
            <label class="checkbox-wrapper">
              <b-check
                :checked="checkboxState(lesson)"
                :indeterminate="checkboxState(lesson) === null"
                @change="selectRow(lesson)"
              />
            </label>
          </td>
          <td class="image">
            <!--
              click to select seems obvious, but I want these to be a richer LessonGadget that pops up a mini info window,
              so I don't want to set up any expectations on click behavior that I'll have to change later.

              <div class="m-auto d-flex flex-column align-items-center cursor-pointer" @click="selectRow(lesson)">
            -->
            <div class="m-auto d-flex flex-column align-items-center">
              <img :src="lesson.logo" class="img-responsive" />
              <GradeLevelTagLabel v-if="lesson.reading_level" :grade="lesson.reading_level" />
            </div>
          </td>
          <td class="title" style="width: 24rem">
            {{ lesson.title }}
            <!--<pre>{{ lesson }}</pre>-->
            <br>
            <div class="student-lesson-status">
              <section v-if="studentsAssigned(lesson).length">
                <header>
                  Assigned
                </header>
                <ul>
                  <li v-for="student in studentsAssigned(lesson)" :key="student.id" class="student-name">
                    <StudentTag :student="student" size="sm" />
                  </li>
                </ul>
              </section>

              <section v-if="studentsCompleted(lesson).length">
                <header>Completed</header>
                <ul class="tags">
                  <li v-for="student in studentsCompleted(lesson)" :key="student.id" class="student-name">
                    <StudentTag :student="student" size="sm" />
                  </li>
                </ul>
              </section>
            </div>

          </td>
          <td class="subject">{{ lesson.subject }}</td>
          <td class="genre">{{ lesson.storygenre }}</td>
          <td class="lexile">{{ lesson.lexile }}</td>
          <td class="wpm">{{ lesson.wpm }}</td>
          <td>
            <a :href="lesson.worksheet" target="_blank">
              <img src="/images/pdf.png" />
            </a>
          </td>
        </tr>
      </datatable>
    </div>
  </div>
</template>

<style lang="scss" scoped>

.lesson-table {
  .controls {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    * {
      max-height: 2.5rem;
    }

  }

}

.filter-input {
  max-width: 24rem;
  border: 2px solid #ccc;
  margin: 0.5rem;
}

.table {
  overflow: visible !important;

  img {
    max-width: 80px;
  }

  td {
    vertical-align: middle;
  }
}

.checkbox-wrapper {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
}

.lesson-tile {
  &.selected {
    border-radius: 10px;
    background-color: rgba(0, 0, 255, 0.25);
  }

  img {
    margin: 0 auto;
  }

  .description {
    margin-bottom: 10px;
    text-align: center;
    min-height: 100px;
    font-size: 1em;

    h3 {
      font-size: 1.1em !important;
      font-weight: bold;
      height: 2em;
    }

    .label {
      line-height: 15px;
    }
  }
}

.lesson-completed, .lesson-assigned {
  font-size: 0.85em;
  font-style: italic;
}

.student-lesson-status {
  font-size: 0.85em;
  font-style: italic;

  header {
    font-size: 0.85rem;
    font-weight: bold;
  }

  ul {
    list-style-type: none;
    columns: 4;

    li {
      margin-left: 0.5rem;
      white-space: nowrap;
    }
  }

  .showall-toggle {
    font-size: 0.85rem;
    padding: 0.25rem;
    border-radius: 5px;
    margin: 0.5rem;
    background-color: #88bbff;
  }
}

td {
  &.title {
    min-width: 20rem;
  }

  &.genre {
    white-space: nowrap;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.grade-level-dropdown {
  padding: 0;
  max-height: 2.4rem;

  // Dropdowns are pretty damaged by the new theme.  TODO: move these fixes to the global style.
  &:deep(ul) {
    //border: 5px solid red !important /*debug*/;
    background-color: #eee;
    padding: 0;
  }
}
</style>
