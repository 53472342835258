<template>
  <div class="card bg-light">

    <div v-if="loading"><h3>Loading Students...</h3></div>

    <div v-else-if="!students">
      No students in class
    </div>

    <div v-else style="padding: 10px;">

      <div class="alert alert-warning">
          <strong>Please Note:</strong>
          Lyrics2Learn does not and cannot sell parent emails to third parties
          or use them for marketing purposes.
      </div>


      <b-modal v-model="showSampleReport" size="lg">
        <img src="/images/sample_parent_report.png" style="max-width: 100%">
      </b-modal>


      <div style="margin: 10px 0; padding: 5px">
        <div class="pull-left">
          <input
            class="form-control student-search" v-model="filter"
            placeholder="Search for a student..."
          >
        </div>
        <div style="clear:both"></div>
      </div>

      <div class="table table-striped table-responsive table-striped text-center">
        <datatable :columns="columns" :data="students" :filter-by="filter" :footer="true">

          <template v-slot="{ row }">
            <tr>

              <td class="name-column" scope="col">{{row.firstname}}</td>
              <td class="name-column w-25" scope="col">{{row.lastname}}</td>

              <td scope="col">
                <input
                  type="email"
                  class="form-control live-edit-input"
                  v-model="row.parent_email"
                  @keyup.enter="checkChange('parent_email', row)"
                  @blur="checkChange('parent_email', row)"
                >
              </td>

              <td scope="col" class="resubscribe-confirmation" v-if="row._confirm_resubscribe">
                <banner type="warning">You are about to change the subscription settings on a user who has previously unsubscribed.
                  <br>
                  <button class="btn btn-primary" @click="confirmResubscribe(row)">Confirm</button>
                  <button class="btn btn-secondary" @click="cancelResubscribe(row)">Cancel</button>
                </banner>
              </td>

              <td scope="col" v-else>
                <!-- TODO: refactor these two checkbox divs into subscription management widgets -->
                <div class="checkbox">
                    <span v-if="!row.send_weekly_report_unsubscribed">
                      <label>
                        <input
                          type="checkbox"
                          class="live-edit-input"
                          v-model="row.send_weekly_report"
                          @change="checkChange('send_weekly_report', row)"
                        >
                        <whats-this text="By checking this box, the student’s parent will automatically receive an emailed progress report every week."></whats-this>
                        Automated Weekly Reports
                      </label>
                  </span>
                  <span v-else @click="showConfirmResubscribe(row, 'send_weekly_report')">
                    <whats-this text="The parent has previously unsubscribed from the list by clicking an unsubscribe link.  Click to unlock this setting.">
                      <span>
                        <i class="bi bi-exclamation-triangle" style="color: darkred; width:2.85em;"></i>
                        Automated Weekly Reports <span class="unsub-date">[Unsubscribed {{dateToDMY(row.send_weekly_report_unsubscribed)}}]</span>
                      </span>
                    </whats-this>
                  </span>
                </div>

                <!-- TODO: refactor these two checkbox divs into subscription management widgets -->
                <!-- LL2L-667: manual emails can no longer be opted out -->
                <div v-if="false" class="checkbox">
                    <span v-if="!row.send_parent_emails_unsubscribed">
                      <label>
                        <input
                          type="checkbox"
                          class="live-edit-input"
                          v-model="row.send_parent_emails"
                          @change="checkChange('send_parent_emails', row)"
                        >
                        <whats-this text="This is a manual email that can be used to send parents student log in information and tutorial videos or class announcements. You will be able to preview and edit this message before it goes out."></whats-this>
                        Manual Custom Emails
                      </label>
                    </span>
                    <span v-else @click="showConfirmResubscribe(row, 'send_parent_emails')">
                      <whats-this text="The parent has previously unsubscribed from the list by clicking an unsubscribe link. Click to unlock this setting.">
                        <span>
                        <i class="bi bi-exclamation-triangle" style="color: darkred; width:2.85em;"></i>
                        Manual Custom Emails <span class="unsub-date">[Unsubscribed {{dateToDMY(row.send_parent_emails_unsubscribed)}}]</span>
                        </span>
                      </whats-this>
                    </span>
                </div>
              </td>

            </tr>

          </template>
        </datatable>

        <a class="btn btn-info m-3" @click="showSampleReport = true">View a Sample Weekly Report</a>
        <a class="btn btn-success m-3" :href="parentConnectUrl" target=_blank>Home Login Email</a>

      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>

  input[type="email"] {
    min-width: 12em;
  }

  .unsub-date {
    font-weight: bold;
    font-style: italic;
    color: darkred;
  }

  div.checkbox {
    white-space: nowrap;
  }

  input[type=checkbox].live-edit-input {
    height: 1.5em !important;
    width: 1.5em !important;
    display: inline;
    vertical-align: middle;
  }

  a.btn {
    color: white;
  }

  .table {
    margin-bottom: 5px;
  }

  td {
    vertical-align: middle;
    margin: 0;
  }

  .name-column {
    max-width: 5em;
  }

  .checkbox {
    .live-edit-input {
      display: block;
      margin-left: 0;
      position: initial;
    }
  }

  table {
    font-size: 0.8em;
    margin-bottom: 0;

    .incomplete, .error {
      border: 1px solid red;
      background-color: lightpink !important;

      &:hover {
        background-color: pink;
      }
    }

    .action-buttons {
      display: flex;
      justify-content: center;
    }

  }

  .form-control {
    min-height: inherit;
  }

  .tip {
    font-size: small;
    font-style: italic;
  }

  .resubscribe-confirmation {
    text-align: center;
    font-weight: bold;
  }

</style>

<script>
  import Vue from "vue";
  import WhatsThis from "./widgets/WhatsThis.vue";
  import {dateToDMY} from "../common/utils";
  import Banner from "./widgets/Banner.vue";

  // TODO: refactor these into a reusable widget
  const parentEmailTip = Vue.component('ParentEmails', {
    components: {WhatsThis},
    template:
        `
      <div>
        <whats-this text="These controls allow you to choose which emails to send to your students' parents."></whats-this>
        Personalized Parent Emails
      </div>`,
  });

  export default {
    components: {WhatsThis, Banner},

    mounted() {
      this.loadStudents();
    },

    data() {
      return {

        loading: false,
        columns: [
          {label: 'First Name', field: 'firstname'},
          {label: 'Last Name', field: 'lastname'},
          {label: 'Parent Email Address', field: 'parent_email'},
          {
            label: 'Personalized Parent Emails',
            field: 'personalized_parent_emails',
            sortable: false,
            headerComponent: parentEmailTip,
            // footerComponent: this.sampleButton,
          },
        ],
        students: [],
        newids: [],
        original: [],
        filter: '',
        savingCount: 0,
        errors: [],
        changedFields: {},  // Different than orig check in that it stays latched true

        showSampleReport: false,

        updatedOne: false,    // latches true when a student is updated

        parentConnectUrl: '/teacher/parentConnect/home',
      }
    },

    validations: {},

    computed: {
      sampleButton() {
        const onClick = () => this.showSampleReport = true
        return Vue.component('SampleButton', {
          template: `<a class="btn btn-success" @click="onClick">View a Sample Weekly Report</a>`
        })
      },

      emailButton() {
        const url = this.parentConnectUrl
        return Vue.component('EmailButton', {
          template: `<a :href="url" target=_blank class="btn btn-success">Write a Custom Email</a>`
        });
      },
    },



    methods: {
      dateToDMY,

      checkChange(fieldName, row) {
        var changed = false;
        var model = this;

        let old = null

        this.original.forEach(function (item, index) {
          if (item.id == row.id) {
            if (item.parent_email !== row.parent_email
              || item.send_weekly_report !== row.send_weekly_report
              || item.send_parent_emails !== row.send_parent_emails
            ) {
              // copy the "new" original
              old = item
              model.original[index] = JSON.parse(JSON.stringify(row));
              changed = true;
            }
          }
        });

        if (changed) {
          this.onChange(fieldName, row, old);
          this.saveRow(row);
        }
      },

      onChange(fieldName, row, old) {
        // console.log('CHANGED:', fieldName, row, old)
        if (
          fieldName === 'parent_email'
          && !this.isChanged(fieldName, row)
          && !old.parent_email
          && row.parent_email.match(/^\S+@[-.\w]+\.[-\w+]+$/)  // a very weak stab at validation
        ) {
          this.autoSubscribe(row)
        }
        this.setChanged(fieldName, row)
      },

      setChanged(fieldName, row) {
        this.changedFields[`${row.id}_${fieldName}`] = true
      },

      isChanged(fieldName, row) {
        return this.changedFields[`${row.id}_${fieldName}`]
      },

      autoSubscribe(row) {
        console.log("AUTOSUBSCRIBE:", row)
        row.send_weekly_report = true
        row.send_parent_emails = true
      },

      saveRow(row) {
        this.serializeRow(row, 'save');
      },

      serializeRow(row, verb) {
        let entry = JSON.parse(JSON.stringify(row));

        this.savingCount += 1;

        axios.post('/teacher/students', {verb: verb, student: entry})
          .then((response) => response.data)
          .then((data) => {
            this.removeErrorForStudentId(entry.id);
            this.updatedOne = true;
          }).catch((error) => {
          if (error.response && error.response.data && error.response.data.errors) {
            // and add it again...
            this.removeErrorForStudentId(entry.id);
            this.errors.push({
              id: entry.id,
              errors: error.response.data.errors
            });
          } else {
            this.errorMessage = error.message;
          }
        }).finally(() => {
          this.savingCount -= 1;
        })
      },

      loadStudents() {
        this.loading = true;
        axios.post('/teacher/students', {verb: 'list'})
          .then((response) => response.data)
          .then((data) => {
            this.students = data
            this.original = JSON.parse(JSON.stringify(data));
          }).catch((error) => {
          this.errorMessage = error;
        }).finally(() => {
          this.loading = false;
        })
      },

      removeErrorForStudentId(id) {
        this.errors = this.errors.filter((error) => {
          return (error.id != id);
        });
      },
      hasError(row, field) {
        var result = false;
        for (var ii = 0; ii < this.errors.length; ii++) {
          if (this.errors[ii].id == row.id) {
            let fieldname = `student.${field}`;
            if (this.errors[ii].errors[fieldname]) {
              result = this.errors[ii].errors[fieldname]; // fixme adjust.
            }
            break;
          }
        }
        return result;
      },


      showConfirmResubscribe(row, list) {
        // If we could use DialogsMixin, we could just do this, but it only works in the admin UI
        // if (await this.confirmDialog(message)) {
        //   row[list + '_unsubscribed'] = null;
        // }

        // So instead we get creative with the table row and render the confirm in
        // the row itself. I think it looks a lot nicer than a modal dialog anyway.

        console.log("CONFIRM", row, list);
        Vue.set(row, '_confirm_resubscribe', list);

      },

      confirmResubscribe(row) {
        const list = row._confirm_resubscribe
        row._confirm_resubscribe = null
        row[`${list}_unsubscribed`] = null; // we get away with this because it never goes up to the backend
      },

      cancelResubscribe(row) {
        const list = row._confirm_resubscribe
        row._confirm_resubscribe = null
      }

    }
  }
</script>
