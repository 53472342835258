<script>
import {debounceAsyncValidator} from "@/common/utils/index.js"
import useVuelidate from "@vuelidate/core"
import {helpers, required} from "@vuelidate/validators"

const {withAsync, withMessage} = helpers

// move to provider eventually
async function userExists(loginName) {
  if (loginName === '') return true
  const response = await fetch(`/support/user/${loginName}/exists`)
  return Boolean(await response.json())
}

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  data() {
    return {
      userType: '',
      firstName: '',
      lastName: '',
      userName: '',
      email: '',
      password: '',
      gradeLevel: '',
      howDidYouFindUs: '',

      showPassword: false,
      schoolZipCode: '',
      selectedSchool: '',

      schoolsLoaded: false,
      loadingSchools: false,
      availableSchools: [],

      errorMessage: '',
      submitting: false,

      // constant data

      gradeLevels: [
        "Kindergarten",
        "1st Grade",
        "2nd grade",
        "3rd grade",
        "4th grade",
        "5th grade",
        "6th grade or higher",
        "Multiple grade levels",
      ],

      referralTypes: [
        "Pinterest",
        "A Friend Referred Me",
        "Search Engine",
        "Facebook",
        "Twitter",
        "Instagram",
        "Other",
        "Unknown",
      ],
    }
  },

  methods: {
    togglePasswordVisibility() {
      this.showPassword = !this.showPassword
    },

    onSubmit() {
      const payload = {
        userType: this.userType,
        firstName: this.firstName,
        lastName: this.lastName,
        userName: this.userName,
        email: this.email,
        password: this.password,
        gradeLevel: this.gradeLevel,
        howDidYouFindUs: this.howDidYouFindUs,
        selectedSchool: this.selectedSchool,
      }

      this.submitting = true
      axios.post('/teacher/signup', payload)
        .then((response) => response.data)
        .then((data) => {
          if (data["success"]) {
            window.location = data["redirect"]
          } else {
            this.errorMessage = data["message"]
          }
        }).catch((error) => {
        try {
          if (error.response.data.errors) {
            let msg = ''
            for (let k in error.response.data.errors) {
              msg += error.response.data.errors[k][0]
            }
            this.errorMessage = msg
          }
        } catch {
          this.errorMessage = error.toString()
        }
      }).finally(() => {
        this.submitting = false
      })
    },

    onZipChange() {
      if (this.schoolZipCode.length === 5) {
        this.lookupByZip(this.schoolZipCode)
      }
    },

    async lookupByZip(zip) {
      this.loadingSchools = true
      const response = await fetch(`/support/schoolsByZip/${zip}`)
      this.availableSchools = await response.json()
      this.loadingSchools = false
      this.schoolsLoaded = true
    },
  },

  validations() {
    return {
      userName: {
        required,
        isUnique: withMessage(
          'This username has already been taken.',
          withAsync(debounceAsyncValidator(function (value, debounce) {
            if (!value) return true
            // async validator.  don't try to refactor the promise chain, it depends on being two steps.
            return debounce()
              .then(() => userExists(value))
              .then((exists) => !exists)
              .catch(() => false) // could be caused by either rest api failure or by debounce
          }, 500)),
        ),
      },
    }
  },
}
</script>

<template>
  <div class="card card-lg">
    <div class="card-body">
      <h3 class="text-center mb-4">
        We fuse cool music with educational stories to improve reading skills. Kids love it.
      </h3>

      <form method="post" name="frmSignUp" @submit.prevent="onSubmit">
        <div class="alert alert-danger" v-if="errorMessage">
          <b>Error: </b>{{ errorMessage }}
        </div>

        <div class="row gx-3">
          <div class="col-sm-6">
            <label class="form-label" for="firstname">First name</label>
            <input
              type="text"
              required
              maxlength="45"
              minlength="2"
              class="form-control"
              name="firstName"
              v-model.trim="firstName"
            >
          </div>

          <div class="col-sm-6">
            <label class="form-label" for="lastname">Last name</label>
            <input
              required
              maxlength="45"
              type="text"
              class="form-control"
              name="lastName"
              v-model.trim="lastName"
            >
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label" for="email">Email address</label>
          <input
            type="email"
            class="form-control"
            name="email"
            v-model="email"
            required
          >
        </div>

        <div class="mb-2">
          <label class="form-label" for="login">Username</label>
          <input
            type="text"
            v-model="v$.userName.$model"
            autocapitalize="none"
            autocomplete="off"
            class="form-control"
            maxlength="20"
            minlength="4"
            required
          />
          <div v-if="!v$.userName.$pending">
            <div class="error" v-for="error in v$.userName.$errors" :key="error.$uid">{{ error.$message }}</div>
          </div>
        </div>

        <div class="mb-2">
          <label class="form-label" for="txt_password">Password</label>
          <div class="input-group">
            <input
              :type="showPassword ? 'text' : 'password'"
              class="form-control"
              v-model="password"
              minlength="6"
              maxlength="32"
              required
            />
            <span
              class="eye-icon input-group-text"
              @click="togglePasswordVisibility"
              :title="showPassword ? 'Hide password' : 'Show password'"
            >
                <i :class="showPassword ? 'bi bi-eye-slash' : 'bi bi-eye'" class="text-body ml-1"></i>
            </span>
          </div>
        </div>

        <div class="row gx-3">
          <div class="col-sm-6">
            <div class="mb-2">
              <label class="form-label" for="usertype">User Type</label>
              <select id="usertype" v-model="userType" class="form-select" name="usertype" required>
                <option value="" selected>--Select user type--</option>
                <option value="teacher">Teacher</option>
                <option value="parent">Parent</option>
              </select>
            </div>
          </div>

          <div class="col-sm-6">
            <div class="mb-2">
              <label class="form-label" for="inf_custom_GradeLevelofStudents">
                Grade Level of Students
                <i
                  class="bi-question-circle text-body ml-1"
                  title="NOTE: This question will not limit access to content."
                ></i>
              </label>
              <select id="gradeLevel" class="form-select" v-model="gradeLevel" required>
                <option value="">--Select grade level--</option>
                <option v-for="(grade, index) in gradeLevels" :value="grade" :key="index">{{ grade }}</option>
              </select>
            </div>
          </div>
        </div>

        <div v-if="userType === 'teacher'">
          <div class="row gx-3">
            <div class="col-sm-6">
              <div class="mb-2">
                <label class="form-label" for="txt_password">School ZIP (5 digits)</label>
                <input type="number" class="form-control" @input="onZipChange" v-model="schoolZipCode" maxlength="5">
                <p v-show="loadingSchools"><strong>Loading Schools...</strong></p>
              </div>
            </div>

            <div class="col-sm-6">
              <div class="mb-2">
                <label class="form-label" for="school">Choose Your School</label>
                <select id="school" name="school" class="form-select" v-model="selectedSchool" required>
                  <option value="">Choose Your School</option>
                  <option v-for="school in availableSchools" :key="school.id" :value="school.id">
                    {{ school.name }}
                  </option>
                  <option value="1">My School Is Not Listed</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <div class="mb-4">
          <label class="form-label" for="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn">
            How Did You Find Out About Us?
          </label>
          <select
            class="form-select"
            id="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
            name="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
            v-model="howDidYouFindUs"
            required
          >
            <option value="">--Select a choice--</option>
            <option v-for="(how, index) in referralTypes" :value="how" :key="index">{{ how }}</option>
          </select>
        </div>

        <button type="submit" id="btnSubmit" class="btn btn-primary btn-lg d-block w-100">
          Start Your Free Trial
        </button>
      </form>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@import "../../sass/colorscheme";

form {
  .error {
    color: $cs-l2l-red;
  }
}

.eye-icon {
  max-height: 38px;
  border-left: 0;
}
</style>
