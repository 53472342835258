<script>
import FluencyGradeButton from "./FluencyGradeButton.vue"

export default {
  components: {FluencyGradeButton},

  props: {
    videos: {type: Array, required: true},
  },

  emits: ["graded"],
}
</script>

<template>
  <datatable
    :columns="[{label: 'First Name', field: 'firstname'},
               {label: 'Last Name', field: 'lastname'},
               {label: 'New Recordings', field: 'new_recordings.length'},
               {label: '', field: '', sortable: false}]"
    :data="videos"
    class="table table-striped table-responsive table-bordered"
    v-slot="{row}"
  >
    <tr>
      <td>{{ row.firstname }}</td>
      <td>{{ row.lastname }}</td>
      <td>{{ row.new_recordings.length }}</td>
      <td>
        <FluencyGradeButton
          v-if="row.latest_ungraded_id"
          :video-id="row.latest_ungraded_id"
          label="Grade"
          variant="primary"
          @graded="$emit('graded', row.latest_ungraded_id)"
        />
        <!-- NOTE: no delete button available in this table, as the teacher wouldn't know which lesson they're deleting -->
        <div v-else>Nothing to grade</div>
      </td>
    </tr>
  </datatable>
</template>
