<script setup lang="ts">
import {computed, ref} from "vue"
import {
  AssignmentInfo,
  LessonRow,
  StudentRow,
  useLessonsByLevel,
  usePostAssignments,
  useStudentRows,
} from "~components/teacher/assign/assignment"
import AssignmentStudentPicker from "~components/teacher/assign/AssignmentStudentPicker.vue"
import AssignmentLessonPicker from "~components/teacher/assign/AssignmentLessonPicker.vue"
import {countAndPluralize} from "@/common/utils/english"
import StudentTag from "~components/teacher/assign/StudentTag.vue"
import LessonTag from "~components/teacher/assign/LessonTag.vue"

const {students, fetcher: studentsFetcher} = useStudentRows()
const selectedStudents = ref<StudentRow[]>([])

const assignSubmission = ref<AssignmentInfo>({students: [], assign: [], remove: []})
const {fetcher: postAssignments} = usePostAssignments(assignSubmission)
postAssignments.onFetchResponse(onSubmitDone)
postAssignments.onFetchFinally(reset)

const {lessonsByLevel} = useLessonsByLevel()
const lessons = computed(() => Object.entries(lessonsByLevel.value)
    .flatMap(([reading_level, lessons]) => lessons.map(lesson => ({...lesson, reading_level}))),
)

type AssignmentPageMode = "students" | "lessons" | "submitting"
const mode = ref<AssignmentPageMode>("students")

const errorMessage = ref("")

const studentTabTitle = computed(() => mode.value === "students" ? "Step 1: Select Students" : "Back to Student Selection")
const lessonTabTitle = computed(() => mode.value === "lessons" ? `Step 2: Assign Lessons to ${selectedStudents.value.length} Students` : " ")

function submit(input: AssignmentInfo) {
  console.log("SUBMIT", input)
  mode.value = "submitting"
  assignSubmission.value = input  // triggers a POST automatically
}

async function onSubmitDone(response: any) {
  console.log("SUBMIT RESPONSE", response.data)
  await studentsFetcher.execute()
}

function reset() {
  mode.value = "students"
  selectedStudents.value = []
  showConfirmRemove.value = false
}

const selectedStudentLessons = computed(() => {
  const lessons = {} as Record<number, LessonRow>
  selectedStudents.value
    .flatMap(s => s.assigned_lessons)
    .forEach(lesson => lessons[lesson.id] = lesson)
  return Object.values(lessons)
})

function onClickClear() {
  showConfirmRemove.value = true
  pendingRemove.value = {students: selectedStudents.value, assign: [], remove: selectedStudentLessons.value}
}

const showConfirmRemove = ref(false)
const pendingRemove = ref<AssignmentInfo | null>(null)

function onConfirmRemove() {
  const submission = pendingRemove.value
  if (!submission) throw new Error("IMPOSSIBLE: pendingRemove is null")
  submit(submission)
}

function onClickLesson(student: StudentRow, lesson: LessonRow) {
  showConfirmRemove.value = true
  pendingRemove.value = {students: [student], assign: [], remove: [lesson]}
}

const showConfirmAssign = ref(false)
const pendingAssign = ref<AssignmentInfo | null>(null)

function onConfirmAssign() {
  const submission = pendingAssign.value
  if (!submission) throw new Error("IMPOSSIBLE: pendingAssign is null")
  submit(submission)
}

function onClickUnassigned(student: StudentRow, lesson: LessonRow) {
  showConfirmAssign.value = true
  pendingAssign.value = {students: [student], assign: [lesson], remove: [], preserve_progress: true}
}
</script>

<template>
  <div class="assign-lessons-page">
    <h1 class="text-primary">Assign Lessons</h1>

    <b-alert :show="!!errorMessage" variant="danger">
      {{ errorMessage }}
    </b-alert>

    <b-overlay :show="mode === 'submitting'">
      <b-tabs lazy>
        <b-tab :title="studentTabTitle" @click="mode = 'students'">
          <template v-if="mode === 'lessons'" #title>
            <div class="title-slot">
              <b-button variant="secondary" class="tab-button">❮ Back to Student Selection</b-button>
            </div>
          </template>
          <AssignmentStudentPicker
            v-if="mode === 'students' || mode === 'submitting'"
            :active="mode === 'students'"
            :all-students="students"
            :selected-students.sync="selectedStudents"
            @click-lesson="onClickLesson"
            @click-unassigned-lesson="onClickUnassigned"
            @assign-lessons="mode = 'lessons'"
            @submit="submit"
          />
        </b-tab>

        <b-tab :title="lessonTabTitle" :active="mode === 'lessons'" :disabled="mode !== 'lessons'">
          <AssignmentLessonPicker
            v-if="mode === 'lessons'"
            :lessons="lessons"
            :selected-students.sync="selectedStudents"
            @back="mode = 'students'"
            @submit="submit"
          />
        </b-tab>

        <template v-if="mode === 'students' && selectedStudents.length" #tabs-end>
          <b-button variant="primary" size="sm" @click="mode = 'lessons'">
            Assign Lessons to {{ countAndPluralize(selectedStudents.length, "Student") }}
          </b-button>

          <b-button variant="danger" size="sm" @click="onClickClear">
            Remove Lessons from {{ countAndPluralize(selectedStudents.length, "Student") }}
          </b-button>
        </template>

        <b-modal v-model="showConfirmRemove" hide-header @ok="onConfirmRemove">
          <div v-if="pendingRemove"><!-- always true at this point but vue-tsc does't know that -->
            Unassigning lessons for students:
            <ul class="tags">
              <li v-for="student in pendingRemove.students" :key="student.id">
                <StudentTag :student="student" show-grade-level />
              </li>
            </ul>

            <br>

            Confirm unassigning lessons:
            <ul class="tags">
              <li v-for="lesson in pendingRemove.remove" :key="lesson.id">
                <LessonTag :lesson="lesson" />
              </li>
            </ul>
          </div>
        </b-modal>

        <b-modal v-model="showConfirmAssign" hide-header @ok="onConfirmAssign">
          <div v-if="pendingAssign"><!-- always true at this point but vue-tsc does't know that -->
            Marking auto-assigned lesson as teacher-assigned for students:
            <ul class="tags">
              <li v-for="student in pendingAssign.students" :key="student.id">
                <StudentTag :student="student" show-grade-level/>
              </li>
            </ul>

            <br>

            Confirm assigning lessons:
            <ul class="tags">
              <li v-for="lesson in pendingAssign.assign" :key="lesson.id">
                <LessonTag :lesson="lesson"/>
              </li>
            </ul>
          </div>
        </b-modal>

      </b-tabs>
    </b-overlay>
  </div>
</template>

<style scoped lang="scss">
:deep(li.nav-item) {
  max-height: 4rem;

  .title-slot {
    margin-top: -1.25rem;
  }

  .nav-link {
    border-color: transparent !important;
  }
}

button {
  margin: 0.5rem;
}

ul.tags {
  list-style-type: none;
  columns: 2;
}

</style>
