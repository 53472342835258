<template>
  <div>
	<div class="alert alert-soft-info">
    We offer special volume pricing for school and district licenses,
    including live webinars and in-person training.
		<button
			class="btn btn-link"
			@click="showDialog = true"
			style="padding: 0; font-weight: inherit; vertical-align: inherit"
		>
			Receive a quote here
		</button>
    or email <a href="mailto:admin@lyrics2learn.com">admin@lyrics2learn.com</a>
		for more info.
	</div>

    <b-modal
      v-model="showDialog"
      title="Contact Us To Get Your District Started with Lyrics2Learn"
      hide-footer
    >
      <teacher-quote-form class="text-start" modal></teacher-quote-form>
    </b-modal>

  </div>

</template>

<script>
  export default {
    data() {
      return {
        showDialog: false,
      };
    },

    methods: {
      closeDialog() {
        this.showDialog = false
      },
    },

  }
</script>
