<script>

import Vue from "vue"
import {Promised} from "vue-promised"
import useVuelidate from "@vuelidate/core"
import {email, required} from "@vuelidate/validators"

const BROWSE_ENDPOINT = '/school_admin/teachers_json'
  const CREATE_ENDPOINT = '/school_admin/new_teacher'

  export default {
    setup() {
      return {
        v$: useVuelidate(),
      }
    },
    name: "TeacherList",
    components: {Promised},
    props: {},

    data() {
      return {

        teachers: [],

        seats: 0,

        addingTeachers: false,

        pendingTeacher: {},

        addedTeachers: [],
        savingTeacher: false,

        filter: '',
        columns: [
          {label: 'First Name', field: 'firstname'},
          {label: 'Last Name', field: 'lastname'},
          {label: 'Username/Email', field: 'login'},
          {label: 'Password', field: 'password'},
          {label: '', field: 'userid', sortable: false}
        ],

        loading: false,

        addingTeacherPromise: null,
      }
    },

    computed: {
      canAddNewTeachers() {
        return this.teachers.length < this.seats
      }
    },

    methods: {

      async loadTeachers() {
        this.loading = true;
        try {
          await this._loadTeachers();
        } finally {
          this.loading = false;
        }
      },

      async _loadTeachers() {
        const response = await axios.get(BROWSE_ENDPOINT)
        const data     = response.data
        this.teachers  = data.data.teachers
        this.seats     = data.data.seats
      },

      showAddNewTeachers() {
        this.addingTeachers = true
        Vue.nextTick(() => this.$refs.pendingEmail.focus())
      },

      async addNewTeacher() {
        if(this.v$.$invalid) return;

        this.savingTeacher = true;
        this.addingTeacherPromise = axios.post(CREATE_ENDPOINT, this.pendingTeacher)
        try {
            const response     = await this.addingTeacherPromise

            this.addedTeachers = [response.data.data, ...this.addedTeachers]
            this.pendingTeacher = {}

            Vue.nextTick(() => this.$refs.pendingEmail.focus())

            this._loadTeachers()
        } finally {
            this.savingTeacher = false;
        }
      },

      // Copy-paste from WithBusySpinner, since we can't actually use that yet
      formatError(error) {
        // Returns an array of objects
        // [{category: string, messages: [string...]]

        if (this.errorFormatter) {
          return this.errorFormatter(error)
        }

        // Converts an error into something readable.  It's _probably_ thrown by axios, but
        // all we really know is we have an exception object of some sort, so poke at it.
        if (!error.response) {
          return [
            {category: "", messages: [error.toString()]}
          ]
        }
        this.errorResponse = error.response // stash away for debugging
        // It's axios.  Now let's find the status and further DWIM the message(s)
        let {status, data} = error.response

        if (status === 422) {
          // Validation errors.  Normally we do enough client-side validation that we
          // should rarely see these, but we can't catch them all.   This might be able
          // to tie into EditorField somehow to place errors next to their inputs, but
          // that's even more out of scope for what's supposed to be a busy spinner...

          // data.errors is an object very similar to our output format.  We just need
          // to convert it to an array (or maybe I should just allow returning objects)
          let errors = data.errors
          return Object.keys(errors)
            .map(key => ({category: key, messages: errors[key]}))
        }

        let message = null;
        if (typeof (data) === 'string') {
          message = `${data} [error ${status}]`
        } else if (data.message) {
          message = `${data.message} [error ${status}]`
        } else if (data.error) {
          // Some APIs use 'error' instead.
          message = `${data.error} [error ${status}]`
        } else {
          message = `Unknown error [error ${status}]`
        }
        return [
          {category: "", messages: [message]}
        ]
      },

    },

    created() {
      this.loadTeachers()
    },

    validations: {
        pendingTeacher: {
            email: {
                required,
                email
            },
        }
    }

  }
</script>

<template>
    <div>
        <div>
            <div v-if="loading"><h3>Loading...</h3></div>

            <div class="table-toolbar">
                <input
                    style="max-width: 200px;"
                    placeholder="Search..."
                    class="form-control"
                    v-model="filter"
                >

                <a
                    v-if="!addingTeachers && canAddNewTeachers"
                    class="btn btn-success"
                    @click="showAddNewTeachers()"
                >
                    Add New Teachers
                </a>
                <a
                    v-if="addingTeachers"
                    class="btn btn-success"
                    @click="addingTeachers = false"
                >
                    Done Adding Teachers
                </a>

            </div>

            <Promised :promise="addingTeacherPromise" pending-delay="200">
                <!-- These slots must be filled but we're only using the rejected slot -->

                <template v-slot:default>&nbsp;</template>
                <template v-slot:pending>&nbsp;</template>
                <template v-slot="response">&nbsp;</template>

                <!-- copy-paste job from WithBusySpinner -->
                <template v-slot:rejected="error">
                    <slot name="error">
                        <div class="alert alert-danger" role="alert">
                            <div
                                class="error-group"
                                v-for="{category, messages} in formatError(error)"
                            >
                                <span class="error-category">{{category}}</span>
                                <span class="error-messages">
								<ul>
									<li v-for="message in messages">{{message}}</li>
								</ul>
							</span>
                            </div>
                        </div>
                    </slot>
                </template>
            </Promised>


            <div class="teacher-count" v-if="!loading">
                {{ teachers.length }} Teachers,
                {{ seats - teachers.length }} Available
            </div>

            <div v-if="addingTeachers && !savingTeacher" class="panel panel-default">
                <div class="panel-heading">
                    <h3 class="panel-title">Add Teacher</h3>
                </div>
                <form class="panel-body">
                    <div class="form-group" :class="v$.pendingTeacher.email.$invalid ? 'has-error' : ''">
                        <label for="new_email">Email (required)</label>
                        <input ref="pendingEmail"
                            type="email"
                            class="form-control"
                            id="new_email"
                            v-model="pendingTeacher.email">
                    </div>
                    <div class="form-group">
                        <label for="new_first">First Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="new_first"
                            placeholder="First"
                            v-model="pendingTeacher.firstname">
                    </div>
                    <div class="form-group">
                        <label for="new_last">Last Name</label>
                        <input
                            type="text"
                            class="form-control"
                            id="new_last"
                            placeholder="Last"
                            @keydown.enter="addNewTeacher()"
                            v-model="pendingTeacher.lastname">
                    </div>
                    <button type="submit" class="btn btn-primary" :disabled="v$.$invalid" @click.prevent="addNewTeacher()">Send Invitation</button>
                </form>
            </div>

            <!-- FIXME: Busy Spinner -->
            <div v-if="savingTeacher">
                <div>
                    <h3>Adding Teacher...</h3>
                </div>
            </div>
        </div>

        <div class="recent-teachers" v-if="addedTeachers.length" style="width: 100%">
            <h3>Recently Added:</h3>
            <ul>
                <li v-for="teacher in addedTeachers" :key="teacher.userid">
                    {{teacher.email}} ({{teacher.firstname}}
                    {{teacher.lastname}})
                </li>
            </ul>
        </div>

        <div class="table table-striped table-responsive table-bordered">
            <datatable
                :columns="columns"
                :data="teachers"
                :filter-by="filter"
            >
                <template v-slot="{ row }">
                    <tr>

                        <td>
                            {{ row.firstname }}
                        </td>

                        <td>
                            {{ row.lastname }}
                        </td>

                        <td>
                            {{ row.login }}
                            <br>
                            {{ row.email }}
                        </td>

                        <td>
                            {{ row.password }}
                        </td>

                        <td>
                            <a
                                class="btn btn-danger btn-sm"
                                :href="`/impersonate/take/${row.userid}`"
                            >
                                Impersonate
                            </a>
                        </td>
                    </tr>
                </template>
            </datatable>
        </div>
    </div>
</template>


<style lang="scss">
    // Unscoped

</style>

<style lang="scss" scoped>

    .table-toolbar {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        padding-bottom: 1rem;
    }

    .btn {
        // This is supposed to be the default, but the CSS is being screwed with elsewhere
        color: white !important;
    }

    .teacher-count {
        font-weight: bold;
        font-size: 1.25em;
    }


    .recent-teachers {

        font-weight: bold;

        ul {
            list-style-type: square;
        }

        li {
            margin-left: 2em;

        }
    }

</style>
