<!-- District Admin Dashboard - not to be confused with DistrictAdminsPage in the superadmin app -->
<template>

  <div>
    <h1>{{ districtName }} Summary</h1>
    <!--<pre>{{ {start_date, end_date} }}</pre>-->
    <div v-if="loading_schools">
      <h3>Please Wait...</h3>
    </div>

    <div v-else>
      <section id="district-summary-report">
        <div v-if="summaryLoading">
          {{ summaryLoadingProgress }}
          <b-progress :value="schoolsLoadedCount" :max="schools.length" animated></b-progress>
        </div>
        <DistrictSummaryReport :year-summary="overall.year" :thirty-day-summary="overall.thirtyDays" />
      </section>

      <section v-if="schools.length" id="school-summary-reports">
        <div v-if="!summaryLoading">
          <b-button class="btn-toggle-all" variant="success" @click="expandAll()">Expand All</b-button>
          <b-button class="btn-toggle-all" variant="success" @click="collapseAll()">Collapse All</b-button>
        </div>

        <div v-for="(school, i) in schools" :key="i" class="summary">
          <div class="summary-header">
            <b-button v-if="schoolSummaryLoading(school)" class="btn-summary" variant="warning">
              <b-spinner v-if="schoolSummaryLoading(school)"  label="Loading.."></b-spinner>
            </b-button>
            <b-button v-else class="btn-summary" variant="success" @click="toggleExpansion(school)">
              <i :class="['fa', isExpanded(school) ? 'fa-minus' : 'fa-plus']"></i>
            </b-button>

            <h3 style="margin-top: 10px;">{{ school.name }} ({{ school.teachers }} Teachers, {{ school.students }} Students)</h3>

            <a class="btn btn-info" :href="schoolAdminUrl('detailed', school.id)" :target="`sa_detailed_${school.id}`">
              Details
            </a>

            <a class="btn btn-info" :href="schoolAdminUrl('concepts', school.id)" :target="`sa_concepts_${school.id}`">
              Concepts
            </a>
          </div>
          <div class="summary-body" v-if="isExpanded(school)">
            <SchoolSummaryReport :summary="school.breakdown" />

            <SchoolConceptReport v-if="hasAnyStudentTime(school.breakdown)" :summary="school.breakdown" />

            <div v-else style="width: 100%; padding: 1.5rem; display: flex; justify-content: center">
              <em>No data recorded &mdash; concept summary unavailable</em>
            </div>
          </div>
        </div>
      </section>
    </div>
  </div>

</template>

<!--suppress CssUnusedSymbol -->
<style scoped>

.center {
  width: 100%;
  display: flex;
  justify-content: center;
}

.btn-toggle-all {
  font-size: 1.5rem;
}

.btn-summary {
  font-size: 1.5rem;
  max-height: 2em;
  margin-right: 1em;
  margin-left: 1em;
}

.summary {
  margin: 1.5rem 0;
  border: 1px solid #ccc;
}

.summary-header {
  display: flex;
  align-items: center;
}

.summary-body {
  margin: 1em;
}

.btn-info {
  margin-left: 10px;
}

.fade-enter-active, .fade-leave-active {
  transition: opacity .5s;
}

.fade-enter, .fade-leave-to {
  opacity: 0;
}

@media print {
  .non-print {
    display: none;
  }
}

</style>

<script>

import axios from "axios";

const SCHOOL_LIST_DATA_URI = '/district_admin/school_list'
const SCHOOL_BREAKDOWN_URI = '/district_admin/school_breakdown'

import {BButton, BCard, BSpinner, BProgress, BTooltip, VBTooltip} from 'bootstrap-vue';
import DistrictSummaryReport from "./DistrictSummaryReport.vue"
import SchoolSummaryReport from "./SchoolSummaryReport.vue"
import SchoolConceptReport from "./SchoolConceptReport.vue"

export default {

  components: {
    SchoolConceptReport,
    SchoolSummaryReport,
    DistrictSummaryReport,
    BButton,
    BCard,
    BSpinner,
    BProgress,
    BTooltip
  },
  directives: {
    'b-tooltip': VBTooltip
  },
  props: {
    districtId: {
      type: Number,
      required: true
    },
    districtName: {
      type: String,
      required: true
    },
    startDate: {
      type: String,
      required: true
    },
    endDate: {
      type: String,
      required: true
    },
  },

  data() {
    return {
      loading_schools: false,
      summaryLoading: false,
      schools: [],
      expanded: {},
      overall: {
        year: {
          student_learning_time: {
            total: 0
          },
          teacher_investment_time: {
            total: 0
          },
          active_students: {
            total: 0
          },
          active_teachers: {
            total: 0
          },
          minutes_spent_reading: {
            total: 0,
          },
          comprehension_practice_time: {
            total: 0
          },
          fiction_lessons_complete: {
            total: 0
          },
          nonfiction_lessons_complete: {
            total: 0
          }
        },
        thirtyDays: {
          student_learning_time: {
            total: 0
          },
          teacher_investment_time: {
            total: 0
          },
          active_students: {
            total: 0
          },
          active_teachers: {
            total: 0
          },
          minutes_spent_reading: {
            total: 0,
          },
          comprehension_practice_time: {
            total: 0
          },
          fiction_lessons_complete: {
            total: 0
          },
          nonfiction_lessons_complete: {
            total: 0
          }
        }
      }
    }
  },

  computed: {
    schoolsLoadedCount() {
      var loaded = 0;
      for(const school of this.schools) {
        if(school.breakdown) loaded++;
      }
      return loaded;
    },

    summaryLoadingProgress() {
      var loaded = 0;
      for(const school of this.schools) {
        if(school.breakdown) loaded++;
      }

      if(loaded) {
        return `Loaded ${loaded} of ${this.schools.length} schools`;
      } else {
        return `Loading ${this.schools.length} schools.`;
      }
    },
  },

  async created() {
    // await this.fetchReportData()
    await this.loadSchoolList();
    this.initializeExpanded();
  },

  methods: {
    async loadSchoolList() {
      this.loading_schools = true
      try {
        this.schools  = await axios.get(`${SCHOOL_LIST_DATA_URI}/${this.districtId}`).then((response) => response.data);
        for(var ii= 0; ii<this.schools.length; ii++) {
          this.schools[ii].breakdown = null;
        }

        // now kick off the individual loads.
        this.loadSchoolBreakdowns();
      } finally {
        this.loading_schools = false
      }
    },

    initializeExpanded() {
      // expanded rows won't be reactive unless we do this first
      for (const school of this.schools) {
        this.$set(this.expanded, this.summaryKey(school), false)
      }
    },

    summaryKey(school) {
      // return summary.schoolYear.title
      return school.id;
    },

    isExpanded(summary) {
      return !!this.expanded[this.summaryKey(summary)]
    },

    toggleExpansion(summary) {
      const key = this.summaryKey(summary)
      this.expanded[key] = !this.expanded[key]
    },

    expandAll() {
      for (const school of this.schools) {
        this.expanded[this.summaryKey(school)] = true
      }
    },

    collapseAll() {
      for (const key of Object.keys(this.expanded)) {
        this.expanded[key] = false
      }
    },

    schoolSummaryLoading(school) {
      return (school.breakdown==null)
    },

    hasAnyStudentTime(summary) {
      return !!summary.school_year.breakdown[0].overall.student_learning_time
    },

    setSchoolBreakdown(school, breakdown) {
      var newSchools = [];
      for(var s of this.schools) {
        if(s.id==school.id) {
          s.breakdown = breakdown;

          const yearOverall =  breakdown.school_year.breakdown[0].overall;
          const monthOverall =  breakdown.last_30_days.breakdown[0].overall;

          this.overall.year.student_learning_time.total += yearOverall.student_learning_time;
          this.overall.year.teacher_investment_time.total += yearOverall.teacher_investment_time;
          this.overall.year.active_students.total += yearOverall.active_students;
          this.overall.year.active_teachers.total += yearOverall.active_teachers;
          this.overall.year.minutes_spent_reading.total += yearOverall.minutes_spent_reading;
          this.overall.year.comprehension_practice_time.total += yearOverall.comprehension_practice_time;

          for(const genre of yearOverall.genres) {
            if(genre.name=='Fiction') {
              this.overall.year.fiction_lessons_complete.total += genre.count;
            } else {
              this.overall.year.nonfiction_lessons_complete.total += genre.count;
            }
          }

          this.overall.thirtyDays.student_learning_time.total += monthOverall.student_learning_time;
          this.overall.thirtyDays.teacher_investment_time.total += monthOverall.teacher_investment_time;
          this.overall.thirtyDays.active_students.total += monthOverall.active_students;
          this.overall.thirtyDays.active_teachers.total += monthOverall.active_teachers;
          this.overall.thirtyDays.minutes_spent_reading.total += monthOverall.minutes_spent_reading;
          this.overall.thirtyDays.comprehension_practice_time.total += monthOverall.comprehension_practice_time;

          for(const genre of monthOverall.genres) {
            if(genre.name=='Fiction') {
              this.overall.thirtyDays.fiction_lessons_complete.total += genre.count;
            } else {
              this.overall.thirtyDays.nonfiction_lessons_complete.total += genre.count;
            }
          }
        }
        newSchools.push(s);
      }
      this.schools = newSchools;
    },

    loadSchoolBreakdowns() {
      const params = {start_date: this.startDate, end_date: this.endDate};
        let getUserData = (school) =>
          axios.get(`${SCHOOL_BREAKDOWN_URI}/${school.id}`, {params})
          .then((response) => response.data )
          .then((data) => {
            this.setSchoolBreakdown(school, data.data);
            return data.data
          }).catch(console.error);

        this.summaryLoading = true;

        var model = this;
        axios.all(this.schools.map(getUserData)).then(function(data) {
          model.$set(model, 'summaryLoading', false);
        });
    },

    schoolAdminUrl(category, school_id) {
      return `/school_admin/reports/${category}/?school_id=${school_id}&start_date=${this.startDate}&end_date=${this.endDate}`
    }
  },
}


</script>
