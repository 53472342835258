<template>
  <banner v-show="showBanner" :type="bannerType">
      <slot>
        {{bannerMessage}}
      </slot>
  </banner>
</template>

<style lang="scss" scoped>
</style>

<script>
  import Banner from "./Banner.vue"

  export default {
    name: "ResponseBanner",

    components: {Banner},

    props: {
      response: {
        required: true,
        validator: prop => typeof prop === 'object' || prop === null
      },

      show: {
        type: Boolean,
        required: false,
        default: true,
      }
    },

    computed: {
      showBanner() {
        return this.show && this.bannerMessage
      },

      bannerType() {
        if (!this.response) {
          return null
        }
        return this.response.data.success ? 'success' : 'danger'
      },

      bannerMessage() {
        if (!this.response) {
          return null
        }
        // TODO: parse validation error responses using a HOC and/or slot
        return this.response.data.message
      }
    }

  }
</script>
