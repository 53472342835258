<template>
<div>
  <response-banner :response="response"></response-banner>

  <div v-if="!sent">
    <parent-mail-picker :students="students" v-model="selectedStudents"></parent-mail-picker>

    <mail-template-ui
      :templates="templates"
      :recipients="selectedStudents"
      @submit="sendTemplate"
      @preview="previewTemplate"
    >
    </mail-template-ui>

    <b-modal v-model="showPreview" title="Message Preview">

      <strong>Subject:</strong> {{this.preview.subject}}
      <hr>
      <div v-html="this.preview.body"></div>

    </b-modal>
  </div>
</div>
</template>

<style lang="scss" scoped>
</style>

<style lang="scss">
  /* unscoped */
  @import "../../sass/wizard";
</style>

<script>
"use strict";

import ParentMailPicker from "./ParentMailPicker.vue";
import MailTemplateUi from "./MailTemplateUi.vue";
import ResponseBanner from "./widgets/ResponseBanner.vue";

export default {
  name: "ParentConnect",

  components: {ParentMailPicker, MailTemplateUi, ResponseBanner},

  inject: ['service'],

  props: {},

  data() {
    return {
      templates: [],
      students: [],
      selectedStudents: [],
      showPreview: false,

      preview: {subject: '', body: ''},

      sendPromise: null,
      sent: false,

      response: null,

      errorMessage: null,
      successMessage: null,
    }
  },

  computed: {

    selectedStudentIds() {
      return this.selectedStudents.map(s => s.id)
    },

  },

  created() {
    this.fetchStudents()
    this.fetchTemplates()
  },

  methods: {

    async fetchTemplates() {
      const templates = await this.service('mail_template')
      const response = await templates.browse()
      this.templates = response.data.data.data  // only first page is just fine
    },

    async fetchStudents() {
      const students = await this.getStudents()
      students.forEach(s => s.selected = false) // must add this prop for it to be reactive
      this.students = students
      console.log("STUDENTS", this.students)
    },

    async sendTemplate(form) {
      const id = form.template.id
      const url = '/teacher/parentConnect/sendMail/' + id

      try {
      this.response = await axios.post(url, {
        subject: form.subject,
        custom_message: form.custom_message,
        student_ids: this.selectedStudentIds,
      })
      } catch (ex) {
        this.response = ex.response
        throw ex
      }

      this.$scrollTo(document.getElementsByTagName('body')[0])
      this.sent = true
    },

    async previewTemplate(form) {
      const id = form.template.id
      const url = '/teacher/parentConnect/preview/' + id

      const response = await axios.post(url, {
        subject: form.subject,
        custom_message: form.custom_message,
        student_ids: this.selectedStudentIds,
      })

      const preview = response.data.data
      console.log("PREVIEW", preview)

      this.preview = preview
      this.showPreview = true
    },

    async getStudents() {
      const url = '/teacher/parentConnect/mailableStudents'
      const response = await axios.get(url)
      return response.data.data
    },

  },


}
</script>
