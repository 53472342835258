<template>
  <div>
    <h1 style="color: #CC0000;">My District's Settings</h1>

    <div v-if="loading">
      <h1>Loading...</h1>
    </div>

    <div v-else>
      <b-card>

        <template #header>
          <h2>District Information</h2>
          <b-alert variant="danger" :show="!!errorMessage" dismissable>{{ errorMessage }}</b-alert>
          <b-alert variant="success" :show="!!successMessage" dismissable>{{ successMessage }}</b-alert>
        </template>

        <b-card-text>
          <b-form-row>
            <b-col md="auto">
              <label for="input-district-name">
                District Name
              </label>
            </b-col>
            <b-col>
              <b-form-input type="text" id="input-district-name" v-model="v$.district.name.$model"/>
            </b-col>
          </b-form-row>
        </b-card-text>

        <template #footer>
          <div v-if="v$.$anyDirty">
            <action-button action="save" size="md" @click.prevent="updateDistrict()" :disabled="v$.$invalid">
              <span v-if="saving">Saving...</span>
              <span v-else>Update District Information</span>
            </action-button>
          </div>
        </template>

      </b-card>
    </div>
  </div>

</template>

<style lang="scss" scoped>
</style>

<script>

import {
  BAlert,
  BButton,
  BCard,
  BCardText,
  BCol,
  BForm,
  BFormGroup,
  BFormInput,
  BFormRow,
} from 'bootstrap-vue';

import useVuelidate from "@vuelidate/core"
import {required, minLength, maxLength} from "@vuelidate/validators"

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  components: {
    BAlert,
    BButton,
    BCard,
    BCardText,
    BCol,
    BForm,
    BFormGroup,
    BFormInput,
    BFormRow,
  },

  props: {
    districtId: {
      type: Number,
      required: true
    }
  },

  inject: ['service'],

  data() {
    return {
      loading: true,
      saving: false,
      successMessage: '',
      errorMessage: '',
      district: null,
    }
  },
  computed: {},
  methods: {

    async fetchDistrict() {
      this.loading = true
      this.errorMessage = ''
      this.successMessage = ''
      try {
        const districts = await this.service('district')
        const response = await districts.read(this.districtId)
        this.district = response.data.data
      } catch (ex) {
        this.errorMessage = ex.toString()
      } finally {
        this.loading = false
      }
    },

    async updateDistrict() {
      this.saving = true
      this.errorMessage = ''
      this.successMessage = ''
      try {
        const districts = await this.service('district')
        const response = await districts.edit(this.districtId, this.district)
        this.district = response.data.data
        this.successMessage = "District information updated"
      } catch (ex) {
        this.errorMessage = ex.toString()
      } finally {
        this.saving = false
      }
    }

  },

  validations: {
    district: {
      name: {
        required,
        minLength: minLength(6),
        maxLength: maxLength(128),
      },
    }
  },

  created() {
    this.fetchDistrict()
  }

}
</script>
