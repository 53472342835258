<style lang="scss" scoped>
		// #comment {
		// 	width: 700px;
		// 	height: 200px;

		// }

		h1, h2, h3, h4, h5, h6 {
			color: #C00;
		}

		.extension-question {
			font-weight: bold;
			font-size: 18px;
		}

		.extension-score {
			font-style: italic;
			font-size: 18px;
		}

		.extension-answer {
			border: 1px solid black;
			margin-top: 2rem;
			padding: 2rem;
			margin-bottom: 2rem;
			width: 100%;
		}

		.buttons {
			margin-top: 10px;
			text-align: center;
		}
</style>

<template>
	<div class="main-content">
		<div v-show="errorMsg" id="err_msg" class="alert alert-danger">{{ errorMsg }}</div>
		<div v-show="successMsg" class="alert alert-success">{{ successMsg }}</div>
		<div v-if="loading">Loading...</div>
		<div v-else>
			<div class="row">
				<div class="col-sm-8 col-sm-offset-2">
					<h1>Individual Student Performance</h1>
					<h3>{{ data.studentName }}: {{ data.lessonTitle }}</h3>
					<div class="extension-qa">
						<div class="extension-question">{{ data.questionText }}</div>
						<div class="extension-score">{{ data.scoreText }}</div>
						<textarea
							disabled
							class="extension-answer"
							v-model="data.response"
							style="height: 12em">
						</textarea>
					</div>
					<editor api-key="7vji1erxmio5rx613x4mehl92wm1rwzhqrrzv3o1t41i6a51"
					:init="{plugins: 'wordcount', height: '200px', menubar: false, toolbar1: 'undo redo | bold italic underline | forecolor backcolor'}"
					v-model="data.comment">
					</editor>

					<div class="buttons">
						<input class="btn btn-primary" type="submit" name="submit" :value="submitLabel" @click.prevent="onSubmit()" :disabled="submitting" />
					</div>
				</div>
			</div>
		</div>
	</div>
</template>


<script>
import Editor from '@tinymce/tinymce-vue';

export default {
	components: {
		'editor': Editor
    },
    mounted() {
		this.load();
    },
    data(){
		return {
			loading: false,
        	submitting: false,
			submitLabel: "Submit",
			errorMsg: '',
			successMsg: '',
			data: {
				comment: null,
				lessonTitle: '',
				questionText: '',
				response: '',
				score: '',
				scoreText: '',
				studentName: ''
			}
      	}
	},
    props: {
      	lesson: {
        	type: Number,
        	required: true,
      	},
      	student: {
	        type: Number,
        	required: true,
      	}
    },
    methods: {
		load() {
			var payload = {
				verb: 'load',
				lesson_id: this.lesson,
				student_id: this.student
			}
			this.loading = true;

        	axios.post('/teacher/reports/extension_answer', payload)
        	.then((response) => response.data)
			.then((data) => {
				this.data = data;
			}).catch((error) => {
				this.showError(error);
			}).finally(() => {
				this.loading = false;
			})
		},
		onSubmit() {
        	var payload = {
				verb: 'save',
				lesson_id: this.lesson,
				student_id: this.student,
				comment: this.data.comment,
        	};

			this.submitting = true;
			this.submitLabel = "Saving...";

			axios.post('/teacher/reports/extension_answer', payload)
			.then((response) => response.data)
			.then((data) => {
				this.data = data;
				this.showSuccess("Comment saved!");
			}).catch((error) => {
				this.showError(error);
			}).finally(() => {
				this.submitting = false;
				this.submitLabel = "Submit";
			});
		},
		showError(msg) {
			this.errorMsg = msg;
        	this.$scrollTo('#err_msg');
		},
		showSuccess(msg) {
			this.successMsg = msg;
      	}
    },
}
</script>
