<style lang="scss">
  .site-license-request {
    label {
      font-size: 14px;
    }
  }
</style>

<template>
  <div>
    <button
      class="btn btn-block btn-lg"
      :class="btnClass"
      style="font-size: 18px"
      @click="showDialog = true"
    >
      {{ btnLabel }}
    </button>


    <b-modal
      v-model="showDialog"
      title="Contact Us To Get Your District Started with Lyrics2Learn"
      hide-footer
    >
      <quote-request-form
        v-bind="$attrs"
        :is-modal="true"
        :show-referral="!$attrs['referral-code']"
        @cancelled="closeDialog()"
        @submitted="closeDialog()"
      >
      </quote-request-form>
    </b-modal>

  </div>

</template>

<script>
  export default {
    props: {
      btnClass: {
        type: String,
        required: false,
        default: 'btn-success'
      },

      btnLabel: {
        type: String,
        required: false,
        default: 'District Pricing Contact Form'
      },
    },

    data() {
      return {
        showDialog: false,
      };
    },


    methods: {
      closeDialog() {
        this.showDialog = false
      },
    },

  }
</script>
