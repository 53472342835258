<script>

import ActionButton from "./widgets/ActionButton.vue";

const ENDPOINT = '/student/feedback';

export default {
  components: {ActionButton},

  props: {
    studentId: {
      type: Number,
      required: true,
    }
  },

  data() {
    return {
      fetchResponse: null,
      saveResponse: null,
      message: null,
      successMessage: null,
    }
  },

  computed: {
    endpoint() {
      return `${ENDPOINT}/${this.studentId}`
    },
    originalMessage() {
      return this.fetchResponse.data.data.message
    }
  },

  methods: {
    async fetchMessage() {
      this.fetchResponse = await axios.get(this.endpoint)
      this.message = this.fetchResponse.data.data.message
    },

    async onSave() {
      this.saveResponse = await axios.post(this.endpoint, {message: this.message})
      this.successMessage = "Message Saved Successfully";
      setTimeout(() => this.successMessage = null, 5000)
    },

    async onCancel() {
      this.message = this.originalMessage
    },

  },

  created() {
    this.fetchMessage()
  },
}
</script>

<template>
  <div>
    <section class="entry">
    <textarea
      v-model="message"
      class="feedback-entry"
      placeholder="Enter a message for your student here..."
    />
    </section>
    <section class="text-right">
      <button class="btn btn-secondary cancel-button mr-2" @click="onCancel">Reset</button>
      <button class="btn btn-primary save-button" @click="onSave">Save</button>
      <div v-if="successMessage" class="success-message">{{ successMessage }}</div>
    </section>

  </div>
</template>


<style lang="scss" scoped>
section {
  margin: 1em;
}

.feedback-entry {
  width: 100%;
  height: 5em;
}

.success-message {
  background-color: #eeffee;
  color: green;
  font-weight: bold;
  margin: 1em;
  padding: 1em;
  border-radius: 3px;
}

</style>
