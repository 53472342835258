<script setup lang="ts">
import {FluencyRecording, FluencyGrade} from "~app/fluency"
import {useVModels} from "@vueuse/core"
import {computed} from "vue"
import {FluencyGraderState} from "~components/teacher/fluency/FluencyGrader.vue"

const props = defineProps<{
  reading: FluencyRecording
  grade: FluencyGrade
  state: FluencyGraderState
}>()

const emit = defineEmits(["abort", "error", "new-grade", "reset", "submit"])

const {grade, state} = useVModels(props, emit)

const grading = computed(() => state.value === "grade")
const submitting = computed(() => state.value === "submitting")

const hasGrade = computed(() => {
  const g = grade.value
  return !!(g.accuracy || g.expression || g.feedback)
})

const validGrade = computed(() => grade.value.expression && grade.value.wpm)

const gradedAccuracy = computed(function() {
  return grade.value.wpm > 0 ?
    Math.round(100 * (grade.value.wpm + grade.value.accuracy) / grade.value.wpm)  + '%' : '--'
})
const cwpm = computed(function() {
  return grade.value.wpm > 0 ? grade.value.wpm + grade.value.accuracy : '--'
})

const selectStyle = computed(() =>
  grade.value.expression
    ? {"font-size": "x-large", "margin-top": "-0.75rem"}
    : {"font-size": "0.8rem", "font-style": "italic"},
)

</script>

<template>
  <div class="fluency-grading-video container">
    <div class="row mb-2">
      <video style="width: 100%" controls :src="reading.url"></video>
    </div>

    <div class="row mb-2">
      <div class="col-md-12 col-lg-4">
        <div class="grade">
          <header>WPM</header>
          <div v-if="grade.wpm>0">{{  grade.wpm }}</div>
          <div v-else>&mdash;&mdash;</div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4">
        <div class="grade">
          <header>Errors</header>
          <div>{{ -grade.accuracy }}</div>
        </div>
      </div>

      <div class="col-md-12 col-lg-4">
        <div class="grade">
          <header>
            <label for="select-expression">Expression</label>
          </header>
          <select
            v-if="grading"
            id="select-expression"
            class="form-control expression-selection"
            v-model.number="grade.expression"
            :disabled="submitting"
            :style="selectStyle"
          >
            <option value="0">(Choose Score)</option>
            <option value="1">1</option>
            <option value="2">2</option>
            <option value="3">3</option>
            <option value="4">4</option>
            <option value="5">5</option>
          </select>
          <span v-else style="margin-top: -0.6rem">{{ grade.expression || "-" }}</span>
        </div>
      </div>
    </div>

    <div class="row mb-2">
      <div class="col-12">
        <textarea
          v-if="grading"
          placeholder="Enter your feedback for this fluency video..."
          class="form-control"
          v-model="grade.feedback"
          :disabled="submitting"
        />
        <div v-else class="bordered">
          {{ grade.feedback || "(no feedback left)" }}
        </div>
      </div>
    </div>

    <div class="row mb-2" v-if="!grading">
      <div class="col-12 text-center">
        <header>
          CWPM:&nbsp;{{  cwpm }} Accuracy:&nbsp;{{  gradedAccuracy }}
        </header>
      </div>
    </div>

    <div class="row">
      <div class="col-12" style="display:flex; justify-content: space-between;"">
        <b-button v-if="grading && hasGrade" variant="secondary" @click="$emit('reset')">
          Reset
        </b-button>
        <b-button v-else variant="secondary" :disabled="submitting" @click="$emit('abort')">
          Close Window
        </b-button>

        <b-button v-if="grading" variant="success" :disabled="submitting || !validGrade" @click="$emit('submit')">
          {{ submitting ? "Submitting..." : "Submit Grade" }}
        </b-button>

        <b-button v-if="reading.grade && !grading" variant="danger" @click="$emit('new-grade')">
          Create New Grade
        </b-button>

      <b-button v-if="!reading.grade && grading" variant="danger" @click="$emit('delete')">
        Delete Recording
      </b-button>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
.bordered {
  min-height: 5.25rem;
  padding: 0.5rem;
  border: 3px solid #ccc;
  border-radius: 0.5rem;
}

.grade {
  // display: flex;
  // flex-direction: column;
  // min-width: 9rem;
  // max-width: 9rem;
  text-align: center;
  min-height: 5.25rem;
  max-height: 5.25rem;
  padding: 0.5rem;

  font-size: 1.5rem;
  margin-top: 5px; // when we stack, this helps

  border: 3px solid #ccc;
  border-radius: 0.5rem;

  // handle the expression text in the narrower than full width but too wide for stacked
  header {
    @media (850px < width < 1200px) {
      label {
        font-size: 1.0rem;
      }
    }
  }
}
/*
@import "../../../../sass/bs5_theme_vars.scss";

select {
  text-align: center;
  font-size: x-large;
  cursor: pointer;
}

section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;
  text-align: center;
}

header {
  margin-bottom: 0.25rem;
}

.grade {
  display: flex;
  flex-direction: column;
  min-width: 9rem;
  max-width: 9rem;
  min-height: 5.25rem;
  max-height: 5.25rem;
  padding: 0.5rem;

  font-size: 1.5rem;

  border: 3px solid #ccc;
  border-radius: 0.5rem;
}
*/
</style>
