<script setup lang="ts">
import FluencyShareButton from "~components/teacher/fluency/FluencyShareButton.vue"
import {FluencyRecording} from "~app/fluency"
import {computed, ref} from "vue"

const props = defineProps<{
  reading: FluencyRecording
  coldRead: FluencyRecording | undefined
  hotRead: FluencyRecording | undefined
}>()

const submitting = ref(false)

//// Scores

const _renderNumber = (val: number): string => isNaN(val) ? "?" : `${val}`
const coldReadAccuracy = computed(() => _renderNumber(-(props.coldRead?.grade?.accuracy as number)))
const hotReadAccuracy = computed(() => _renderNumber(-(props.hotRead?.grade?.accuracy as number)))
const coldReadExpression = computed(() => _renderNumber(props.coldRead?.grade?.expression as number))
const hotReadExpression = computed(() => _renderNumber(props.hotRead?.grade?.expression as number))

//// Sharing

const shareMessage = ref<string>("")
const shareError = ref<string>("")

function onShareDone() {
  shareMessage.value = "Video shared successfully"
  shareError.value = ""
}

function onShareCancelled() {
  shareMessage.value = ""
  shareError.value = ""
}

function onShareError(err: unknown) {
  shareError.value = `Error sharing video: ${err}`
  shareMessage.value = ""
}
</script>

<template>
  <div class="fluency-grading-comparison-video">
    <section>
      <video style="width: 100%" controls :src="reading.url"></video>
    </section>

    <section>
      <div class="grade">
        <header>WPM</header>
        <div>{{ reading.lesson.wordspermin }}</div>
      </div>

      <div class="grade">
        <header>Errors</header>
        <div>
          <span class="cold">{{ coldReadAccuracy }}</span>
          →
          <span class="hot">{{ hotReadAccuracy }}</span>
        </div>
      </div>

      <div class="grade">
        <header>Expression</header>
        <div>
          <span class="cold">{{ coldReadExpression }}</span>
          →
          <span class="hot">{{ hotReadExpression }}</span>
        </div>
      </div>
    </section>

    <section>
      <b-button variant="secondary" :disabled="submitting" @click="$emit('abort')">
        Cancel
      </b-button>

      <FluencyShareButton
        v-if="!shareMessage"
        :video-id="reading.id"
        @done="onShareDone"
        @cancelled="onShareCancelled"
        @error="onShareError"
      />
    </section>

    <section>
      <b-alert
        :show="!!shareMessage"
        class="text-center mt-5 w-100"
        variant="soft-success"
        dismissible
      >
        {{ shareMessage }}
      </b-alert>

      <b-alert
        :show="!!shareError"
        class="text-center mt-5 w-100"
        variant="soft-danger"
        dismissible
      >
        {{ shareError }}
      </b-alert>
    </section>
  </div>
</template>

<style lang="scss" scoped>
section {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5rem;
  align-items: center;
  text-align: center;
}

header {
  margin-bottom: 0.25rem;
}

.grade {
  display: flex;
  flex-direction: column;
  min-width: 9rem;
  max-width: 9rem;
  min-height: 5.25rem;
  max-height: 5.25rem;
  padding: 0.5rem;

  font-size: 1.5rem;

  border: 3px solid #ccc;
  border-radius: 0.5rem;
}

.hot {
  color: red
}

.cold {
  color: rgb(0, 200, 200)
}
</style>
