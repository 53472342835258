<style lang="scss" scoped>
// Modal related below
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, .5);
  display: table;
  transition: opacity .3s ease;
}

.modal-wrapper {
  display: table-cell;
  //      vertical-align: middle;
}

.show_err_modal {
  .modal-content {
    background: none !important;
    border: 0px !important;
    box-shadow: none !important;
  }

  .lefty-speech-wrap {
    background: url(/images/lefty-speech.png) no-repeat left bottom;
    background-size: 100px;
    min-height: 130px;
  }

  .lefty-speech {
    background: #fff;
    padding: 15px;
    border-radius: 10px;
    margin-left: 85px;
    margin-bottom: 80px;
  }

  .modal-content {
    background: none !important;
    border: 0px !important;
    box-shadow: none !important;
  }

  @media (min-width: 768px) {
    .modal-dialog {
      width: 70% !important;
    }
  }
}


.clever-button {
  $icon-size: 48px;

  position: relative;
  display: flex;
  padding: 0;
  width: 100%;
  height: calc(#{$icon-size} + 2px);

  .icon {
    background: url(/assets/images/clever_logo.png) white 50% 50% no-repeat;
    background-size: 50%;
    position: absolute;
    left: 0;
    top: 0;
    width: $icon-size;
    height:$icon-size;
  }

  .buttonText {
    margin: auto;
    width: 100%;
  }
}
</style>

<template>
  <div class="container content-space-2 content-space-lg-3">
    <div class="flex-grow-1 mx-auto" style="max-width: 28rem;">

      <form role="form" method="post" class="js-validate needs-validation" novalidation>
        <fieldset v-show="step == 1">
          <div class="text-center">
            <img class="avatar avatar-xl" src="/assets/images/lefty-new.png" />
            <h1 class="h2">Student Login</h1>
            <p>Enter your teacher's username:</p>
          </div>

          <div>
            <div class="mb-3">
              <label for="tch_username"></label>
              <input type="text" class="form-control form-control-lg" name="form-first-name"
                     placeholder="Your Teacher's Username" id="tch_username" v-model="form.teacher"
                     @keyup="testEnter($event, step)" maxlength="20" required>
            </div>
            <div class="d-grid mb-3">
              <button type="button" class="btn btn-primary btn-lg" @click.prevent="stepOne()"
                      :disabled="step1.loadingStudents">{{ step1.nextTitle }}
              </button>
            </div>
          </div>

          <div v-show="allowClever">
            <div class="text-bold text-center mt-3 mb-3">&mdash; or &mdash;</div>

            <a href="/clever/login" class="btn btn-primary btn-lg clever-button">
              <div class="icon"></div>
              <div class="buttonText">Login With Clever</div>
            </a>
          </div>
        </fieldset>

        <fieldset v-show="step == 2">
          <div class="mb-3">
            <div class="text-center">
              <img class="avatar avatar-xl" src="/assets/images/lefty-new.png" />
              <h3>Student Login</h3>
              <p v-if="step2.type == 'input'">Type your username:</p>
              <p v-if="step2.type == 'select'">Select your username:</p>
            </div>
          </div>
          <!----FIX THIS---->
          <div class="container text-center">
            <div v-if="step2.type == 'input'" class="form-group">
              <label class="sr-only" for="student_username">Username</label>
              <input type="text" name="student_username" placeholder="Username..."
                     class="form-first-name form-control" v-model="form.student"
                     @keyup="testEnter($event, step)" maxlength="20">
            </div>
            <div v-if="step2.type == 'select'">
              <div v-for="student in step2.students" :key="student.id" class="col-sm-4 btn"
                   @click="selectStudent(student)">
                <a class="text-center btn btn-primary fs-4 text-white"
                   :class="{ stud_selected: form.studentId == student.id }">
                  <i class="bi bi-person-vcard fs-1 text-white"></i>
                  {{ student.name }}
                </a>
              </div>
              <!----<div class="clear"></div>--->
            </div>
            <div class="mb-3">
              <div id="step2_btns">
                <button type="button" class="btn btn-success text-center" @click.prevent="back()"
                        :disabled="step2.processing">Previous
                </button>
                <button type="button" v-if="step2.type == 'input'" class="btn"
                        @click.prevent="stepTwo()" :disabled="step2.processing">
                  <span v-show="step2.processing">Loading..</span>
                  <span v-show="!step2.processing">Next</span>
                </button>
              </div>
            </div>
          </div>
        </fieldset>

        <fieldset id="step3" v-show="step == 3">
          <div class="text-center">
            <img class="avatar avatar-xl" src="/assets/images/lefty-new.png" />
            <div class="mb-3">
              <h3>Student Login</h3>
              <p>Enter Your Password:</p>
            </div>
          </div>
          <div class="mb-3">
            <div class="form-group">
              <label for="stud_password">Password</label>
              <input type="text" name="stud_password" placeholder="Password..." autocomplete="false"
                     autocorrect="false" class="js-toggle-password form-control form-control-lg"
                     v-model="form.password" @keyup="testEnter($event, step)">
            </div>
            <p></p>
            <div class="text-center mb-3">
              <button type="button" class="btn btn-success mr-2" @click.prevent="back()"
                      :disabled="step3.processing">Previous
              </button>
              <button type="button" class="btn btn-primary" @click.prevent="stepThree()"
                      :disabled="step3.processing">
                <span v-show="!step3.processing">Sign me in!</span>
                <span v-show="step3.processing">Signing in...</span>
              </button>
            </div>
          </div>
        </fieldset>

      </form>

    </div>


    <div v-if="showModal">
      <transition name="modal">
        <div class="modal-mask" @click.prevent="showModal = false">
          <div class="modal-wrapper">
            <div class="modal-dialog show_err_modal">
              <div class="modal-content">
                <div class="lefty-speech-wrap">
                  <div class="lefty-speech">
                    <h3 id="modal_text">{{ errorText }}</h3>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>


    <div id="show_err_modal" class="modal fade show_err_modal" role="dialog">
      <div class="modal-dialog">
        <!-- Modal content-->
        <div class="modal-content">
          <div class="lefty-speech-wrap">
            <div class="lefty-speech">
              <h3 id="modal_text"></h3>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {required} from "@vuelidate/validators"

export default {
  props: {
    error: {
      type: String,
      default: "",
    },
    allowClever: {
      type: Boolean,
      default: false
    }
  },

  mounted() {
    const error = this.error
    if (error) {
      console.error("Student login error: ", error)
      const messages = {
        clever_login_not_found: "Your Clever login works, but is not set up on Lyrics2Learn.  Please ask your teacher to set up your Clever login.",
      }
      const message = messages[error] ?? `Unknown error while logging in: ${error}`
      this.showError(message)
    }
  },

  data() {
    return {
      step: 1,
      showModal: false,
      errorText: "",

      step1: {
        loadingStudents: false,
        nextTitle: "Next",
      },

      step2: {
        type: "input",
        students: [],
        processing: false,
      },

      step3: {
        processing: false,
      },

      form: {
        teacher: "",
        student: "",
        studentId: "",
        password: "",
      },
    }
  },
  methods: {
    stepOne() {
      if (this.form.teacher.length > 0) {

        this.step1.loadingStudents = true
        this.step1.nextTitle = "Loading..."
        axios.post("/student/login", {teacher: this.form.teacher, type: "unknown", step: this.step})
          .then((response) => response.data)
          .then((data) => {
            if (data.error) {
              this.showError(data.error)
            } else {
              this.step2.type = data.data.type
              if (this.step2.type == "input") {
                this.step2.students = []
              } else if (this.step2.type == "select") {
                this.step2.students = data.data.students
              }
              this.step = 2
            }
          }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.step1.loadingStudents = false
          this.step1.nextTitle = "Next"
        })
      } else {
        // show the error.
        this.showError("Enter your teacher's username")
      }
    },
    selectStudent(student) {
      this.form.studentId = student.id
      this.form.student = student.name
      this.stepTwo()
    },
    stepTwo() {
      if (this.step2.type == "input") {
        if (this.form.student.length > 0) {
          this.step2.processing = true
          axios.post("/student/login", {
            teacher: this.form.teacher,
            type: this.step2.type,
            student: this.form.student,
            step: this.step,
          })
            .then((response) => response.data)
            .then((data) => {
              if (data.error) {
                this.showError(data.error)
              } else {
                this.form.studentId = data.data.id
                this.step = 3
              }
            }).catch((error) => {
            console.error(error)
          }).finally(() => {
            this.step2.processing = false
          })
        } else {
          this.showError("Enter your username")
        }
      } else {
        if (this.form.studentId != "") {
          this.step = 3
        } else {
          this.showError("Select your username")
        }
      }
    },
    stepThree() {
      if (this.form.password.length > 0) {
        this.step3.processing = true
        axios.post("/student/login", {
          teacher: this.form.teacher,
          type: this.step2.type,
          student: this.form.student,
          studentId: this.form.studentId,
          password: this.form.password,
          step: this.step,
        })
          .then((response) => response.data)
          .then((data) => {
            if (data.error) {
              this.showError(data.error)
            } else {
              if (data.data.success) {
                window.location.reload()
              } else {
                this.showError("Something went wrong?")
              }
            }
          }).catch((error) => {
          console.error(error)
        }).finally(() => {
          this.step3.processing = false
        })

      } else {
        this.showError("Enter your password")
      }
    },
    testEnter(event, step) {
      if (event.keyCode == 13) {
        if (step == 1) {
          this.stepOne()
        } else if (step == 2) {
          this.stepTwo()
        } else if (step == 3) {
          this.stepThree()
        }
      }
    },
    back() {
      if (this.step > 1) {
        this.step -= 1
      }
    },
    showError(msg) {
      this.showModal = true
      this.errorText = msg
    },
  },
  validations: {
    form: {
      teacher: {
        required,
      },
      student: {
        required,
      },
      password: {
        required,
      },
    },
  },
}
</script>
