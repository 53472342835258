<template>

    <div>
        <div v-if="successMessage && successMessage.length">
            <h4 class="alert alert-success">{{ successMessage }}</h4>
        </div>


        <div v-if="!successMessage">
            <div v-if="errorMessage" class="alert alert-error">{{ errorMessage }}</div>
            <form>

                <div class="row">

                    <div class="mb-4">
                        <input class="form-control" v-model="v$.form.firstname.$model" type="text"
                            placeholder="First name" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.firstname.required">Field is required</div>
                            <div class="error" v-if="!v$.form.firstname.minLength">Field must have at least
                                {{ v$.form.firstname.$params.minLength.min }} characters.</div>
                            <div class="error" v-if="!v$.form.firstname.maxLength">Field cannot have more than
                                {{ v$.form.firstname.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>

                    <!-- End Col -->
                </div>
                <!-- End Row -->

                <div class="mb-4">
                    <div class="col-xs-8">
                        <input class="form-control form-control-lg" v-model="v$.form.lastname.$model" type="text"
                            placeholder="Last name" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.lastname.required">Field is required</div>
                            <div class="error" v-if="!v$.form.lastname.minLength">Field must have at least
                                {{ v$.form.lastname.$params.minLength.min }} characters.</div>
                            <div class="error" v-if="!v$.form.lastname.maxLength">Field cannot have more than
                                {{ v$.form.lastname.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>


                <div class="mb-4">
                    <div class="col-xs-8">
                        <input class="form-control form-control-lg" v-model="v$.form.email.$model" type="text"
                            placeholder="Email" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.email.required">Field is required</div>
                            <div class="error" v-if="!v$.form.email.email">Invalid email address!</div>
                            <div class="error" v-if="!v$.form.email.maxLength">Field cannot have more than
                                {{ v$.form.email.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>



                <div class="mb-4">
                    <div class="col-xs-8">
                        <input class="form-control form-control-lg" v-model="v$.form.phone.$model" type="text"
                            placeholder="Phone Number" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.phone.required">Field is required</div>
                            <div class="error" v-if="!v$.form.phone.minLength">Field must have at least
                                {{ v$.form.phone.$params.minLength.min }} characters.</div>
                            <div class="error" v-if="!v$.form.phone.maxLength">Field cannot have more than
                                {{ v$.form.phone.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <div class="col-xs-8">
                        <input class="form-control form-control-lg" v-model="v$.form.title.$model" type="text"
                            placeholder="Job Title" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.title.maxLength">Field cannot have more than
                                {{ v$.form.title.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <div class="col-xs-8">
                        <input class="form-control form-control-lg" v-model.trim="v$.form.district.$model" type="text"
                            placeholder="District Name" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.district.required">Field is required</div>
                            <div class="error" v-if="!v$.form.district.minLength">Field must have at least
                                {{ v$.form.district.$params.minLength.min }} characters.</div>
                            <div class="error" v-if="!v$.form.district.maxLength">Field cannot have more than
                                {{ v$.form.district.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>


                <div class="mb-4">
                    <div class="col-xs-8">
                        <input class="form-control form-control-lg" v-model="v$.form.city.$model" type="text" placeholder="City" />
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.city.required">Field is required</div>
                            <div class="error" v-if="!v$.form.city.minLength">Field must have at least
                                {{ v$.form.city.$params.minLength.min }} characters.</div>
                            <div class="error" v-if="!v$.form.city.maxLength">Field cannot have more than
                                {{ v$.form.city.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>

                <div class="mb-4">
                    <div class="col-xs-8">
                        <select class="form-select form-select-lg" v-model="v$.form.state.$model" placeholder="State">
                            <option value="">Please select one</option>
                            <option value="AL">Alabama</option>
                            <option value="AK">Alaska</option>
                            <option value="AR">Arkansas</option>
                            <option value="AZ">Arizona</option>
                            <option value="CA">California</option>
                            <option value="CO">Colorado</option>
                            <option value="CT">Connecticut</option>
                            <option value="DC">District of Columbia</option>
                            <option value="DE">Delaware</option>
                            <option value="FL">Florida</option>
                            <option value="GA">Georgia</option>
                            <option value="HI">Hawaii</option>
                            <option value="ID">Idaho</option>
                            <option value="IL">Illinois</option>
                            <option value="IN">Indiana</option>
                            <option value="IA">Iowa</option>
                            <option value="KS">Kansas</option>
                            <option value="KY">Kentucky</option>
                            <option value="LA">Louisiana</option>
                            <option value="ME">Maine</option>
                            <option value="MD">Maryland</option>
                            <option value="MA">Massachusetts</option>
                            <option value="MI">Michigan</option>
                            <option value="MN">Minnesota</option>
                            <option value="MS">Mississippi</option>
                            <option value="MO">Missouri</option>
                            <option value="MT">Montana</option>
                            <option value="NE">Nebraska</option>
                            <option value="NV">Nevada</option>
                            <option value="NH">New Hampshire</option>
                            <option value="NJ">New Jersey</option>
                            <option value="NM">New Mexico</option>
                            <option value="NY">New York</option>
                            <option value="NC">North Carolina</option>
                            <option value="ND">North Dakota</option>
                            <option value="OH">Ohio</option>
                            <option value="OK">Oklahoma</option>
                            <option value="OR">Oregon</option>
                            <option value="PA">Pennsylvania</option>
                            <option value="RI">Rhode Island</option>
                            <option value="SC">South Carolina</option>
                            <option value="SD">South Dakota</option>
                            <option value="TN">Tennessee</option>
                            <option value="TX">Texas</option>
                            <option value="UT">Utah</option>
                            <option value="VT">Vermont</option>
                            <option value="VA">Virginia</option>
                            <option value="WA">Washington</option>
                            <option value="WV">West Virginia</option>
                            <option value="WI">Wisconsin</option>
                            <option value="WY">Wyoming</option>
                        </select>
                        <div v-if="triedSubmit">
                            <div class="error" v-if="!v$.form.state.required">Field is required</div>
                            <div class="error" v-if="!v$.form.state.minLength">Field must have at least
                                {{ v$.form.state.$params.minLength.min }} characters.</div>
                            <div class="error" v-if="!v$.form.state.maxLength">Field cannot have more than
                                {{ v$.form.state.$params.maxLength.max }} characters.</div>
                        </div>
                    </div>
                </div>

                <!-- End Form -->

                <div class="d-grid mb-2">
                    <b-button size="lg" variant="primary" :disabled="shouldDisableSubmit" @click="submit">
                      <span v-if="submitting">Sending...</span>
                        <span v-else>Send inquiry</span>
                    </b-button>
                </div>

                <div class="text-center">
                    <span class="form-text">We'll get back to you in 1-2 business days.</span>
                </div>
            </form>
            <!-- End Form -->
        </div>



    </div>
</template>

<style lang="scss" scoped>
.heading {
    font-size: large;
    font-weight: bold;
    margin-bottom: 1em;
}

label {
    font-size: 14px;
}

.error {
    color: red;
}
</style>

<script>

import useVuelidate from "@vuelidate/core"
import {email, maxLength, minLength, required} from "@vuelidate/validators"

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },

    props: {

        isModal: {
            type: Boolean,
            required: false,
            default: false
        },

        initialReferralCode: {
            type: String,
            required: false,
            default: '',
        },

        showReferral: {
            type: Boolean,
            required: false,
            default: true
        }
    },

    data() {
        return {
            triedSubmit: false,
            submitting: false,
            showDialog: false,
            successMessage: '',
            errorMessage: '',
            form: {
                district: '',
                title: '',
                firstname: '',
                lastname: '',
                email: '',
                phone: '',
                city: '',
                state: '',
                referral: '',
                referralCode: this.initialReferralCode,
            },
        }
    },

    computed: {
        successButtonClass() {
            return this.isModal ? 'btn btn-success' : 'btn btn-success btn-lg btn-block';
        },
        shouldDisableSubmit() {
            return this.v$.form.$invalid || this.submitting;
        },
    },

    methods: {
        reset() {
            for (var k in this.form) {
                this.form[k] = ''
            }
            this.showDialog = false
        },

        cancel() {
            this.$emit('cancelled')
        },

        submit() {
            if (this.v$.$invalid) {
                this.triedSubmit = true;
            } else {
                this.successMessage = ''
                this.errorMessage = ''
                this.submitting = true
                axios.post('/district_pricing', this.form)
                    .then((response) => response.data)
                    .then((data) => {
                        if (data.success) {
                            // redirect.
                            this.successMessage = data.message
                            this.$emit('submitted');
                        } else {
                            this.errorMessage = data.message
                        }
                    }).catch((error) => {
                        try {
                            if (error.response.data.errors) {
                                var msg = ''
                                for (var k in error.response.data.errors) {
                                    msg += error.response.data.errors[k][0]
                                }
                                this.errorMessage = msg
                            }
                        } catch {
                            this.errorMessage = error.toString()
                        }
                    }).finally(() => {
                        this.submitting = false
                    })
            }
        },
    },

    validations: {
        form: {
            district: {
                required,
                minLength: minLength(4),
                maxLength: maxLength(500)
            },
            title: {
                maxLength: maxLength(200)
            },
            firstname: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            lastname: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(50)
            },
            email: {
                required,
                email,
                maxLength: maxLength(100)
            },
            phone: {
                required,
                minLength: minLength(7),
                maxLength: maxLength(20)
            },
            city: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            state: {
                required,
                minLength: minLength(2),
                maxLength: maxLength(100)
            },
            referral: {
            },
            referralCode: {
            },
        }
    }
}
</script>
