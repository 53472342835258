<script>

export default {
  props: {
    modal: {
      type: Boolean,
      default: false,
    },
  },

  data() {

    const formFields = ['school', 'address', 'city', 'state',
      'zip', 'firstname', 'lastname', 'title', 'email', 'phone',
      'subscription_count', 'referral', 'referralCode']

    const entryForm = {}
    formFields.forEach(name => entryForm[name] = '')

    return {
      submitting: false,
      successMessage: '',
      errorMessage: '',
      form: entryForm,
      quote_url: '',
      invoice_number: '',

      schoolsLoaded: false,
      loadingSchools: false,
      availableSchools: [],

      foreign: false,

      // constant data
      referralTypes: [
        "Pinterest",
        "A Friend Referred Me",
        "Search Engine",
        "Facebook",
        "Twitter",
        "Instagram",
        "Other",
        "Unknown",
      ],
    }
  },

  emits: ['generated'],

  methods: {
    onSubmit() {
      // token - is the token object
      // args - is an object containing the billing and shipping address if enabled
      // do stuff...
      this.successMessage = ''
      this.errorMessage = ''
      this.quote_url = ''
      this.submitting = true
      axios.post('/school_pricing_invoice', this.form)
        .then((response) => response.data)
        .then((data) => {
          // noinspection JSUnresolvedReference
          if (data.success) {
            // redirect.
            this.successMessage = data.message
            this.quote_url = data.url
            this.invoice_number = data.invoice_number
            this.$emit('generated', {url: data.url, invoiceId: data.invoice_number})
          } else {
            // noinspection JSUnresolvedReference
            this.errorMessage = data.message
          }
        }).catch((error) => {
        try {
          if (error.response.data.errors) {
            let msg = ''
            for (let k in error.response.data.errors) {
              msg += error.response.data.errors[k][0]
            }
            this.errorMessage = msg
          }
        } catch {
          this.errorMessage = error.toString()
        }
      }).finally(() => {
        this.submitting = false
      })
    },
  },
}
</script>

<template>
  <div>
    <div class="card card-lg request-teacher-quote">
      <div v-if="!quote_url" class="card-body">
        <div class="mb-4">
          <h3 class="card-title text-center">To automatically generate a quote for your school, please fill out the
            following information.</h3>
        </div>

        <form method="post" @submit.prevent="onSubmit">
          <div class="alert alert-danger" v-if="errorMessage">
            <b>Error: </b>{{ errorMessage }}
          </div>

          <div class="mb-2 form-group">
            <label class="form-label" for="school_name">Name Of School</label>
            <input
              type="text" class="form-control" name="school_name"
              v-model.trim="form.school"
              aria-label="School Name" required
            >
          </div>

          <div class="mb-2 form-group">
            <label class="form-label" for="school_address">Address</label>
            <input
              type="text" class="form-control" name="school_address"
              v-model.trim="form.address"
              aria-label="School Address" required
            >
          </div>

          <div class="row gx-3">

            <div class="col-sm-6">
              <div class="mb-2 form-group">
                <label
                  class="form-label" for="school_city"
                >City</label>
                <input
                  type="text" class="form-control"
                  name="school_city"
                  v-model.trim="form.city"
                  required
                >
              </div>
            </div>

            <div class="col-sm-6">
              <div class="mb-2 form-group">
                <label class="form-label" for="school_state">State</label>
                <select class="form-control" v-model="form.state" required>
                  <option value="">Please select one</option>
                  <option value="AL">Alabama</option>
                  <option value="AK">Alaska</option>
                  <option value="AR">Arkansas</option>
                  <option value="AZ">Arizona</option>
                  <option value="CA">California</option>
                  <option value="CO">Colorado</option>
                  <option value="CT">Connecticut</option>
                  <option value="DC">District of Columbia</option>
                  <option value="DE">Delaware</option>
                  <option value="FL">Florida</option>
                  <option value="GA">Georgia</option>
                  <option value="HI">Hawaii</option>
                  <option value="ID">Idaho</option>
                  <option value="IL">Illinois</option>
                  <option value="IN">Indiana</option>
                  <option value="IA">Iowa</option>
                  <option value="KS">Kansas</option>
                  <option value="KY">Kentucky</option>
                  <option value="LA">Louisiana</option>
                  <option value="ME">Maine</option>
                  <option value="MD">Maryland</option>
                  <option value="MA">Massachusetts</option>
                  <option value="MI">Michigan</option>
                  <option value="MN">Minnesota</option>
                  <option value="MS">Mississippi</option>
                  <option value="MO">Missouri</option>
                  <option value="MT">Montana</option>
                  <option value="NE">Nebraska</option>
                  <option value="NV">Nevada</option>
                  <option value="NH">New Hampshire</option>
                  <option value="NJ">New Jersey</option>
                  <option value="NM">New Mexico</option>
                  <option value="NY">New York</option>
                  <option value="NC">North Carolina</option>
                  <option value="ND">North Dakota</option>
                  <option value="OH">Ohio</option>
                  <option value="OK">Oklahoma</option>
                  <option value="OR">Oregon</option>
                  <option value="PA">Pennsylvania</option>
                  <option value="RI">Rhode Island</option>
                  <option value="SC">South Carolina</option>
                  <option value="SD">South Dakota</option>
                  <option value="TN">Tennessee</option>
                  <option value="TX">Texas</option>
                  <option value="UT">Utah</option>
                  <option value="VT">Vermont</option>
                  <option value="VA">Virginia</option>
                  <option value="WA">Washington</option>
                  <option value="WV">West Virginia</option>
                  <option value="WI">Wisconsin</option>
                  <option value="WY">Wyoming</option>
                </select>
              </div>
            </div>
          </div>


          <div class="row gx-3">

            <div class="offset-sm-6 col-sm-6">
              <div class="mb-2 form-group">
                <label
                  class="form-label" for="school_zip"
                >Zip Code</label>
                <input
                  type="text" class="form-control"
                  name="school_zip"
                  v-model.trim="form.zip" required
                >
              </div>

            </div>
          </div>

          <div class="row gx-3">

            <div class="col-sm-6">
              <div class="mb-2 form-group">
                <label
                  class="form-label" for="firstname"
                >First Name</label>
                <input
                  type="text" class="form-control"
                  name="firstName"
                  v-model.trim="form.firstname" required
                >
              </div>
            </div>

            <div class="col-sm-6">
              <div class="mb-2 form-group">
                <label class="form-label" for="lastname">Last
                  Name</label>
                <input
                  type="text" class="form-control"
                  name="lastName"
                  v-model.trim="form.lastname"
                  aria-label="Last Name" required
                >
              </div>
            </div>
          </div>

          <div class="mb-2 form-group">
            <label class="form-label" for="job_title">Job Title</label>
            <input
              type="text" class="form-control" name="job_title"
              v-model="form.title"
              aria-label="Job Title" required
            >
          </div>

          <div class="mb-2 form-group">
            <label class="form-label" for="email">Email Address</label>
            <input
              type="email" class="form-control" name="email"
              v-model="form.email"
              aria-label="email@site.com" required
            >
          </div>

          <div class="mb-2 form-group">
            <label class="form-label" for="phone">Phone</label>
            <input
              class="form-control" type="tel"
              v-model="form.phone" maxLength="20"
              required
            />
          </div>


          <div class="mb-2 form-group">
            <label class="form-label" for="seat_count">Number of Teacher Subscriptions Requested</label>
            <input
              class="form-control" type="number" name="seat_count"
              v-model="form.subscription_count" min="1"
              required
            />
          </div>

          <div class="mb-4 form-group">
            <label
              class="form-label"
              for="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
            >How
              Did You Find Out About Us?</label>
            <select
              class="form-select"
              id="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
              name="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
              v-model="form.referral"
            >
              <option value="">--Select a choice--</option>
              <option
                v-for="(how, index) in referralTypes"
                :value="how" :key="index"
              >{{ how }}
              </option>
            </select>

          </div>
          <div class="d-grid gap-2">
            <div v-if="successMessage && successMessage.length">
              <div>
                <p>{{ successMessage }}</p>
                <p>Click outside this dialog to close</p>
              </div>
            </div>
            <div v-else>
              <button
                type="submit" class="btn
                                btn-primary btn-lg"
                :disabled="form.$invalid || submitting"
              >
                <span v-if="submitting">Sending..</span>
                <span v-else>Generate Quote</span>
              </button>
            </div>
          </div>
        </form>
      </div>
      <div v-else class="card-body">
        <div class="card-title alert alert-success text-center">
          <h3>Quote Sent</h3>
        </div>
        <p>
          Thanks for your interest in Lyrics2Learn!
          Please check your email for a link to download your school's quote.
        </p>
        <p>
          You can also view your quote here:<br>
          <a :href="quote_url" :target="`quote_${invoice_number}`">L2L Quote #{{ invoice_number }}</a>.
          This link will expire in 30 days.
        </p>
        <p v-if="modal">Click anywhere outside this box to close.</p>
      </div>
    </div>
  </div>
</template>
