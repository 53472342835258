<template>
  <div>
    <div v-if="successMessage && successMessage.length">
      <h4 class="alert alert-success">{{ successMessage }}</h4>
    </div>
    <div v-if="!successMessage">
      <div v-if="!isModal" class="heading text-center">
        Submit Your Request For More Information
      </div>

      <div class="alert alert-warning text-center">
        Please Fill Out The Following Information and We Will Contact You Within 24 Hours
      </div>

      <div v-if="errorMessage" class="alert alert-error">{{ errorMessage }}</div>

      <form class="form-horizontal distict-license-request" novalidate>
        <div class="form-group">
          <label class="col-xs-4 control-label">District:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model.trim="v$.form.district.$model" type="text"/>
            <div class="error" v-for="err in v$.form.district.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Job Title:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.title.$model" type="text"/>
            <div class="error" v-for="err in v$.form.title.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">First Name:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.firstname.$model" type="text"/>
            <div class="error" v-for="err in v$.form.firstname.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Last Name:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.lastname.$model" type="text"/>
            <div class="error" v-for="err in v$.form.lastname.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">Email Address:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.email.$model" type="text"/>
            <div class="error" v-for="err in v$.form.email.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label" >Phone:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.phone.$model" type="text"/>
            <div class="error" v-for="err in v$.form.phone.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label">City:</label>
          <div class="col-xs-8">
            <input class="form-control" v-model="v$.form.city.$model" type="text"/>
            <div class="error" v-for="err in v$.form.city.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div class="form-group">
          <label class="col-xs-4 control-label" >State:</label>
          <div class="col-xs-8">
            <select class="form-control" v-model="v$.form.state.$model">
              <option value="">Please select one</option>
              <option value="AL">Alabama</option>
              <option value="AK">Alaska</option>
              <option value="AR">Arkansas</option>
              <option value="AZ">Arizona</option>
              <option value="CA">California</option>
              <option value="CO">Colorado</option>
              <option value="CT">Connecticut</option>
              <option value="DC">District of Columbia</option>
              <option value="DE">Delaware</option>
              <option value="FL">Florida</option>
              <option value="GA">Georgia</option>
              <option value="HI">Hawaii</option>
              <option value="ID">Idaho</option>
              <option value="IL">Illinois</option>
              <option value="IN">Indiana</option>
              <option value="IA">Iowa</option>
              <option value="KS">Kansas</option>
              <option value="KY">Kentucky</option>
              <option value="LA">Louisiana</option>
              <option value="ME">Maine</option>
              <option value="MD">Maryland</option>
              <option value="MA">Massachusetts</option>
              <option value="MI">Michigan</option>
              <option value="MN">Minnesota</option>
              <option value="MS">Mississippi</option>
              <option value="MO">Missouri</option>
              <option value="MT">Montana</option>
              <option value="NE">Nebraska</option>
              <option value="NV">Nevada</option>
              <option value="NH">New Hampshire</option>
              <option value="NJ">New Jersey</option>
              <option value="NM">New Mexico</option>
              <option value="NY">New York</option>
              <option value="NC">North Carolina</option>
              <option value="ND">North Dakota</option>
              <option value="OH">Ohio</option>
              <option value="OK">Oklahoma</option>
              <option value="OR">Oregon</option>
              <option value="PA">Pennsylvania</option>
              <option value="RI">Rhode Island</option>
              <option value="SC">South Carolina</option>
              <option value="SD">South Dakota</option>
              <option value="TN">Tennessee</option>
              <option value="TX">Texas</option>
              <option value="UT">Utah</option>
              <option value="VT">Vermont</option>
              <option value="VA">Virginia</option>
              <option value="WA">Washington</option>
              <option value="WV">West Virginia</option>
              <option value="WI">Wisconsin</option>
              <option value="WY">Wyoming</option>
            </select>
            <div class="error" v-for="err in v$.form.state.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div v-if="showReferral">
          <div class="form-group">
            <label class="col-xs-4 control-label">How Did You Find Out About Lyrics2Learn?</label>
            <div class="col-xs-8">
              <select class="form-control" v-model="v$.form.referral.model">
                <option value="">Please select one</option>
                <option value="Pinterest">Pinterest</option>
                <option value="A Friend Referred Me">A Friend Referred Me</option>
                <option value="Search Engine">Search Engine</option>
                <option value="Facebook">Facebook</option>
                <option value="Twitter">Twitter</option>
                <option value="Instagram">Instagram</option>
                <option value="Other">Other</option>
              </select>
            </div>
            <div class="error" v-for="err in v$.form.referral.$errors">{{ err.$message }}</div>
          </div>

          <div class="form-group">
            <label class="col-xs-4 control-label">Referral Code (optional)</label>
            <div class="col-xs-8">
              <input class="form-control" type="text" v-model="v$.form.referralCode.$model">
            </div>
            <div class="error" v-for="err in v$.form.referralCode.$errors">{{ err.$message }}</div>
          </div>
        </div>

        <div :class="isModal ? 'modal-footer' : ''">
          <div v-if="successMessage && successMessage.length">
            <a :class="successButtonClass" @click.prevent="reset()">Okay</a>
          </div>
          <div v-else>
            <a :class="successButtonClass" :disabled="shouldDisableSubmit" @click.prevent="submit()">
              <span v-if="submitting">Sending...</span>
              <span v-else>Submit</span>
            </a>

            <a v-if="isModal" class="btn btn-default" @click.prevent="cancel()">
              Cancel
            </a>
          </div>
        </div>
      </form>
    </div>
  </div>
</template>


<style lang="scss" scoped>
  .heading {
    font-size: large;
    font-weight: bold;
    margin-bottom: 1em;
  }

  label {
    font-size: 14px;
  }

  .error {
    color: red;
  }
</style>

<script>
import useVuelidate from "@vuelidate/core"
import {email, maxLength, minLength, required} from "@vuelidate/validators"

export default {
    setup() {
      return {
        v$: useVuelidate(),
      }
    },
    props: {

      isModal: {
        type: Boolean,
        required: false,
        default: false
      },

      initialReferralCode: {
        type: String,
        required: false,
        default: '',
      },

      showReferral: {
        type: Boolean,
        required: false,
        default: true
      }
    },

    data() {
      return {
        triedSubmit: false,
        submitting: false,
        showDialog: false,
        successMessage: '',
        errorMessage: '',
        form: {
          district: '',
          title: '',
          firstname: '',
          lastname: '',
          email: '',
          phone: '',
          city: '',
          state: '',
          referral: '',
          referralCode : this.initialReferralCode,
        },
      }
    },

    computed: {
      successButtonClass() {
        return this.isModal ? 'btn btn-success' : 'btn btn-success btn-lg btn-block';
      },
      shouldDisableSubmit() {
        return this.v$.form.$invalid || this.submitting;
      }
    },

    methods: {
      reset() {
        for (var k in this.form) {
          this.form[k] = ''
        }
        this.showDialog = false
      },

      cancel() {
        this.$emit('cancelled')
      },

      submit() {
        this.successMessage = ''
        this.errorMessage = ''
        this.submitting = true
        axios.post('/district_pricing', this.form)
          .then((response) => response.data)
          .then((data) => {
            if (data.success) {
              // redirect.
              this.successMessage = data.message
              this.$emit('submitted');
            } else {
              this.errorMessage = data.message
            }
          }).catch((error) => {
          try {
            if (error.response.data.errors) {
              var msg = ''
              for (var k in error.response.data.errors) {
                msg += error.response.data.errors[k][0]
              }
              this.errorMessage = msg
            }
          } catch {
            this.errorMessage = error.toString()
          }
        }).finally(() => {
          this.submitting = false
        })
      },
    },

    validations: {
      form: {
        district: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(500)
        },
        title: {
          maxLength: maxLength(200)
        },
        firstname: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        },
        lastname: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(50)
        },
        email: {
          required,
          email,
          maxLength: maxLength(100)
        },
        phone: {
          required,
          minLength: minLength(7),
          maxLength: maxLength(20)
        },
        city: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(100)
        },
        state: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(100)
        },
        referral: {
        },
        referralCode : {
        },
      }
    }
  }
</script>
