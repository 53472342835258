<template>
  <div>
    <!-- Note: we do calculate averages, but it's not actually useful given the distribution (including zeros) -->
    <table>
      <thead>
      <tr>
        <th><!-- blank --></th>
        <th colspan>Last 30 Days</th>
        <th colspan>School Year</th>
      </tr>
      </thead>
      <tbody>
      <tr>
        <th>Student Learning</th>
        <td>{{minutes(thirtyDaySummary.student_learning_time.total)}}</td>
        <td>{{minutes(yearSummary.student_learning_time.total)}}</td>
      </tr>
      <tr>
        <th>Teacher Investment</th>
        <td>{{minutes(thirtyDaySummary.teacher_investment_time.total)}}</td>
        <td>{{minutes(yearSummary.teacher_investment_time.total)}}</td>
      </tr>
      <tr>
        <th>Active Students</th>
        <td>{{thirtyDaySummary.active_students.total}}</td>
        <td>{{yearSummary.active_students.total}}</td>

      </tr>
      <tr>
        <th>Active Teachers</th>
        <td>{{thirtyDaySummary.active_teachers.total}}</td>
        <td>{{yearSummary.active_teachers.total}}</td>

      </tr>
      <tr>
        <th>Fluency Practice</th>
        <td>{{minutes(thirtyDaySummary.minutes_spent_reading.total)}}</td>
        <td>{{minutes(yearSummary.minutes_spent_reading.total)}}</td>

      </tr>
      <tr>
        <th>Comprehension Practice</th>
        <td>{{minutes(thirtyDaySummary.comprehension_practice_time.total)}}</td>
        <td>{{minutes(yearSummary.comprehension_practice_time.total)}}</td>

      </tr>
      <tr>
        <th>Fiction Lessons Completed</th>
        <td>{{thirtyDaySummary.fiction_lessons_complete.total}}</td>
        <td>{{yearSummary.fiction_lessons_complete.total}}</td>

      </tr>
      <tr>
        <th>Non-Fiction Lessons Completed</th>
        <td>{{thirtyDaySummary.nonfiction_lessons_complete.total}}</td>
        <td>{{yearSummary.nonfiction_lessons_complete.total}}</td>
      </tr>

      <tr>
        <th>Total Lessons Completed</th>
        <td>{{thirtyDaySummary.fiction_lessons_complete.total + thirtyDaySummary.nonfiction_lessons_complete.total}}</td>
        <td>{{yearSummary.fiction_lessons_complete.total + yearSummary.nonfiction_lessons_complete.total}}</td>
      </tr>

      </tbody>
    </table>
  </div>
</template>

<style scoped>

tbody th {
  text-align: center;
}

</style>

<script>

export default {

  components: {},

  props: {

    yearSummary: {
      type: Object,
      required: true,
    },

    thirtyDaySummary: {
      type: Object,
      required: true,
    },

  },

  data() {
    return {
      // ...
    }
  },

  computed: {
    // ...
  },

  methods: {
    minutes(minutes) {
      return minutes.toLocaleString() + ' minutes'
    }
  },

  created() {
    // ...
  },

  mounted() {
    // ...
  }

}
</script>
