<style lang="scss" scoped>
    .row {
        &:before, &:after {
            display: inline-block !important;
        }
    }
</style>

<template>
    <LeftyPopup v-if="showPopup" @hide="hidden()">
        <div v-if="text" v-html="text">
        </div>
        <div v-else>
            <video style="width: 100%" ref="player" controls autoplay>
                <source v-for="(video) in videos" :type="video.type" :src="video.path"/>
            </video>
            <div v-if="showNavButtons" class="row" style="margin-top: 10px;">
                <div class="col-xs-7">
                    <a v-if="showLeftNav"
                        class="btn btn-success btn-lg"
                        :href="navCompleteHref"
                        @click="dismiss()"
                    >
                        {{navCompleteLabel}}
                    </a>
                </div>
                <div class="col-xs-5" style="text-align: right;">
                    <a v-if="showRightNav"
                       :href="navContinueHref"
                       class="btn btn-lg btn-warning"
                    >
                        {{navContinueLabel}}
                    </a>
                </div>
            </div>
        </div>
    </LeftyPopup>
</template>

<script>
  import LeftyPopup from "~components/LeftyPopup.vue"

  export default {
    components: {LeftyPopup},

    props: {
      name: {		// e.g. 'student_dashboard', 'teacher_reports'
        type: String,
        required: true,
      },

      navButtons: {
        type: String,
        required: false,
        default: "none"
      },

      navCompleteLabel: {
        type: String,
        required: false,
        default: "Complete This Step",
      },

      navCompleteHref: {
        type: String,
        required: false,
        default: '#',
      },

      navContinueLabel: {
        type: String,
        required: false,
        default: "Continue Tour",
      },

      navContinueHref: {
        type: String,
        required: false,
        default: '#',
      },
    },
    data() {
      return {
        requested: false,
        showPopup: false,
        videos: undefined,
        text: undefined,
      };
    },

    computed: {
      showNavButtons() {
        return this.navButtons !== "none"
      },

      showLeftNav() {
        return this.navButtons === "left" || this.navButtons === "both"
      },

      showRightNav() {
        return this.navButtons === "right" || this.navButtons === "both"
      },
    },

    created() {
      this.load()
    },
    methods: {
      player() {
        return this.$refs.player;
      },
      hidden() {
        // if it's hidden, we need to stop the video.
        this.player() && this.player().pause();
      },
      dismiss() {
        this.showPopup = false;
      },
      load() {
        axios.post(`/media/tutorials/${this.name}`)
          .then((response) => response.data)
          .then((data) => {
            if (data.success) {
              const tut = data.data
              this.videos = tut.videos;
              this.text = tut.text
              this.showPopup = tut.show;
            } else {
              console.warn(data);
            }
          }).catch(err => {
          console.error(err)
        })
        ;
      }
    }
  }
</script>
