<template>
<div class="panel-group">

  <section class="wizard-step">
    <header>Select a Template</header>
    <vue-select
      id="template-select"
      v-model="selectedTemplate"
      :options="options"
      :searchable="false"
      placeholder="Please select a template..."
      label="title"
      style="max-width: 240px;"
    >
    </vue-select>
  </section>

  <section v-if="selectedTemplate" class="wizard-step">
    <header>Enter your message</header>
    <div>
      <label for="subject-input">Subject:</label>
      <input id="subject-input" v-model="finalSubject" placeholder="Please enter a subject">
    </div>

    <div v-if="messageEditorEnabled">

      <p class="editor-guide">The selected template allows for a custom message. Enter anything you'd like to add below.</p>

      <label for="custom-message-input">Custom Message:</label>
      <html-editor id="custom-message-input" v-model="custom_message"></html-editor>
    </div>

      <p class="editor-guide">Use the Preview button below to see a sample of what the email will look like.</p>

  </section>

  <section class="wizard-step" v-if="selectedTemplate">
    <button class='btn btn-info' @click="preview" :disabled="!actionsEnabled">
      Preview
    </button>
    <button class='btn btn-success' @click="submit" :disabled="!sendEnabled">
      {{sendButtonLabel}}
    </button>
  </section>

</div>

</template>

<style lang="scss" scoped>
  #subject-input {
    width: 100%;
  }

  .editor-guide {
    margin: 1em 2em;
    font-weight: normal;
    font-size: small;
    font-style: italic;

  }
</style>


<script>

  import HtmlEditor from "./widgets/HtmlEditor.vue";

  export default {

    components: {HtmlEditor},

    props: {
      templates: {
        type: Array,
        required: true,
      },
      recipients: {
        type: Array,
        required: true,
      }
    },

    data() {
      return {
        selectedTemplate: null,
        subject: '',
        custom_message: '',

        actionsEnabled: true,
        subjectWasEdited: false,
      }
    },

    computed: {

      options() {
        return this.templates
      },

      sendButtonLabel() {
        const len = this.recipients.length
        const suffix = len > 1 ? 'es' : '';
        return len ? `Send to ${len} address${suffix}` : "Select recipients above"
      },

      sendEnabled() {
        return (
          this.actionsEnabled
          && this.recipients.length > 0
          && this.finalSubject
          && (this.custom_message || !this.messageEditorEnabled)
        )
      },

      messageEditorEnabled() {
        return !!(this.selectedTemplate && this.selectedTemplate.token_list.match(/custom_message/))
      },

      finalSubject: {
        get() {
          if (this.subjectWasEdited) {
            return this.subject
          }
          return this.subject || this.selectedTemplate.subject
        },
        set(val) {
          this.subject = val
          this.subjectWasEdited = true  // so it doesn't reset if we clear it
        }
      }

    },

    methods: {

      submit() {
        this.actionsEnabled = false
        this.$emit('submit', {
          template: this.selectedTemplate,
          subject: this.finalSubject,
          custom_message: this.custom_message,
        })
      },

      preview() {
        this.$emit('preview', {
          template: this.selectedTemplate,
          subject: this.finalSubject,
          custom_message: this.custom_message,
        })
      }


    },

  }


</script>
