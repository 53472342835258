<script setup lang="ts">
import axios from "axios"
import {computed} from "vue";
import {useFetcher} from "@/common/utils/fetch";

const props = defineProps<{
  detailRoute: string
  extensionRoute: string
  resetRoute: string
  startDate: string
  endDate: string
  studentId: string
}>()

// useFetch doesn't have any api for reactive query parameters, so we just build the string raw
const rowsEndpoint = computed(() => `/teacher/reports/seven_day/${props.studentId}/rows?start_date=${props.startDate}&end_date=${props.endDate}`)
const rowsQuery = useFetcher(rowsEndpoint).get().json()
const rows = computed(() => rowsQuery.data?.value ?? [])

function detailUrl(id: string) {
  return props.detailRoute
    .replace("STUDENT_ID", id)
    .replace("START_DATE", props.startDate)
    .replace("END_DATE", props.endDate)
}

function extensionUrl(student_id: string|number, lesson_id: string|number) {
  return props.extensionRoute
    .replace("STUDENT_ID", student_id.toString())
    .replace("LESSON_ID",  lesson_id.toString())
}

function onClickReset(student_id: string|number) {
  if (!confirm("Are you sure you want to reset the scores for this student?")) return // TODO: use a modal
  resetAllLessons(student_id)
}

function resetAllLessons(student_id: string|number) {
  const url = props.resetRoute.replace("STUDENT_ID", student_id.toString())
  axios.post(url, {start_date: props.startDate, end_date: props.endDate})
    .then(() => window.location.reload())
}
</script>

<template>
  <div>
    <!--
      We can't use .card or .card-table because it screws up the thead such that the table headers no longer line up
      with the table columns.  I can't find a fix so far, and I won't waste more time fighting our wretched legacy css.

      <table class="table table-bordered card card-table table-responsive table-striped table-light">
    -->
    <table class="table table-bordered table-responsive table-striped table-light">
      <thead class="text-light text-center">
      <tr>
        <th>Student</th>
        <th>Lessons</th>
        <th>Lesson Days Completed</th>
        <th>Avg. WPM</th>
        <th>Avg. Lexile</th>
        <th>Day 1 Literal</th>
        <th>Day 2 Inference</th>
        <th>Day 3 Complex</th>
        <th>Critical Thinking Link</th>
        <th><span class="non-print">Reset</span></th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="row in rows" :key="row.student_id">
        <td class="name">
          <a :href="detailUrl(row.student_id)" :target="`rpt_det_${row.student_id}`">{{ row.fullname }}</a>
        </td>
        <td class="lessons">{{ row.titles.join(", ") }}</td>
        <td class="days">{{ row.days_completed }}</td>
        <td class="avg">{{ row.avg_wpm }}</td>
        <td class="avg">{{ row.avg_lexile }}</td>
        <td class="avg">{{ row.avg_scores[0] }}</td>
        <td class="avg">{{ row.avg_scores[1] }}</td>
        <td class="avg">{{ row.avg_scores[2] }}</td>
        <td class="extensions">
          <ul>
            <li v-for="extension in row.extensions" class="extension">
              <a
                :key="extension.id"
                :href="extensionUrl(row.student_id, extension.id)"
                :target="`ext_${row.student_id}_${extension.id}`"
              >
                Ext.&nbsp;[{{ extension.title }}]
              </a>
            </li>
          </ul>
        </td>
        <td>
          <b-button variant="soft-danger" class="non-print btn-sm p-2" @click="onClickReset(row.student_id)">
            <i class="bi bi-trash"></i>
          </b-button>
        </td>
      </tr>
      </tbody>
    </table>

  </div>
</template>

<style lang="scss" scoped>
td {
  &.name {
    white-space: nowrap;
  }

  &.lessons {
    font-size: small;
  }

  &.extensions {
    font-size: small;

    ul {
      list-style: none;
      padding: 0;
    }
  }
}
</style>
