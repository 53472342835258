<script>
import FluencyShareForm from "~components/teacher/fluency/FluencyShareForm.vue"

export default {
  components: {FluencyShareForm},
  props: {
    videoId: {type: Number, required: true},
  },

  data() {
    return {
      showDialog: false,
    }
  },

  methods: {
    onDone() {
      this.showDialog = false
      this.$emit('done')
    },

    onCancelled() {
      this.showDialog = false
      this.$emit('cancelled')
    },

    onError(err) {
      this.showDialog = false
      this.$emit('error', err)
    },
  }
}
</script>

<template>
  <div>
    <b-button variant="info" @click="showDialog = true">Share with Parent</b-button>
    <b-modal v-model="showDialog" hide-header hide-footer>
      <FluencyShareForm
        :video-id="videoId"
        @done="onDone"
        @cancelled="onCancelled"
        @error="onError"
      />
    </b-modal>
  </div>
</template>
