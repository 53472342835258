<template>
  <div class="container-fluid">


    <div class="row">
      <div class="col-4 student-drag-column card">
        <p v-if="!students.length" class="no-students">
          All students have been placed into groups
        </p>
        <div class="students">
          <input class="form-control filter-student-list" v-model="filter" placeholder="Filter student list..." />

          <drag v-for="student in filteredStudents()"
                :key="student.userId"
                class="draggable-student"
                :transfer-data="student">
              {{ student.name }}
          </drag>
        </div>
      </div>

      <div class="col-8 student-drop-column card">
        <div v-for="(group, groupId) in groups" class="row noselect" :key="groupId">
          <div class="col-md-12">
            <drop @drop="function(transferData) { handleDrop(transferData, group, groupId) }"
                  @dragenter="dragEnter"
                  @dragleave="dragExit"
                  class="droppable-group">
              <div class="row">
                <div class="col-md-6">
                  <h2 class="p">
                    {{ group.details.name }}
                  </h2>
                </div>

                <div class="col-md-6">
                  <a href="#" class="pull-right group-collapser" @click.prevent="group.collapsed = !group.collapsed">
                    <span v-if="group.collapsed">Expand Group</span>
                    <span v-else>Collapse Group</span>
                  </a>
                </div>
              </div>

              <div class="content" v-if="!group.collapsed">
                <hr />
                <div class="group-students">
                  <p v-if="group.memberships.length==0" class="no-students">
                    No students have been added to this group.
                  </p>
                  <div class="students">
                    <div v-for="membership in group.memberships" class="draggable-student">
                      {{ membership.name }}
                      <a href="#" class="delete-membership" @click.prevent="deleteMembership(group, membership)">
                        <i class="bi bi-trash "></i>
                      </a>
                    </div>
                  </div>
                  <div class="clearfix"></div>
                </div>
              </div>
            </drop>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Drag, Drop} from "vue-drag-drop"

export default {
  components: {
    drag: Drag,
    drop: Drop,
  },
  mounted() {
    // fixme: need to reload if the students change! (how do i either communicate or only do this when I become visible?)
    console.log("Component mounted.")
    this.loadGroups()
  },
  data() {
    return {
      loading: false,
      groups: [],
      students: [],
      filter: "",
      saveCount: 0,
    }
  },
  computed: {
    sortedStudents: function() {
      function compare(a, b) {
        if (a.lastname < b.lastname)
          return -1
        if (a.lastname > b.lastname)
          return 1
        return 0
      }

      return this.students.sort(compare)
    },
  },
  methods: {
    dragEnter(data) {
      console.log("Drag Enter")
    },
    dragExit(data) {
      console.log("Drag Exit")
    },
    filteredStudents() {
      if (this.filter == "") {
        return this.sortedStudents
      } else {
        var result = []
        var model = this
        this.sortedStudents.forEach(function(entry) {
          if (entry.name.toLowerCase().indexOf(model.filter.toLowerCase()) !== -1) {
            result.push(entry)
          }
        })

        return result
      }
    },
    sortedMembership(group) {
      function compare(a, b) {
        if (a.lastname < b.lastname)
          return -1
        if (a.lastname > b.lastname)
          return 1
        return 0
      }

      return group.memberships.sort(compare)
    },
    handleDrop(student, group, groupId) {
      // remove from students...
      let index = this.students.indexOf(student)
      this.students.splice(index, 1)

      //student.userMembershipId = xx;
      group.memberships.push(student)
      group.memberships = this.sortedMembership(group)

      this.updateMembership(student.userId, group.details.id)
      // FIXME: perist.
      console.log("Drag Drop")
      console.log(`You dropped with data ${JSON.stringify(student)}`)
      console.log(`Destination Group ${JSON.stringify(group)}`)
      console.log(`Destination GroupID ${groupId}`)
    },
    deleteMembership(group, membership) {
      let index = group.memberships.indexOf(membership)
      group.memberships.splice(index, 1)
      delete membership.userMembershipId

      // FIXME: Persist
      this.updateMembership(membership.userId, 0)

      this.students.push(membership) // resort?
    },
    updateMembership(studentId, groupId) {
      this.saveCount++
      axios.post("/teacher/students/groups", {verb: "update", studentId: studentId, groupId: groupId})
        .then((response) => response.data)
        .then((data) => {
          var groups = data.groups
          // for(var key in groups) {
          //   groups[key].collapsed = false;
          // }
          // this.groups = groups;
          // this.students = data.ungroupedStudents;
        }).catch((error) => {
        this.errorMessage = error
      }).finally(() => {
        this.saveCount--
      })
    },
    loadGroups() {
      this.loading = true
      axios.post("/teacher/students/groups", {verb: "list"})
        .then((response) => response.data)
        .then((data) => {
          var groups = data.groups
          for (var key in groups) {
            groups[key].collapsed = false
          }
          this.groups = groups
          this.students = data.ungroupedStudents
        }).catch((error) => {
        this.errorMessage = error
      }).finally(() => {
        this.loading = false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../sass/bs5_theme_vars";

.draggable-student {
  width: 100%;
  height: 2rem;
  border: 1px solid #ccc;
  background: #fafafa;
  border-radius: 2px;
  padding: 0.25rem;
  margin: 0.25rem;
  cursor: grab;

  &:active {
    cursor: grabbing;
  }
}


.droppable-group {
  width: 100%;
  min-height: 50px;
  border-radius: 8px;
  border: 1px solid #ccc;
  margin: 0 auto 10px;
  padding: 10px;
}

.droppable-group h2 {
  margin: 0;
  padding: 0;
}

.droppable-group hr {
  margin: 10px 0;
}

.student-drag-column {
  padding-top: 10px;
}

.student-drop-column {
  padding-top: 10px;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none;
  /* Non-prefixed version, currently
                                   supported by Chrome and Opera */
}

a.delete-membership {
  float: right;
}

a.delete-membership i {
  color: $bs-red;
}

.group-collapser {
  line-height: 33px;
}

.filter-student-list {
  margin-bottom: 10px;
}

.tip {
  font-size: small;
  font-style: italic;
}

</style>
