
<template>
  <span class="whats-this" :title="text">
    <!-- b-tooltip is so broken it's better to just use the browser's behavior on titles -->
    <!--<span class="whats-this" v-b-tooltip.hover :title="text">-->
    <slot>
      <i class="whats-this-icon bi bi-question-circle"></i>
    </slot>
  </span>
</template>

<style scoped>
.whats-this-icon {
  color: deepskyblue;
  font-size: small;
  cursor: help;
}
</style>

<script>
export default {
  name: "WhatsThis",

  props: {
    text: String,
    default: "",
  },
}
</script>
