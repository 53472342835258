<template>
  <div :class="classes">
    <slot></slot>
  </div>
</template>

<style lang="scss" scoped>
    .banner {
        font-size: large;
    }
</style>

<script>
  export default {
    name: "Banner",

    props: {
      type: {
        type: String,
        required: false,
        default: 'info',
      }
    },

    computed: {
      classes() {
        return ['alert', `alert-${this.type}`]
      }
    }
  }
</script>
