<script setup lang="ts">
import {computed} from "vue"
import {FluencyRecording, FluencyGrade} from "~app/fluency"
import {useVModels} from "@vueuse/core"
import {toggle_include} from "@/common/utils"
import {FluencyGraderState} from "~components/teacher/fluency/FluencyGrader.vue"

const props = defineProps<{
  reading: FluencyRecording
  grade: FluencyGrade
  state: FluencyGraderState,
}>()

const emit = defineEmits([])

const {grade} = useVModels(props, emit)

const grading = computed(() => props.state === "grade")

const isBenchmark = computed(() => props.reading.classification === "benchmark")
const isColdRead = computed(() => props.reading.classification === "cold_read")
// const isHotRead = computed(() => props.reading.classification === "hot_read")
const errorClass = computed(() => (isBenchmark.value || isColdRead.value) ? "cold" : "hot")
const needsWPM = computed(() => props.reading.classification === "benchmark" || props.reading.lyrics_only == true) // FIXME

const lyricsHtml = computed(() => {
  let html = props.reading.lesson.lessontext

  grade.value.selectedWordIds.forEach((id: string) =>
    html = html.replace(`"${id}" class="l2l-word`, `"${id}" class="l2l-word ${errorClass.value}`),
  )

  const wpm = grade.value.wpm
  if (needsWPM.value && wpm) {
    html = html.replace(`"${wpm}" class="l2l-word`, `"${wpm}" class="l2l-word wpm-marker `)
  }

  return html
})

function onClick(event: any) {
  if (!grading.value) return
  const parent = window.getSelection()?.anchorNode?.parentNode as HTMLElement
  if (!parent?.classList?.contains("l2l-word")) return
  const id = parent.getAttribute("l2l-id")
  if (!id) return

  if (event.metaKey || event.altKey) {
    if (needsWPM.value) grade.value.wpm = parseInt(id)
  } else {
    grade.value.selectedWordIds = toggle_include(grade.value.selectedWordIds, id)
    grade.value.accuracy = -grade.value.selectedWordIds.length
  }
}

function onContextMenu() {
  // no-op to disable context menu
}
</script>

<template>
  <div
    class="lyrics-wrapper"
    :class="{grading}"
    @click="onClick($event)"
    @contextmenu.prevent="onContextMenu"
  >
    <section v-if="grading" class="grading-instructions">
      <header>Grading Instructions</header>
      <ul>
        <li>
          Click on words missed to track accuracy. Do not click for self-corrections.
        </li>
        <li v-if="needsWPM">
          <b>PC:</b> Hold down the <kbd>Alt</kbd> or <kbd>🔎</kbd> key and click the last word read for total WPM.
        </li>
        <li v-if="needsWPM">
          <b>Mac:</b> Hold down the <kbd>⌥</kbd> or <kbd>⌘</kbd> key and click the last word read for total WPM.
        </li>
      </ul>
      <hr>
    </section>

    <div class="lyrics">
      <h1>{{ reading.lesson.title }}</h1>
      <div v-html="lyricsHtml"></div>
    </div>
  </div>
</template>

<style lang="scss">
@import "../../../../sass/bs5_theme_vars";

$cold: rgba(0, 200, 200);
$hot: rgba(255, 0, 0);

// Global style for CSS classes that are modified dynamically (TODO: use ::deep instead)
.lyrics-wrapper {
  &.grading {
    .l2l-word {
      cursor: pointer;

      &:hover {
        background-color: #eee;

        &.cold {
          background-color: $cold;
        }

        &.hot {
          background-color: $hot;
        }
      }
    }
  }

  .wpm-marker {
    border: 2px solid $bs-red;
  }

  .cold {
    background-color: transparentize($cold, 0.5);
  }

  .hot {
    background-color: transparentize($hot, 0.5);
  }

  h1 {
    font-size: 20px;
    font-style: oblique;
    text-align: center;
    color: black;
  }

  p {
    margin: 0 0 1.3em;
    font-size: 1.1em;
  }

  .grading-instructions {
    font-size: small;

    header {
      font-size: medium;
      font-weight: bold;
      text-align: center;
    }

    ul {
      list-style-type: none;
      font-style: italic;

      li {
        margin-top: 0.5rem;
      }
    }
  }
}
</style>
