<script>
import {ArcElement, Chart as ChartJS} from "chart.js"
import {Doughnut} from "vue-chartjs"
import {computed} from "vue"

export default {
  components: {Doughnut},

  props: {
    amount: {
      type: Number,
      required: true,
    },
    total: {
      type: Number,
      default: 100, // amount is a percent by default
    },
    title: {
      type: String,
      default: "%s%%",
    },
    titleColor: {
      type: String,
      default: "black",
    },
    titleSize: {
      type: String,
      default: "1.5em",
    },
    caption: {
      type: String,
      default: "",
    },
    captionColor: {
      type: String,
      default: "#888",
    },
    captionSize: {
      type: String,
      default: "0.75rem",
    },
    radius: {
      type: Number,
      default: 125,
    },
    color: {
      type: String,
      default: "#41B883",
    },
    remainderColor: {
      type: String,
      default: "transparent",
    },
    animationDuration: {
      type: Number,
      default: 2000,
    },
    thickness: {
      type: Number,
      default: 10,
    },
  },

  setup(props) {
    ChartJS.register(ArcElement)

    const title = computed(() => props.title
      .replaceAll("%%", "@@@###@@@")
      .replaceAll("%s", props.amount)
      .replaceAll("@@@###@@@", "%"),
    )

    const titlePosition = computed(() => props.caption ? "25%" : "50%")
    const captionPosition = computed(() => props.caption ? "65%" : "50%")

    return {
      title,
      titlePosition,
      captionPosition,
      data: {
        datasets: [
          {
            backgroundColor: [props.color, props.remainderColor],
            data: [props.amount, props.total - props.amount],
          },
        ],
      },
      options: {
        cutout: props.radius * ((100 - props.thickness) / 100),
        borderWidth: 0,
        borderRadius: 50,
        responsive: true,
        maintainAspectRatio: false,
        animation: {
          duration: props.animationDuration,
        },
      },
    }
  },
}
</script>

<template>
  <div class="circle-chart">
    <div class="chart">
      <Doughnut
        chart-id="doughnut-chart"
        dataset-id-key="label"

        :options="options"
        :data="data"
        :width="radius * 2"
        :height="radius * 2"

        v-bind="$attrs"
      />
    </div>
    <div class="overlaid title">{{ title }}</div>
    <div class="overlaid caption">{{ caption }}</div>
  </div>
</template>

<style scoped lang="scss">
.circle-chart {

  position: relative;
  display: inline-block;

  .overlaid {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  .title {
    color: v-bind(titleColor);
    font-size: v-bind(titleSize);
    top: v-bind(titlePosition);
    line-height: 0.9;
    text-align: center;
  }

  .caption {
    color: v-bind(captionColor);
    font-size: v-bind(captionSize);
    top: v-bind(captionPosition);
    line-height: 1;
    text-align: center;
  }
}
</style>
