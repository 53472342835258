<template>
<div ref="reportGraph">
	<div v-show="loading">Loading...</div>
	<div v-show="!loading">
		<div v-if="nodata">
			<h4>{{ noDataMessage }}</h4>
		</div>
		<GChart
			v-else
			:settings="{ packages: ['corechart', 'line' ] }"
			:resizeDebounce="0"
			type="LineChart"
			:data="chartData"
			:options="chartOptions"
			:events="chartEvents"
			@ready="onChartReady"
		/>
	</div>
</div>
</template>

<script>
import { GChart } from 'vue-google-charts/legacy'
import { EventBus } from '../../../../event_bus.js';

export default {
	components: {
		GChart
	},
	mounted() {
		this.chartOptions = this.defaultChartOptions;
        EventBus.$emit('graph-create', {
            type: this.type,
            from: this.from,
            to: this.to,
            student_id: this.student_id,
            image: 'none',
            id: this._uid
        });

		this.load();
	},
	data() {
		return {
			loading: false,
			nodata: false,
            noDataMessage: '',
			chartOptions: {},
			chartEvents: {
				'ready': () => {
					// handle event here
					var new_chart_img = this.chart.getImageURI();

					// now we need to send this up the line.
					EventBus.$emit('graph-ready', {
						type: this.type,
						from: this.from,
						to: this.to,
						student_id: this.student_id,
                        image: new_chart_img,
                        id: this._uid
					});
				}
			},
			chartData: [
/*
				['Week', 'Cumulative Time Spent'],
				[new Date('2018-07-10'), 3],
				[new Date('2018-07-11'), 4],
				[new Date('2018-07-19'), 7],
				[new Date('2018-08-08'), 8],
				[new Date('2018-08-11'), 11],
				[new Date('2018-08-13'), 11],
				[new Date('2018-08-14'), 11],
				[new Date('2018-08-17'), 11],
				[new Date('2018-08-22'), 18],
				[new Date('2018-08-23'), 24],
				[new Date('2018-08-31'), 36],
				[new Date('2018-09-05'), 36],
				[new Date('2018-09-12'), 36],
				[new Date('2018-09-13'), 37],
				[new Date('2018-09-17'), 74],
				[new Date('2018-09-19'), 119],
				[new Date('2018-09-24'), 134],
				[new Date('2018-10-08'), 135],
				[new Date('2018-10-09'), 135]
*/
			]
		}
	},
	props: {
		type: {
			type: String,
			required: false,
			default: 'fluency'
		},
		from: {
			type: String,
			required: false,
			default: '2000-01-01'
		},
		to: {
			type: String,
			required: false,
			default: '2500-01-01'
		},
		student_id: {
			type: String,
			required: false,
			default: 'fluency'
		},
		aggregateType: {
			type: String,
			required: false,
			default: 'class'
		},
		schoolId: {
			type: String,
			required: false
		},
		districtId: {
			type: String,
			required: false
		},
		teacherId: {
			type: String,
			required: false
		}
    },
	computed: {
		defaultChartOptions() {
			return {
				// chartArea: {
				// 	left: 40,
				// 	width: '100%'
				// },
                // width: '100%',
                width: 200,
//                height: 100,
				pointSize: 3,
				legend: {position: 'none'},
				curveType: 'function',
				hAxis: {
					textStyle: {
						fontSize: 9 // or the number you want
					},
				},
				vAxis: {
					// title: 'Practice Time In Minutes', // '{{ $vAxis }}',
					viewWindow: {
						min: 0,
					}
				}
			};
		}
	},
	methods: {
		load() {
			this.loading = true;
			var params = {
				type: this.type,
				student_id: this.student_id,
				from_date:  this.from,
				to_date: this.to,
				aggregate_type: this.aggregateType
            }
            switch(this.aggregateType) {
				case 'class':
					if(this.teacherId) {
						params.teacher_id = this.teacherId;
					}
					break;
				case 'school':
					if(this.teacherId) {
						params.teacher_id = this.teacherId;
					}
					params.school_id = this.schoolId;
					break;
				case 'district':
					params.district_id = this.districtId;
					break;
			}

			axios.get('/teacher/reports/graph', {params})
			.then((response) => response.data)
			.then((data) => {
				this.chartOptions = this.defaultChartOptions;
				this.noDataMessage = data.noDataMessage;

				if(this.type=='fluency' || this.type=='quiz_complete') {
					this.chartOptions.vAxis.title = data.vAxis;
				} else {
					this.chartOptions.series = {
						0: { targetAxisIndex: 0},
						1: { targetAxisIndex: 1}
					};
					this.chartOptions.vAxes = {
						0: { title: 'WPM' },
						1: { title: 'Accuracy' }
					};
				}
				this.chartData = data.data.map((element, index) => {
					if(index==0) {
						// return the header unchanged
						return element;
					} else {
						var x = element;
						// convert first field to dates, leave the others alone.
						if(this.type == 'fluency' || this.type=='quiz_complete')
						{
							x[0] = new Date(element[0])
						}

						return x;
					}
				});

                this.nodata = (this.chartData.length==1);

                // set the size based on the size of our container.
                this.chartOptions.width = this.$refs.reportGraph.clientWidth;

                // maybe?
                window.addEventListener('beforeprint', (evt) => {
                    this.chartOptions.width = this.$refs.reportGraph.clientWidth;
                });

                // this might should debounce
                window.addEventListener('resize', (evt) => {
                    this.chartOptions.width = this.$refs.reportGraph.clientWidth;
                });

				if(this.nodata) {
					// now we need to send this up the line.
					EventBus.$emit('graph-ready', {
						type: this.type,
						from: this.from,
						to: this.to,
						student_id: this.student_id,
                        image: 'none',
                        id: this._uid
                    });
				}
			}).catch((error) => {
				console.error(error);
			}).finally(() => {
				this.loading = false;
			});
        },
		onChartReady (chart, google) {
			this.chart = chart;
			this.google = google;
		}
	}
}
</script>
