<script>
import LessonTile from "~components/LessonTile.vue"
import useVuelidate from "@vuelidate/core"
import {maxLength, minLength, required} from "@vuelidate/validators"
import Datatable from "~components/datatable/DataTable.vue"
import StudentFeedbackForm from "~components/StudentFeedbackForm.vue"
import LeftyPopup from "~components/LeftyPopup.vue"

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },
  components: {StudentFeedbackForm, Datatable, LessonTile, LeftyPopup},

  mounted() {
    this.loadStudents()
    this.loadParameters()
  },
  props: {
    hasFluency: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      SAMPLE_LESSON_IDS: [157, 3, 118, 122, 44, 189],

      loading: false,
      columns: [
        {label: "First Name", field: "firstname"},
        {label: "Last Name", field: "lastname"},
        {label: "Reading Level", field: "planid"},
        {label: "Username", field: "username"},
        {label: "Password", field: "password", sortable: false},
        {label: "More Settings", field: "password", sortable: false},
        {label: "Delete", field: "password", sortable: false},
      ],
      students: [],
      newids: [],
      original: [],
      filter: "",
      maximumStudents: 5,
      savingCount: 0,
      autocreateCount: [],
      autoCreating: false,
      autocreate: {
        count: "",
      },
      errors: [],

      promptForChange: {"firstname": [], "lastname": []},
      detailRows: {},

      startedEmpty: false,  // latches true if autocreate screen was shown
      updatedOne: false,    // latches true when a student is updated
      sendingCards: false,
      cardsSent: false,
      sendCardsResponse: undefined,

      sampleLesson: {
        show: false,
        lesson: undefined,
      },
    }
  },
  validations: {
    autocreate: {
      count: {
        required,
      },
    },
    username: {
      // This is constrained by the <input> already, but belt-and-suspenders, right?
      required,
      minLength: minLength(1),
      maxLength: maxLength(25),
    },
  },
  // validations: {
  //   emails: {
  //     required,
  //     $each: {
  //       value: {
  //         required,
  //         email
  //       }
  //     }
  //   }
  // },
  computed: {
    studentsRemaining() {
      return this.maximumStudents - this.students.length
    },
    allRowsComplete() {
      return this.students.every(this.isRowComplete)
    },
    studentsWithNewLast() {
      let oldStudents = this.students.filter(student => !this.newids.includes(student.id))
      let newStudents = this.newids.map(id => this.students.find(s => s.id === id))
      return [...oldStudents, ...newStudents]
    },
    sampleLessonDialogTitle() {
      var result = "Choose a Lesson to Try"
      if (this.sampleLesson.lesson) {
        result = this.sampleLesson.lesson.ltitle
      }
      return result
    },
  },
  methods: {
    addStudentRow() {
      this.autocreateUsers(1)
    },
    checkChange(row, fieldName) {
      var changed = false
      var model = this

      // check to see if any other rows have this username
      let validUsername = this.isUniqueRowUsername(row)

      if (!validUsername && fieldName == "username") {
        // simulate a server side validation error.
        this.removeErrorForStudentId(row.id)
        this.errors.push({
          id: row.id,
          errors: {
            "student.username": ["This username is already in use."],
          },
        })
      }

      for (let field of ["firstname", "lastname"]) {
        console.log("checkChange row." + field, row[field])
      }

      this.original.forEach(function(item, index) {
        if (item.id == row.id) {
          if (item.firstname != row.firstname
            || item.lastname != row.lastname
            || item.planid != row.planid
            || item.username != row.username
            || item.password != row.password
            || item.parent_email != row.parent_email
            || item.require_3x != row.require_3x
            || item.require_retake != row.require_retake
            || item.slow_video != row.slow_video
            || item.enable_fluency_recording != row.enable_fluency_recording
            || item.fluency_recording_lyrics_only != row.fluency_recording_lyrics_only
          ) {
            if (row.firstname.length > 0 && row.username.length > 0 && row.password.length > 0) {
              // copy the "new" original
              model.original[index] = JSON.parse(JSON.stringify(row))
              changed = true
            } else {
              console.log("Changed but invalid")
            }
          }
        }
      })

      if (!validUsername && changed) {
        console.log("Changed but username is invalid!")
        changed = false
      }

      if (changed) {
        this.saveRow(row)
      } else {
        console.log("Unchanged!")
      }
    },

    async deleteStudent(row) {
      const title = `Delete ${row.firstname} ${row.lastname}?`
      const message = "This student and their history will be permanently deleted. Continue?"

      const confirmed = await this.$bvModal.msgBoxConfirm(message, {title, okTitle: "Delete", okVariant: "danger"})
      if (!confirmed) return

      var index = this.students.indexOf(row)
      delete this.detailRows[row.id]

      // remove them from the arrays.
      this.students.splice(index, 1)
      this.original.splice(index, 1)

      this.newids = this.newids.filter(id => id !== row.id)

      this.serializeRow(row, "delete")

      // this.$uiv_notify({
      //   type: 'success',
      //   content: 'Delete completed.'
      // })
      // })
      // .catch(() => {
      //   this.$uiv_notify('Delete canceled.')
      // })
    },

    saveRow(row) {
      this.serializeRow(row, "save")
    },

    isUniqueRowUsername(row) {
      var isUnique = true
      this.original.forEach(function(entry) {
        if (entry.id != row.id) {
          if (entry.username.toLowerCase() == row.username.toLowerCase()) {
            isUnique = false
          }
        }
      })

      return isUnique
    },
    clearIf(row, field, value) {
      if (row[field].toLowerCase() == value.toLowerCase()) {
        row[field] = ""
      }
    },
    isRowComplete(row) {
      let unique_username = this.isUniqueRowUsername(row)
      return (
        unique_username &&
        row.firstname && row.firstname.trim() !== ""
        && (row.lastname !== null) // blank lastname is ok
        && row.username && row.username.trim() !== ""
        && row.password && row.password.trim() !== ""
      )
    },
    autoCreate() {
      this.autocreateUsers(this.autocreate.count)
    },
    autocreateUsers(count) {
      this.autoCreating = true
      axios.post("/teacher/students", {verb: "autocreate", count: count})
        .then((response) => response.data)
        .then((data) => {
          let students = data.students
          let newids = data.newids
          this.students = students
          this.newids = [...this.newids, ...newids]
          newids.forEach(id => {
            let row = students.find(s => s.id === id)
            this.addPromptForChange(row, "firstname")
            this.addPromptForChange(row, "lastname")
          })

          this.original = JSON.parse(JSON.stringify(students))
        }).catch((error) => {
        this.errorMessage = error
      }).finally(() => {
        this.loading = false
        this.autoCreating = false
      })
    },
    serializeRow(row, verb) {
      let entry = JSON.parse(JSON.stringify(row))

      this.savingCount += 1

      axios.post("/teacher/students", {verb: verb, student: entry})
        .then((response) => response.data)
        .then((data) => {
          // wouldz be nice to do something like restore the version..
          // remove the error if possible
          this.removeErrorForStudentId(entry.id)
          this.updatedOne = true
        }).catch((error) => {
        if (error.response && error.response.data && error.response.data.errors) {
          // and add it again...
          this.removeErrorForStudentId(entry.id)
          this.errors.push({
            id: entry.id,
            errors: error.response.data.errors,
          })
        } else {
          this.errorMessage = error.message
        }
      }).finally(() => {
        this.savingCount -= 1
      })
    },

    async deleteAll() {
      const title = "Delete ALL Students?"
      const message = "ALL students and their history will be permanently deleted. This is not recoverable.  Continue?"

      const confirmed = await this.$bvModal.msgBoxConfirm(message, {title, okTitle: "Delete", okVariant: "danger"})
      if (!confirmed) return

      this.loadOrDeleteAllStudents("deleteAll")
      this.detailRows = {}
      // this.$uiv_notify({
      //   type: 'success',
      //   content: 'Delete completed.'
      // })

      // catch:
      // this.$uiv_notify("Delete All canceled.")
    },

    loadStudents() {
      this.loadOrDeleteAllStudents("list")
    },

    loadParameters() {
      this.loading = true
      axios.post("/teacher/students", {verb: "parameters"})
        .then((response) => response.data)
        .then((data) => {
          this.maximumStudents = data.maximumStudents
          this.hasFluency = data.fluencyVideosEnabled;
          var selectList = []
          for (var ii = 0; ii < this.maximumStudents; ii++) {
            selectList.push(ii + 1)
          }
          this.autocreateCount = selectList
        }).catch((error) => {
        this.errorMessage = error
      }).finally(() => {
        this.loading = false
      })
    },
    loadOrDeleteAllStudents(verb) {
      this.loading = true
      axios.post("/teacher/students", {verb: verb})
        .then((response) => response.data)
        .then((data) => {
          this.students = data.map((e) => {
            if (e.firstname == "Student") {
              this.addPromptForChange(e, "firstname")
            }
            if (e.lastname == "") {
              this.addPromptForChange(e, "lastname")
            }
            return e
          })
          if (!this.students.length) {
            this.startedEmpty = true
          }

          this.original = JSON.parse(JSON.stringify(data))
        }).catch((error) => {
        this.errorMessage = error
      }).finally(() => {
        this.loading = false
      })
    },
    removeErrorForStudentId(id) {
      this.errors = this.errors.filter((error) => {
        return (error.id != id)
      })
    },
    hasError(row, field) {
      var result = false
      for (var ii = 0; ii < this.errors.length; ii++) {
        if (this.errors[ii].id == row.id) {
          let fieldname = `student.${field}`
          if (this.errors[ii].errors[fieldname]) {
            result = this.errors[ii].errors[fieldname] // fixme adjust.
          }
          break
        }
      }
      return result
    },
    hasPromptForChange(row, field) {
      return this.promptForChange[field].includes(row.id)
    },
    addPromptForChange(row, field) {
      this.promptForChange[field] = [...this.promptForChange[field], row.id]
    },
    removePromptForChange(row, field) {
      console.log("removePromptForChange", row, field)
      this.promptForChange[field] = this.promptForChange[field].filter(
        id => row.id !== id,
      )
    },

    toggleDetailRow(id) {
      this.detailRows = {...this.detailRows, [id]: !this.detailRows[id]}
    },

    async sendLoginCards() {
      this.sendingCards = true
      const response = await axios.post("/teacher/media/cards/send")
      this.sendCardsResponse = response.data
      this.sendingCards = false
      this.cardsSent = true
    },

    showLessonDialog() {
      this.sampleLesson.lesson = undefined
      this.sampleLesson.show = true
    },

    onSampleLessonClick(lesson) {
      this.sampleLesson.lesson = lesson
    },

    proceedToLesson() {
      this.sampleLesson.show = false
      window.location = "/student/learn/" + this.sampleLesson.lesson.lessonid + "/1"
    },
  },
}
</script>

<template>
  <div>
    <div class="card bg-light">
      <div class="card-header ">
        <div class="mb-2 mb-md-0">
          <div v-if="loading">
            <h3>Loading Students...</h3>
          </div>
          <div v-else-if="students.length == 0">
            <div style="font-weight: bold; font-size: 1.5rem;">
              STEP 1 – Click below to select the number of student accounts you wish to create
            </div>
            <form class="col-sm-8 col-lg-4" method="post" name="autocreateForm" novalidation>
              <div class="form-group">
                <!--<label class="control-label">-->
                <!--  Number of Students In Class:-->
                <!--</label>-->
                <div>
                  <select class="form-control" v-model="v$.autocreate.count.$model">
                    <option value="">Select Number of Students in Class</option>
                    <option v-for="(count, index) in autocreateCount" :key="index" :value="count">
                      {{ count }}
                    </option>
                  </select>
                </div>
              </div>
              <div>
                <button class="btn btn-lg btn-success" @click.prevent="autoCreate"
                        :disabled="v$.autocreate.$invalid || autoCreating">
                  <span v-if="autoCreating">Creating...</span>
                  <span v-else>
                    <i class="bi-plus"></i>
                    Add Student<span v-if="autocreate.count != 1">s</span>
                  </span>
                </button>
              </div>
            </form>
          </div>

          <div v-else-if="students.length" style="padding: 10px;">
            <tutorial-popup name="setup_classroom_2"></tutorial-popup>
            <div style="margin: 10px 0; padding: 5px">
              <div class="pull-left">
                <input class="form-control student-search w-25" v-model="filter" placeholder="Search for a student...">
              </div>
              <div style="clear:both"></div>
            </div>
            <div class="alert alert-danger" v-if="!allRowsComplete">
              Please enter all fields for the marked rows
            </div>
            <div class="table table-striped table-responsive ">
              <Datatable :columns="columns" :data="studentsWithNewLast" :filter-by="filter">
                <template slot-scope="{ row }">
                  <tr :class="{ incomplete: !isRowComplete(row) }">
                    <td :class="{ error: hasError(row, 'firstname'), prompt: hasPromptForChange(row, 'firstname') }">
                      <input class="form-control live-edit-input" placeholder="First Name" v-model="row.firstname"
                             @focus="clearIf(row, 'firstname', 'Student')"
                             @focus.once="removePromptForChange(row, 'firstname')"
                             @keyup.enter="checkChange(row, 'firstname')" @blur="checkChange(row, 'firstname')" />
                      <em v-if="hasPromptForChange(row, 'firstname')">Enter student name</em>
                      <div v-if="hasError(row, 'firstname')">
                        <ul>
                          <li v-for="(error, index) in hasError(row, 'firstname')" :key="index">{{ error }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td :class="{ error: hasError(row, 'lastname'), prompt: hasPromptForChange(row, 'lastname') }">
                      <input class="form-control live-edit-input"
                             placeholder="Last Name"
                             v-model="row.lastname"
                             @focus.once="removePromptForChange(row, 'lastname')"
                             @keyup.enter="checkChange(row, 'lastname')"
                             @blur="checkChange(row, 'lastname')" />
                      <em v-if="hasPromptForChange(row, 'lastname')">Enter student name</em>
                      <div v-if="hasError(row, 'lastname')">
                        <ul>
                          <li v-for="(error, index) in hasError(row, 'lastname')" :key="index">{{ error }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td>
                      <select class="form-control grade-level-picker" v-model="row.planid" @change="checkChange(row)">
                        <option value="6">Kindergarten</option>
                        <option value="7">First Grade</option>
                        <option value="8">Second Grade</option>
                        <option value="9">Third Grade</option>
                        <option value="10">Fourth Grade</option>
                        <option value="11">Fifth Grade</option>
                      </select>
                    </td>
                    <td :class="{ 'error': hasError(row, 'username') }">
                      <input minlength="1" maxlength="25" class="form-control live-edit-input" placeholder="User Name"
                             v-model="row.username" @keyup.enter="checkChange(row, 'username')"
                             @blur="checkChange(row, 'username')" />
                      <div v-if="hasError(row, 'username')">
                        <ul>
                          <li v-for="(error, index) in hasError(row, 'username')" :key="index">{{ error }}
                          </li>
                        </ul>
                      </div>
                    </td>
                    <td :class="{ 'error': hasError(row, 'password') }">
                      <input class="form-control live-edit-input" placeholder="Password" v-model="row.password"
                             @keyup.enter="checkChange(row, 'password')" @blur="checkChange(row, 'password')" />
                      <div v-if="hasError(row, 'password')">
                        <ul>
                          <li v-for="(error, index) in hasError(row, 'password')" :key="index">{{ error }}
                          </li>
                        </ul>
                      </div>
                    </td>

                    <td>
                      <button class="btn btn-warning w-100" @click="toggleDetailRow(row.id)">
                        <i class="bi-gear"></i>
                      </button>
                    </td>

                    <td>
                      <button type="button" class="btn btn-danger w-100" @click="deleteStudent(row)">
                        <i class="bi-x"></i>
                      </button>


                    </td>
                  </tr>

                  <tr v-show="detailRows[row.id]">
                    <!-- metadata editor -->
                    <td colspan="7">
                      <div class="card card-body mb-2">
                        <div class="row">
                          <div class="form-group col-sm-6 col-md-3 col-lg-3 col-xl-3 editor-field">
                            <label for="parent-email">
                              Parent's Email Address:
                            </label>
                            <div>
                              <input class="form-control live-edit-input" id="parent-email" type="email"
                                    v-model="row.parent_email" @keyup.enter="checkChange(row, 'parent_email')"
                                    @blur="checkChange(row, 'parent_email')">
                            </div>
                          </div>

                          <div class="form-group col-sm-6 col-md-3 col-lg-3 col-xl-3 editor-field">
                            <label for="require-retake">
                              Retake exams if score &lt; 75%</label>
                            <div>
                              <select class="form-control" id="require-retake" v-model="row.require_retake"
                                      @change="checkChange(row)">
                                <option value="inherit">Teacher default</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group col-sm-6 col-md-3 col-lg-3 col-xl-3 editor-field">

                            <label for="require-3x">
                              Require read-along fluency practice 3 times
                            </label>
                            <div>
                              <select class="form-control" id="require-3x" v-model="row.require_3x"
                                      @change="checkChange(row)">
                                <option value="inherit" selected>Teacher
                                  default
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group col-sm-6 col-md-3 col-lg-3 col-xl-3 editor-field">

                            <label for="slow-video">Slow down fluency video</label>
                            <div>
                              <select class="form-control" id="slow-video" v-model="row.slow_video"
                                      @change="checkChange(row)">
                                <option value="inherit" selected>Teacher default</option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>
                        </div>

                        <div class="row" v-if="hasFluency">
                          <div class="form-group col-sm-6 col-md-3 col-lg-3 col-xl-3 editor-field">
                            <label for="require-3x">
                              Enable fluency recording
                            </label>
                            <div>
                              <select class="form-control" id="require-3x" v-model="row.enable_fluency_recording"
                                      @change="checkChange(row)">
                                <option value="inherit" selected>Teacher
                                  default
                                </option>
                                <option value="1">Yes</option>
                                <option value="0">No</option>
                              </select>
                            </div>
                          </div>

                          <div class="form-group col-sm-6 col-md-3 col-lg-3 col-xl-3 editor-field">
                            <label for="require-3x">
                              Fluency Recording - Mode
                            </label>
                            <div>
                              <select class="form-control" id="require-3x" v-model="row.fluency_recording_lyrics_only"
                                      @change="checkChange(row)">
                                <option value="inherit" selected>Teacher
                                  default
                                </option>
                                <option value="1">Lyrics Only</option>
                                <option value="0">Video Only</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div class="card card-body">
                        <div class="row">
                          <div class="form-group col-12">
                            <label>Leave feedback for {{ row.firstname }} {{ row.lastname }}</label>
                            <StudentFeedbackForm :student-id="row.id" />
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>

                </template>
              </Datatable>
            </div>

            <p>
              {{ students.length }} students created, {{ studentsRemaining }} available.
            </p>

            <div style="width: 100%; margin-top: 10px; display: flex; justify-content: space-between;">

              <button class="btn btn-success" @click.prevent="addStudentRow()">
                <i class="bi-plus"></i>
                New Student
              </button>

              <button class="btn btn-danger" @click.prevent="deleteAll()">
                <i class="bi-exclamation-triangle-fill"></i>
                Delete All Students
              </button>

            </div>

            <hr />

            <div class="cta-buttons">
              <div v-if="startedEmpty && updatedOne">
                <a :disabled="!startedEmpty || !updatedOne" class="btn btn-primary" href="/">Take Me to My Dashboard</a>
              </div>

              <div>
                <button v-if="!cardsSent" class="btn btn-primary" @click="sendLoginCards()" :disabled="sendingCards">
                  Email Student Login Cards
                </button>
              </div>
            </div>


            <div v-if="sendCardsResponse" class="alert mt-5 text-center alert"
                 :class="sendCardsResponse.success ? 'alert-soft-success' : 'alert--soft-danger'">
              {{ sendCardsResponse.message }}
            </div>

            <LeftyPopup v-if="sampleLesson.show" :title="sampleLessonDialogTitle">
              <div v-if="!sampleLesson.lesson" style="height: 330px; overflow-y: auto">
                <lesson-tile v-for="lessonId in SAMPLE_LESSON_IDS" :key="lessonId" :lesson-id="lessonId"
                             @selected="onSampleLessonClick">
                </lesson-tile>
              </div>
              <div v-else class="lefty-text">
                <p>The first part of each day’s lesson is fluency practice.
                  Kids read repeatedly 3 times as they see, listen and read along towards mastery.</p>

                <p>The rhythm, rhyme and repetition allow kids to remember more
                  information, which is helpful for the quiz following the video.</p>
                <div class="text-center">
                  <a class="btn btn-success btn-block" @click.prevent="proceedToLesson()">View Lesson</a>
                </div>
              </div>
            </LeftyPopup>


            <!-- TODO: Add a busy spinner while the lessons are being loaded -->


          </div>
        </div>
      </div>
    </div>
  </div>
</template>


<style lang="scss" scoped>
.table {
  margin-bottom: 5px;
}

table {
  font-size: 0.8em;
  margin-bottom: 0px;

  .incomplete,
  .error {
    border: 1px solid red;
    background-color: lightpink !important;

    &:hover {
      background-color: pink;
    }
  }

  .prompt {
    background-color: #fffac3;
  }

  .grade-level-picker {
    min-width: 120px;
  }
}

.form-control {
  min-height: inherit;
}

.export-csv-file {
  display: none;
}

.cta-buttons {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}

.mail-results-message {
  margin-top: 1.5rem;
}

.lefty-text {
  font-size: 1.4rem;
}

label {
  min-height: 3em;
}
</style>
