<script setup lang="ts">
import GradeLevelTagLabel from "~components/GradeLevelTagLabel.vue"
import {computed, ref, watch} from "vue"
import {StudentRow} from "~components/teacher/assign/assignment"
import {useVModels} from "@vueuse/core"
import LessonTag from "~components/teacher/assign/LessonTag.vue"

const props = defineProps<{
  allStudents: StudentRow[]
  selectedStudents: StudentRow[]
}>()

const emit = defineEmits(["assign-lessons", "click-lesson", "click-unassigned-lesson", "submit"])

const {selectedStudents} = useVModels(props, emit)

const columns = [
  {label: ""},
  {label: "Student Name", field: "name"},
  {label: "Assigned Lessons"},
]

const filter = ref("")
const selectAllBox = ref(false)

const allStudentsSelected = computed(() => selectedStudents.value.length === props.allStudents.length)
watch(selectedStudents, () => selectAllBox.value = allStudentsSelected.value)

function isSelected(student: StudentRow) {
  return selectedStudents.value.some(s => s.id === student.id)
}

function onCheck(checked: boolean, student: StudentRow) {
  const filtered = selectedStudents.value.filter(s => s.id !== student.id)
  selectedStudents.value = checked ? [...filtered, student] : filtered
}

function onSelectAllBoxChange(checked: unknown) {
  selectedStudents.value = checked ? [...props.allStudents] : []
}

function unassignedStartedLessons(student: StudentRow) {
  const unassigned_ids = new Set(student.unassigned_lessons.map(lesson => lesson.id))
  return student.started_lessons.filter(lsn => unassigned_ids.has(lsn.id))
}
</script>

<template>
  <div>
    <b-alert show variant="soft-info" dismissible>
      <b>Note:</b> Once you have assigned lessons to individual students, you will see them listed below. If you wish to
      unassign lessons, click on the lesson titles directly or select students and click the red unassign button.
    </b-alert>

    <section class="controls">
      <b-input placeholder="Search for a student..." class="search-input" v-model="filter"/>
    </section>

    <section class="table">
      <label class="checkbox-wrapper select-all-box position-absolute">
        <b-check v-model="selectAllBox" @change="onSelectAllBoxChange"/>
      </label>
      <datatable
        :columns="columns"
        :data="allStudents"
        :filter-by="filter"
        class="table table-striped"
        v-slot="{row: student}"
      >
        <tr>
          <td class="row-check">
            <label class="checkbox-wrapper">
              <b-check :checked="isSelected(student)" @change="onCheck($event, student)"/>
            </label>
          </td>

          <td class="name">
            {{ student.name }}<br/>
            {{ student.login }}<br>
            <GradeLevelTagLabel v-if="student.level" :grade="student.level" size="sm"/>
          </td>

          <td>
            <div class="lessons d-flex flex-wrap">
              <LessonTag
                v-for="lesson in student.assigned_lessons"
                :key="lesson.id"
                :lesson="lesson"
                class="m-1"
                @click="$emit('click-lesson', student, lesson)"
              />
            </div>

            <div v-if="unassignedStartedLessons(student).length" class="text-italic">
              Unassigned, but in progress (click to move to assigned list, click again to remove):
            </div>
            <div class="lessons d-flex flex-wrap">
              <LessonTag
                v-for="lesson in unassignedStartedLessons(student)"
                :key="lesson.id"
                :lesson="lesson"
                class="m-1"
                state="unassigned"
                @click="$emit('click-unassigned-lesson', student, lesson)"
              />
            </div>
          </td>
        </tr>
      </datatable>
    </section>
  </div>
</template>

<style lang="scss" scoped>
section {
  display: flex;
  gap: 1rem;
  margin-top: 0.5rem;

  &.controls {
    align-items: center;
  }
}

.search-input {
  max-width: 18rem;
}

#student-search-input {
  border: 2px solid #ccc;
  max-width: 20em;
}

.checkbox-wrapper {
  min-width: 2rem;
  min-height: 2rem;
  max-width: 2rem;
  max-height: 2rem;
  display: flex;
  align-items: center;
  justify-content: center;
  transform: scale(1.5);
}

.select-all-box {
  // Shift down and left so it looks like it's the first column header
  transform: scale(1.5) translateX(20px) translateY(4px);
}

td {
  &.name {
    text-align: center;
  }
}
</style>
