<!-- TODO: make this into GradeTag similar to LessonTag and StudentTag -->
<script>
export default {
  name: "GradeLevelTagLabel",

  props: {
    grade: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: false,
    },
    interactive: {
      type: Boolean,
      default: undefined, // undefined = look for @click/@mouseover/@mouseenter
    },
    size: {
      type: String,
      required: false,
      default: 'md'
    },
    state: {
      type: String,
      required: false,
    }
  },

  computed: {
    displayName() {
      return this.title || this.grade
    },

    gradeKey() {
      const grade = this.grade
      const rules = [
        [/^k|kinder/i, "kindergarten"],
        [/^1|first/i, "first-grade"],
        [/^2|second/i, "second-grade"],
        [/^3|third/i, "third-grade"],
        [/^4|fourth/i, "fourth-grade"],
        [/^5|fifth/i, "fifth-grade"],
        [/all benchmarks/i, "all-benchmarks"],
      ]
      for (const [pat, key] of rules) {
        if (pat.test(grade)) return key
      }
      return "unknown"
    },

    classes() {
      const key = this.gradeKey
      const classList = ["tag-label", `tag-label-${key}`, `size-${this.size}`]
      this.isInteractive && classList.push("tag-label-interactive")
      this.state && classList.push(`tag-state-${this.state}`)
      return classList
    },

    isInteractive() {
      if (this.interactive !== null && this.interactive !== undefined) {
        return this.interactive
      }
      return !!(this.$listeners.click || this.$listeners.mouseover || this.$listeners.mouseenter)
    }
  },
}
</script>

<template>
  <span :class="classes" v-on="$listeners">{{ displayName }}</span>
</template>

<style lang="scss" scoped>
.tag-label {
  font-size: 0.66rem;
  border-radius: 3px;
  padding: 2px 4px;
  white-space: nowrap;
  &.tag-label-interactive {
    cursor: pointer;
  }
}

.tag-label-first-grade {
  background-color: #ffdb58;
  color: black;
  &.tag-label-interactive:hover {
    background-color: #ddbc44;
  }
}

.tag-label-second-grade {
  background-color: #800080;
  color: white;
  &.tag-label-interactive:hover {
    background-color: #630563;
  }
}

.tag-label-third-grade {
  background-color: #5cb85c;
  color: white;
  &.tag-label-interactive:hover {
    background-color: #449d44;
  }
}

.tag-label-fourth-grade {
  background-color: #11634f;
  color: white;
  &.tag-label-interactive:hover {
    background-color: #0f4a3b;
  }
}

.tag-label-fifth-grade {
  background-color: #337ab7;
  color: white;
  &.tag-label-interactive:hover {
    background-color: #286090;
  }
}

.tag-label-kindergarten {
  background-color: #ff8000;
  color: white;

  &.tag-label-interactive:hover {
    background-color: #e37200;
  }
}

.tag-label-unknown {
  background-color: #ddd;
  color: black;

  &.tag-label-interactive:hover {
    background-color: #bbb;
  }
}

.tag-label-all-benchmarks {
  background-color: #ffccff;
  color: black;

  &.tag-label-interactive:hover {
    background-color: #ffaaff;
  }
}

.tag-state-unassigned {
  border: 3px dotted grey;
  font-style: italic;
}

.size-xs {
  font-size: 0.66rem;
}

.size-sm {
  font-size: 0.8rem;
}

.size-md {
  font-size: 1rem;
}
</style>
