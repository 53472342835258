<script>
import useVuelidate from "@vuelidate/core"
import {email, helpers, maxLength, minLength, required, requiredUnless} from "@vuelidate/validators"
import {debounceAsyncValidator} from "@/common/utils/validation.js";

const {withAsync} = helpers

// move to provider eventually
async function userExists(loginName) {
  if (loginName === '') return true
  const response = await fetch(`/support/user/${loginName}/exists`)
  return Boolean(await response.json())
}

export default {
  setup() {
    return {
      v$: useVuelidate(),
    }
  },

  props: {

    socialButtons: {
      type: Boolean,
      required: false,
      default: true
    },

    fields: {
      type: Object,
      required: false,
      default: () => ({})
    },
  },

  data() {

    const formFields = ['userType', 'firstName', 'lastName', 'userName',
      'email', 'password', 'gradeLevel', 'howDidYouFindUs']

    const entryForm = {}
    formFields.forEach(name => entryForm[name] = this.getFieldDefault(name))

    return {
      ...entryForm,

      schoolZipCode: '',
      selectedSchool: '',

      schoolsLoaded: false,
      loadingSchools: false,
      availableSchools: [],

      errorMessage: '',
      submitting: false,

      foreign: false,

      // constant data

      gradeLevels: [
        "Kindergarten",
        "1st Grade",
        "2nd grade",
        "3rd grade",
        "4th grade",
        "5th grade",
        "6th grade or higher",
        "Multiple grade levels",
      ],
      referralTypes: [
        "Pinterest",
        "A Friend Referred Me",
        "Search Engine",
        "Facebook",
        "Twitter",
        "Instagram",
        "Other",
        "Unknown"
      ],
    };
  },
  methods: {
    onSubmit() {
      const payload = {
        userType: this.userType,
        firstName: this.firstName,
        lastName: this.lastName,
        userName: this.userName,
        email: this.email,
        password: this.password,
        gradeLevel: this.gradeLevel,
        howDidYouFindUs: this.howDidYouFindUs,
        selectedSchool: this.selectedSchool,
      };

      this.submitting = true;
      axios.post('/teacher/signup', payload)
        .then((response) => response.data)
        .then((data) => {
          // noinspection JSUnresolvedReference
          if (data.success) {
            window.location = data.redirect;
          } else {
            // show the error. // FIXME
            // noinspection JSUnresolvedReference
            this.errorMessage = data.message;
          }
        }).catch((error) => {
        try {
          if (error.response.data.errors) {
            let msg = '';
            for (const k in error.response.data.errors) {
              msg += error.response.data.errors[k][0];
            }
            this.errorMessage = msg;
          }
        } catch {
          this.errorMessage = error.toString();
        }
      }).finally(() => {
        this.submitting = false;
      });
    },
    isNumber(event) {
      const evt = event || window.event;
      const charCode = evt.which || evt.keyCode;
      // console.log(charCode);
      if ((charCode > 31 && (charCode < 48 || charCode > 57))) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    onZipChange() {
      if (this.schoolZipCode.length === 5) {
        this.lookupByZip(this.schoolZipCode)
      }
    },
    async lookupByZip(zip) {
      this.loadingSchools = true;
      const response = await fetch(`/support/schoolsByZip/${zip}`)
      this.availableSchools = await response.json();
      this.loadingSchools = false;
      this.schoolsLoaded = true;
    },

    getFieldDefault(name) {
      return this._getField(name).default
    },

    isFieldLocked(name) {
      return this._getField(name).locked
    },

    isFieldHidden(name) {
      return this._getField(name).hidden
    },

    _getField(name) {
      return this.fields[name] || {}
    },

  },
  validations() {
    // there's probably a way to break these apart into two things, and combine them if teacher.
    // but i have more pressing things to solve
    if (this.userType === 'teacher') {
      return {
        userType: {
          required
        },
        firstName: {
          required,
          minLength: minLength(2),
          maxLength: maxLength(45)
        },
        lastName: {
          required,
          minLength: minLength(1),
          maxLength: maxLength(45)
        },
        userName: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(20),
          isUnique: withAsync(debounceAsyncValidator(function (value, debounce) {
            // synchronous validations
            if (!value) return true
            if (!this.v$.userName.minLength || !this.v$.userName.maxLength) return true
            // capture all component state synchronously
            return debounce()
              .then(() => userExists(value))
              .then((exists) => {
                return !exists;
              })
              .catch(() => {
                // could be caused by either rest api failure or by debounce
                return false
              })
          }, 500))
        },
        password: {
          required,
          minLength: minLength(6),
          maxLength: maxLength(32),
        },
        email: {
          required,
          email
        },
        gradeLevel: {
          required
        },
        howDidYouFindUs: {
          required
        },
        selectedSchool: {
          required: requiredUnless('foreign')
        },
      };
    } else {
      return {
        userType: {
          required
        },
        firstName: {
          required,
          minLength: minLength(2)
        },
        lastName: {
          required,
          minLength: minLength(1)
        },
        userName: {
          required,
          minLength: minLength(4),
          maxLength: maxLength(20),
          isUnique: withAsync(debounceAsyncValidator(function (value, debounce) {
            // synchronous validations
            if (!value) return true
            if (!this.v$.userName.minLength || !this.v$.userName.maxLength) return true
            // capture all component state synchronously
            return debounce()
              .then(() => userExists(value))
              .then((exists) => {
                return !exists;
              })
              .catch(() => {
                // could be caused by either rest api failure or by debounce
                return false
              })
          }, 500))
        },
        password: {
          required, // FIXME
          minLength: minLength(6),
          maxLength: maxLength(32)
        },
        email: {
          required,
          email
        },
        gradeLevel: {
          required
        },
        howDidYouFindUs: {
          required
        }
      };
    }
  },
}
</script>

<template>
  <div>
    <div v-if="socialButtons" class="social-sign-in-wrapper">
      <social-login-buttons :is-register="true"></social-login-buttons>
    </div>
    <div v-if="socialButtons" class="row" style="text-align: center;">
      <div class="col-xs-12">
        or
      </div>
    </div>

    <!-- form --> <!-- FIXME: change #frmSignup CSS elements to class based -->
    <form method="post" name="frmSignUp" class="signup-form" @submit.prevent="onSubmit">
      <!--
        <input type="hidden" name="cmd" value="_xclick" />
        <input type="hidden" name="no_note" value="1" />
        <input type="hidden" name="lc" value="UK" />
        <input type="hidden" name="currency_code" value="USD" />
        <input type="hidden" name="bn" value="PP-BuyNowBF:btn_buynow_LG.gif:NonHostedGuest" />
        <input type="hidden" name="first_name" value="Customer's First Name"  />
        <input type="hidden" name="last_name" value="Customer's Last Name"  />
        <input type="hidden" name="payer_email" value="customer@example.com"  />
        <input type="hidden" name="item_number" value="Teacher Registration Fee" / >
        <input type="hidden" name="return" value="/admin/teaches.php?PY_CO=1">
        <input type="hidden" name="rm" value="2">
        <input type="hidden" name="hosted_button_id" value="QQ447TDZ9GAXL">
        9R77JNHN6BLBA
      -->

      <div class="alert alert-danger" v-if="errorMessage">
        <b>Error: </b>{{ errorMessage }}
      </div>

      <div class="form-group row" :class="{ 'has-error': v$.userType.$error }">
        <div class="col-xs-4 control-label">
          <label for="usertype">User Type:</label>
        </div>
        <div class="col-xs-8">
          <select id="usertype" name="usertype" v-model="v$.userType.$model" class="form-control" required>
            <option value="">--Select user type--</option>
            <option value="teacher">Teacher</option>
            <option value="parent">Parent</option>
          </select>
          <div class="error" v-if="!v$.userType.required">Field is required</div>
        </div>
      </div>

      <div class="form-group row" :class="{ 'has-error': v$.firstName.$error }">
        <div class="col-xs-4 control-label">
          <label for="firstname">First Name:</label>
        </div>
        <div class="col-xs-8">
          <input type="text" class="form-control" name="firstName" v-model.trim="v$.firstName.$model"/>
          <div class="error" v-if="!v$.firstName.required">Field is required</div>
          <div class="error" v-if="!v$.firstName.minLength">Field must have at least
            {{ v$.firstName.$params.minLength.min }} letters.
          </div>
        </div>

      </div>

      <div class="form-group row" :class="{ 'has-error': v$.lastName.$error }">
        <div class="col-xs-4 control-label">
          <label for="lastname">Last Name:</label>
        </div>
        <div class="col-xs-8">
          <input type="text" class="form-control" name="lastName" v-model.trim="v$.lastName.$model"/>
          <div class="error" v-if="!v$.lastName.required">Field is required</div>
          <div class="error" v-if="!v$.lastName.minLength">Field must have at least
            {{ v$.lastName.$params.minLength.min }} letters.
          </div>
        </div>
      </div>

      <div class="form-group row" :class="{ 'has-error': v$.email.$error }">
        <div class="col-xs-4 control-label">
          <label for="email">Email:</label>
        </div>
        <div class="col-xs-8">
          <input
            type="email"
            class="form-control"
            name="email"
            v-model="v$.email.$model"
            :readonly="isFieldLocked('email')"
          />
          <div class="error" v-if="!v$.email.required">Field is required</div>
          <div class="error" v-if="!v$.email.email">Field must be a valid email address.</div>
        </div>
      </div>

      <div class="form-group row" :class="{ 'has-error': v$.userName.$error }">
        <div class="col-xs-4 control-label">
          <label for="login">Username:</label>
        </div>
        <div class="col-xs-8">
          <input
            autocomplete="off"
            autocapitalize="none"
            class="form-control"
            type="text"
            v-model="v$.userName.$model"
            maxLength="20"
          />
          <div class="error" v-if="!v$.userName.required">Field is required</div>
          <div class="error" v-if="!v$.userName.minLength">Field must have at least
            {{ v$.userName.$params.minLength.min }} letters.
          </div>
          <div class="error" v-if="!v$.userName.maxLength">Field must have at most
            {{ v$.userName.$params.maxLength.max }} letters.
          </div>
          <div v-if="v$.userName.$pending">Checking username...</div>
          <div class="error" v-if="!v$.userName.isUnique">This username has already been taken.</div>
        </div>
      </div>

      <div v-if="!isFieldHidden('password')" class="form-group row" :class="{ 'has-error': v$.password.$error }">
        <div class="col-xs-4 control-label">
          <label for="txt_password">Password:</label>
        </div>
        <div class="col-xs-8">
          <input type="password" class="form-control" v-model="v$.password.$model" maxlength="32"/>
          <div class="error" v-if="!v$.password.required">Field is required</div>
          <div class="error" v-if="!v$.password.minLength">Field must have at least
            {{ v$.password.$params.minLength.min }} letters.
          </div>
        </div>
      </div>

      <div v-if="userType==='teacher'">
        <div class="form-group row">
          <div class="col-xs-4 control-label">
            <label for="txt_password">School Zip Code:</label>
          </div>
          <div class="col-xs-8">
            <input id="cb-non-us" type="checkbox" v-model="foreign">
            <label for="cb-non-us" style="font-weight: normal; font-style: italic;">
              I am not in the United States
            </label>
          </div>
          <div v-show="!foreign" class="col-xs-8">
            <input
              type="text"
              class="form-control"
              @input="onZipChange"
              v-model="schoolZipCode"
              maxlength="5"
              @keypress="isNumber($event)"
            />
            <p v-show="loadingSchools"><strong>Loading Schools...</strong></p>
            <p><strong>Note:</strong> After entering the school zip
              code, please select your school.</p>
          </div>
        </div>
        <div
          v-show="!foreign && schoolsLoaded"
          class="form-group row"
          :class="{ 'has-error': v$.selectedSchool.$error }"
        >
          <div class="col-xs-4 control-label">
            <label for="school">Choose Your School:</label>
          </div>
          <div class="col-xs-8">
            <select id="school" name="school" class="form-control" v-model="v$.selectedSchool.$model">
              <option value="">Choose Your School</option>
              <option v-for="school in availableSchools" :key="school.id" :value="school.id">{{ school.name }}</option>
              <option value="1">My School Is Not Listed</option>
            </select>
            <div class="error" v-if="!v$.selectedSchool.required">Field is required</div>
          </div>
        </div>
      </div>

      <div class="form-group row" :class="{ 'has-error': v$.gradeLevel.$error }">
        <div class="col-xs-4 control-label">
          <label for="inf_custom_GradeLevelofStudents">Grade Level of Students</label>
        </div>
        <div class="col-xs-8">
          <select class="select_new form-control" v-model="v$.gradeLevel.$model">
            <option value="">--Select grade level--</option>
            <option v-for="(grade, index) in gradeLevels" :value="grade" :key="index">{{ grade }}</option>
          </select>
          <p>
            <strong>Note:</strong>
            This question will not limit access to content.
          </p>
        </div>
      </div>

      <div
        v-if="!isFieldHidden('howDidYouFindUs')"
        class="form-group row"
        :class="{ 'has-error': v$.howDidYouFindUs.$error }"
      >
        <div class="col-xs-4 control-label">
          <label for="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn">How
            Did You Find Out About Lyrics2Learn?</label>
        </div>
        <div class="col-xs-8">
          <select
            class="select_new form-control"
            id="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn"
            name="inf_custom_QuestionHowDidYouFindOutAboutLyrics2Learn" v-model="v$.howDidYouFindUs.$model"
          >
            <option value="">--Select a choice--</option>
            <option v-for="(how, index) in referralTypes" :value="how" :key="index">{{ how }}</option>
          </select>
        </div>
      </div>

      <div class="alert alert-danger" v-if="errorMessage">
        <b>Error: </b>{{ errorMessage }}
      </div>

      <div class="form-group row">
        <div class="col-xs-12">
          <button type="submit" id="btnSubmit" class="button_submit" :disabled="v$.$invalid && !submitting">
            <span v-if="submitting">Submitting..</span>
            <span v-else>Start Your Free Trial Now</span>
          </button>
        </div>
      </div>
    </form>
  </div>
</template>

<style lang="scss" scoped>
@keyframes shakeError {
  0% {
    transform: translateX(0);
  }
  15% {
    transform: translateX(0.375rem);
  }
  30% {
    transform: translateX(-0.375rem);
  }
  45% {
    transform: translateX(0.375rem);
  }
  60% {
    transform: translateX(-0.375rem);
  }
  75% {
    transform: translateX(0.375rem);
  }
  90% {
    transform: translateX(-0.375rem);
  }
  100% {
    transform: translateX(0);
  }
}

.signup-form {

  .form-group {
    .error {
      display: none;
    }

    &.has-error {
      animation-name: shakeError;
      animation-fill-mode: forwards;
      animation-duration: .6s;
      animation-timing-function: ease-in-out;

      .error {
        color: red;
        display: block;
      }
    }
  }

  button[type=submit] {
    cursor: pointer;
    width: 100%;
    height: 54px;
    color: white;
    font-weight: 700;
    font-size: 26px;
    border: none;
    border-radius: 5px;
    background: #c41717;
    background: linear-gradient(to bottom, #c41717 0%, #b01415 100%);

    &:disabled {
      opacity: 0.75;
      background: #aaa;
      cursor: default;
    }
  }

  @media screen and (max-width: 700px) and (min-width: 450px) {
    button[type=submit] {
      font-size: 20px;
    }
  }

  @media screen and (max-width: 449px) {
    button[type=submit] {
      font-size: 14px;
    }
  }
}
</style>

