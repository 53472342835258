<style lang="scss">
.email-button-container {
	.modal-content {
		background: none !important;
		border: 0px !important;
		box-shadow: none !important;
	}
}
</style>

<template>
<div class="email-button-container">
	<button :class="buttonClass" type="button" @click="show()">{{ buttonTitle }}</button>
	<b-modal v-model="showWelcomeMessage" title="Welcome" size="lg" ref="shareModal" hide-header hide-footer>
		<teacher-email-summary
			:view="view"
			:placeholders="placeholders"
			:default-message="defaultMessage">
		</teacher-email-summary>
	</b-modal>
</div>
</template>

<script>
export default {
	mounted() {
	},
	props: {
		defaultMessage: {
			type: String,
			required: true,
		},
		placeholders: {
			type: String,
			required: true,
		},
		view: {
			type: String,
			required: true
		},
		buttonClass: {
			type: String,
			required: false,
			default: 'btn btn-warning'
		},
		buttonTitle: {
			type: String,
			required: false,
			default: "Email School Admin"
		}
	},
	data(){
		return {
			showWelcomeMessage: false
		};
	},
	methods: {
		show() {
			this.showWelcomeMessage = true;
		}
	}
}
 </script>
